import React from "react";
import ReactDom from "react-dom";

// import { w3cwebsocket as W3CWebSocket } from "websocket";
// import { DateRangePicker } from 'react-date-range';
// import { addDays, subDays } from "date-fns";
// import { pl } from 'date-fns/locale'
// import Moment from 'react-moment';
// import Multiselect from 'multiselect-react-dropdown';
// import { IoIosAdd } from "react-icons/io";
// import { RiCalendarLine } from "react-icons/ri";
// import { RiCheckFill } from "react-icons/ri";
import 'mapbox-gl/dist/mapbox-gl.css';
// import MapGL from "react-map-gl";

import MapGL, {
  Marker,
  Popup,
  AttributionControl,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl
} from 'react-map-gl';
// import mapboxgl from 'mapbox-gl';

// eslint-disable-next-line import/no-webpack-loader-syntax
// mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MAPBOX_TOKEN = "pk.eyJ1IjoicGx1c2ZsZWV0IiwiYSI6ImNsOTVydTlvZDAxMmM0MG15bTVjMHlkMHEifQ.1fA73q5Vq8MaIKfgY3et1A";
const DEFAULT_VIEWPORT = {
  width: 800,
  height: 600,
  longitude: 16.9711,
  latitude: 51.1389,
  zoom: 14,
};
// const api_url = process.env.REACT_APP_API_URL;

export class DisplayAdminSocket extends React.Component {
  constructor(props) {
  super(props);
      this.state = {
        data: [],
        viewport: DEFAULT_VIEWPORT,
        ws: null,
      };
  }

  componentDidMount() {
      // var ws = new W3CWebSocket('ws://'+api_url+'/reports');
      // this.setState({
      //   ws: ws
      // });
      // this.connect(ws);
  }

  _updateViewport = (viewport) => {
    this.setState({ viewport });
  };

  render() {
    // console.log(this.state.selectedVehicles);
    const { viewport } = this.state;
    return (
      <div className="content-wrapper reports">
        <div className="content-left">
        <MapGL
          {...viewport}
          width="100%"
          height="100%"
          mapboxApiAccessToken = {MAPBOX_TOKEN}
          mapStyle={"mapbox://styles/mapbox/satellite-streets-v11"}
          onViewportChange={this._updateViewport}
        >
          <GeolocateControl position="top-left" />
          <FullscreenControl position="top-left" />
          <NavigationControl position="top-left" />
          <ScaleControl />

          <Marker longitude={16.9711} latitude={51.1389} anchor="bottom" >
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16"
               viewBox="0 0 77 77" enable-background="new 0 0 77 77">
            <path fill="#010101" d="M72.7,77c-0.8,0-1.7-0.2-2.4-0.7L40.8,56.7c-1.4-0.9-3.3-0.9-4.7,0L6.6,76.3C4.6,77.5,2,77,0.7,75
              c-0.8-1.3-0.9-2.9-0.2-4.2L34.7,2.3c1.1-2.1,3.7-2.9,5.8-1.9c0.8,0.4,1.4,1.1,1.9,1.9l34.2,68.4c0.9,1.7,0.5,3.8-1,5.1
              C74.7,76.6,73.7,77,72.7,77z"/>
            </svg>
          </Marker>
        </MapGL>
        </div>
      </div>
    );
  }
}
