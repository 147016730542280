import * as React from 'react';
import axios from 'axios';
import { GET_ERRORS, GET_NOTIFICATIONS_RULES_LIST } from './types';
const api_name = process.env.REACT_APP_API_URL;
const api_ssl = process.env.REACT_APP_API_SSL;
const domainName =  window.location.hostname;
var api_url = domainName+api_name;

switch (domainName) {
  case '141.94.222.182':
    api_url = domainName+':5000'+api_name;
    break;
  default:
}

export const listNotificationsRules = (organization_id) => dispatch => {
      axios.get(
        api_ssl+'://'+api_url+'/notifications/rules/list',
        { params: { organization_id: organization_id } }
      )
      .then(response => {
          dispatch({
              type: GET_NOTIFICATIONS_RULES_LIST,
              payload: response.data
          });
      });
}

export const addNotificationsRule = (rule, history) => dispatch => {
    axios.post(api_ssl+'://'+api_url+'/notifications/rules/add', rule)
            .then(function (response) {
              axios.get(
                api_ssl+'://'+api_url+'/notifications/rules/list',
                { params: { organization_id: rule.organization_id } }
              )
              .then(response => {
                  dispatch({
                      type: GET_NOTIFICATIONS_RULES_LIST,
                      payload: response.data
                  });
              });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data
                });
            });
}
export const editNotificationsRule = (rule, history) => dispatch => {
    axios.post(api_ssl+'://'+api_url+'/notifications/rules/edit', rule)
            .then(function (response) {
              axios.get(
                api_ssl+'://'+api_url+'/notifications/rules/list',
                { params: { organization_id: rule.organization_id } }
              )
              .then(response => {
                  dispatch({
                      type: GET_NOTIFICATIONS_RULES_LIST,
                      payload: response.data
                  });
              });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data
                });
            });
}
export const deleteNotificationsRule = (rule, history) => dispatch => {
    console.log('rule organization_id', rule.organization_id);
    axios.post(api_ssl+'://'+api_url+'/notifications/rules/delete', rule)
            .then(function (response) {
              axios.get(
                api_ssl+'://'+api_url+'/notifications/rules/list',
                { params: { organization_id: rule.organization_id } }
              )
              .then(response => {
                  dispatch({
                      type: GET_NOTIFICATIONS_RULES_LIST,
                      payload: response.data
                  });
              });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data
                });
            });
}
export const toggleNotificationsRule = (rule, history) => dispatch => {
    axios.post(api_ssl+'://'+api_url+'/notifications/rules/toggle', rule)
            .then(function (response) {
              axios.get(
                api_ssl+'://'+api_url+'/notifications/rules/list',
                { params: { organization_id: rule.organization_id } }
              )
              .then(response => {
                  dispatch({
                      type: GET_NOTIFICATIONS_RULES_LIST,
                      payload: response.data
                  });
              });
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data
                });
            });
}
