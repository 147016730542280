import React from "react";
import {
  Link
} from "react-router-dom";
import { IconButton, Input, SelectPicker } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';
import axios from 'axios';
import { addDays, subDays } from "date-fns";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from '../../WithRouter';
import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment';
import { listNotifications, readNotification } from '../../actions/Notification';
import SettingHorizontalIcon from '@rsuite/icons/SettingHorizontal';
import {Notification} from "./DisplayNotificationDetails";
import {CalendarModal} from "../calendar_notifications";

const notificationTypesList = {
  'satelitesNone': 'Brak satelitów',
  'satelitesLow': 'Słaby sygnał GPS',
  'voltageLow': 'Niskie napięcie akumulatora',
  'voltageNone': 'Utracono zasilanie',
  'ignitionNone': 'Jazda z wyłączonym zapłonem'
};

const getLicenseplate = (vl, codename) => {
  const obj = vl.find(o => o.codename === codename);
  let r = codename;
  if(obj !== undefined) {
    r = obj['label'];
  }
  return r
}


function durationFormated(first, last) {
  // console.log(first, last);
  let start = moment(new Date(first)); // some random moment in time (in ms)
  let end = moment(new Date(last)); // some random moment after start (in ms)
  let diff = end.diff(start);
  console.log(start);
// execution
  // let f = moment.utc(diff.asMilliseconds()).format("HH:mm:ss.SSS");
  let f = moment.utc(diff).format("HH:mm:ss.SSS");
  let days = (moment.utc(diff).format("DD"))-1;
  let hours = moment.utc(diff).format("HH");
  let minutes = moment.utc(diff).format("mm");
  let seconds = moment.utc(diff).format("ss");
  const formated = days+' dni '+hours+ ' godz. '+minutes+' min. '+seconds+' sek.';
  // console.log(formated);
  return formated
}

class DisplayNotificationsSocket extends React.Component {
  constructor(props) {
  super(props);
      this.state = {
        calMaxDays: 31,
        calendarVisibility: false,
        currentNotification: [],
        panelDetails: false,
        data: [],
        modal: false,
        notificationsList: [],
        vehiclesList: [],
        filtersDateStart: subDays(new Date(), 30),
        filtersDateEnd: addDays(new Date(), 1),
        filtersRead: 'unread',
        filtersType: 'all'
      };
      this.handleMarkRead = this.handleMarkRead.bind(this);
      this.handleShowDetails = this.handleShowDetails.bind(this);
      this.handleCloseDetails = this.handleCloseDetails.bind(this);
      this.showCalendar = this.showCalendar.bind(this);
      this.calendarChange = this.calendarChange.bind(this);
      this.handleReadTypeChange = this.handleReadTypeChange.bind(this);
      this.handleNotificationTypeChange = this.handleNotificationTypeChange.bind(this);
      // this.handleFilters = this.handleFilters.bind(this);

  }

  componentDidMount() {
    let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    let at = '';
    let oi = '';
    if(auth_get) {
      at = auth_get.access_token;
      oi = auth_get.organization_id;
    }
    this.setState({
      vehiclesList: this.props.vehicles.list,
      // notificationsList: this.props.notifications.list
    });


    const params = {
      dateStart: this.state.filtersDateStart.getTime(),
      dateEnd: this.state.filtersDateEnd.getTime(),
      read: this.state.filtersRead,
      type: this.state.filtersType,
      organization_id: oi,
    }
    this.props.listNotifications(params, this.props.history);


    this.timeout = setInterval(() => {
      let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
      let at = '';
      let oi = '';
      if(auth_get) {
        at = auth_get.access_token;
        oi = auth_get.organization_id;
      }
      const params = {
        dateStart: this.state.filtersDateStart.getTime(),
        dateEnd: this.state.filtersDateEnd.getTime(),
        read: this.state.filtersRead,
        type: this.state.filtersType,
        organization_id: oi,
      }
      this.props.listNotifications(params, this.props.history);
    }, 10000)

  }

  componentDidUpdate(prevProps) {
    if(prevProps.notifications.list.data !== this.props.notifications.list.data) {
      this.setState({notificationsList: this.props.notifications.list.data});
    }
    if(prevProps.vehicles.list !== this.props.vehicles.list) {
      this.setState({vehiclesList: this.props.vehicles.list});
    }
    if(prevProps.orgId !== this.props.orgId ) {
      this.setState({organizationId:  this.props.orgId})
      const params = {
        dateStart: this.state.filtersDateStart.getTime(),
        dateEnd: this.state.filtersDateEnd.getTime(),
        read: this.state.filtersRead,
        type: this.state.filtersType,
        organization_id: this.props.orgId,
      }
      this.props.listNotifications(params, this.props.history);
    }
  }

  showCalendar(event) {
    const a = event.currentTarget.getAttribute('calvis');
    if (a==='closed') {
      this.setState({calendarVisibility: true});
    } else {
      this.setState({calendarVisibility: false});
    }
  };

  calendarChange = (childData) => {
    let ds = new Date(childData[0].rgns[0].startDate);
    let de = addDays(new Date(childData[0].rgns[0].endDate),1)
    this.setState({
        filtersDateStart: ds,
        filtersDateEnd: de ,
    });
    const params = {
      dateStart: ds.getTime(),
      dateEnd: de.getTime(),
      read: this.state.filtersRead,
      type: this.state.filtersType,
      organization_id: this.props.orgId,
    }
    this.props.listNotifications(params, this.props.history);
  };

  handleShowDetails = (notification, event) => {
    notification['licenseplate'] = getLicenseplate(this.state.vehiclesList, notification.codename);
    notification['duration'] = durationFormated(notification.first,notification.last);
    notification['typeName'] = notificationTypesList[notification.notificationType];
    // console.log(notification);
    this.setState({
      panelDetails: true,
      currentNotification: notification,
    });
  };

  handleCloseDetails = (notification, event) => {
    this.setState({
      panelDetails: false
    });
  }
  handleMarkRead = (notification, event) => {
      const params = {
        _id: notification._id,
        dateStart: this.state.filtersDateStart.getTime(),
        dateEnd: this.state.filtersDateEnd.getTime(),
        read: this.state.filtersRead,
        type: this.state.filtersType,
        organization_id: notification.organization_id,
        latest: false
      }
      this.props.readNotification(params, this.props.history);
      // this.props.listNotifications(this.props.orgId, this.props.history);
  }


  // handleFilters(event) {
  //   console.log('filter');
  //   const params = {
  //     dateStart: this.state.filtersDateStart.getTime(),
  //     dateEnd: this.state.filtersDateEnd.getTime(),
  //     read: this.state.filtersRead,
  //     type: this.state.filtersType,
  //     organization_id: this.props.orgId,
  //   }
  //   this.props.listNotifications(params, this.props.history);
  // }

  handleReadTypeChange(event) {
    this.setState({filtersRead: event});
      const params = {
        dateStart: this.state.filtersDateStart.getTime(),
        dateEnd: this.state.filtersDateEnd.getTime(),
        read: event,
        type: this.state.filtersType,
        organization_id: this.props.orgId,
      }
      this.props.listNotifications(params, this.props.history);
  }

  handleNotificationTypeChange(event) {
    this.setState({filtersType: event});
    const params = {
      dateStart: this.state.filtersDateStart.getTime(),
      dateEnd: this.state.filtersDateEnd.getTime(),
      read: this.state.filtersRead,
      type: event,
      organization_id: this.props.orgId,
    }
    this.props.listNotifications(params, this.props.history);
  }

  render() {
    // console.log(this.state.filtersDateStart.getTime());
    // console.log(this.state.filtersDateEnd.getTime());

    const data = [
      { label: 'Wszystkie', value: 'all' },
      { label: 'Nieodczytane', value: 'unread' },
      { label: 'Odczytane', value: 'read' }
    ]

    let ftp = [{ value: 'all', label: 'Wszystkie typy' }];
    const filter_types = Object.entries(notificationTypesList).map(([name, obj]) => ({ value: name, label: obj }))
    ftp.push(...filter_types);

    return (
      <div className="content-wrapper notifications">
      <div className="header">
        <div className="left">
          <ul className="header-list">
          <li className="header-list-element active">
            <Link to="/notifications">
              Lista Powiadomień
            </Link>
          </li>
          <li className="header-list-element">
            <Link to="/notifications/rules">
              Reguły Powiadomień
            </Link>
          </li>
          </ul>
        </div>
        <div className="right">
        </div>
      </div>
        <div className="content">
          <div className={`content-left ${this.state.panelDetails === true? 'opened': ''}`}>
          <div className="filters">
            <SelectPicker cleanable={false} onChange={this.handleReadTypeChange} defaultValue={data[1].value} data={data} searchable={false} style={{ width: 224 }} />
            <SelectPicker cleanable={false} onChange={this.handleNotificationTypeChange} defaultValue={data[0].value} data={ftp} searchable={false} style={{ width: 224 }} />
            <div className="visible-date" onClick={this.showCalendar} calvis={this.state.calendarVisibility === true? 'opened': 'closed'}>
              <CalendarModal  calMaxDays={this.state.calMaxDays} calRanges={this.calendarChange} calendarVisibility={this.state.calendarVisibility}/>
            </div>
            {/*
              <div className="filters-button">
              <IconButton
                appearance="primary"
                onClick={this.handleFilters}
                className="new-button"
                size="md"
                icon={<SettingHorizontalIcon />}>
                  Filtruj
              </IconButton>
              </div>
            */}
          </div>
            <ul>
              {this.state.notificationsList.map((notification, i) => (
                <li key={i} className='notification'>
                <div className="left">
                  <div className="top-line">
                    <div className="notification=type">
                      {notificationTypesList[notification.notificationType]}
                    </div>
                  </div>
                  <div className="middle-line">
                    <div className="vehicle">
                      <div className="label">
                        Pojazd:
                      </div>
                      <div className="value">
                        <div className="codename">
                          {notification.codename}
                        </div>
                        <div className="slash">
                          /
                        </div>
                        <div className="licenseplate">
                          {getLicenseplate(this.props.vehicles.list, notification.codename)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bottom-line">
                    <div className="date-start">
                      <div className="label">
                        Rozpoczęcie:
                      </div>
                      <Moment format="DD.MM.YYYY HH:mm:ss">
                        {new Date(notification.first)}
                      </Moment>
                    </div>
                    <div className="date-end">
                      <div className="label">
                        Zakończenie:
                      </div>
                      <Moment format="DD.MM.YYYY HH:mm:ss">
                        {new Date(notification.last)}
                      </Moment>
                    </div>
                    <div className="duration">
                      <div className="label">
                        Czas trwania:
                      </div>
                        {durationFormated(notification.first,notification.last)}
                    </div>
                  </div>
                </div>
                <div className="right">
                  {notification.read === false ? (
                    <div className="read" onClick={() => this.handleMarkRead(notification, i)}>
                      Odczytaj
                    </div>
                    ) : (
                    <div className="read true">
                      Odczytane
                    </div>
                  )}
                  <div className="details" onClick={() => this.handleShowDetails(notification, i)}>
                    Szczegóły
                  </div>
                </div>
                </li>
              ))}
            </ul>
            </div>
            <div className={`content-right ${this.state.panelDetails === true? 'opened': ''}`}>
              <div className="inner">
                <Notification goBack={this.handleCloseDetails} currentNotification={this.state.currentNotification} />
              </div>
            </div>
        </div>
      </div>
    );
  }
}

DisplayNotificationsSocket.propTypes = {
    listNotifications: PropTypes.func.isRequired,
    readNotification: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    notifications: state.notifications,
    vehicles: state.vehicles,
    errors: state.errors
});

export default connect(mapStateToProps,{ listNotifications, readNotification })(withRouter(DisplayNotificationsSocket))
