import { combineReducers } from 'redux';
import errorReducer from './errorReducer';
import integrationsEtollReducer from './integrationsEtollReducer';
import vehiclesReducer from './vehiclesReducer';
import sharedVehiclesReducer from './sharedVehiclesReducer';
import notificationsReducer from './notificationsReducer';
import notificationsLatestReducer from './notificationsLatestReducer';
import notificationsRulesReducer from './notificationsRulesReducer';
import orgcurrentReducer from './orgcurrentReducer';
import EtollSentStatusListReducer from './EtollSentStatusListReducer';
import EtollSentStatusSummaryReducer from './EtollSentStatusSummaryReducer';
import SentgeoSentStatusListReducer from './SentgeoSentStatusListReducer';
import SentgeoSentStatusSummaryReducer from './SentgeoSentStatusSummaryReducer';

export default combineReducers({
    errors: errorReducer,
    integrationsEtoll: integrationsEtollReducer,
    vehicles: vehiclesReducer,
    sharedVehicles: sharedVehiclesReducer,
    notifications: notificationsReducer,
    notificationsLatest: notificationsLatestReducer,
    notificationsRules: notificationsRulesReducer,
    orgcurrent: orgcurrentReducer,
    etollsentstatuslist: EtollSentStatusListReducer,
    etollsentstatussummary: EtollSentStatusSummaryReducer,
    sentgeosentstatuslist: SentgeoSentStatusListReducer,
    sentgeosentstatussummary: SentgeoSentStatusSummaryReducer
});
