// import { useMemo, useRef } from "react";
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";

function MarkerOrder(props) {
  const { id, latitude, longitude, address } = props;

  const navIcon = new L.divIcon({
    iconAnchor: [0,0],
    popupAnchor: [0,0],
    shadowSize: "12px",
    iconSize: new L.Point(140, 24),
    className: 'marker-vehicle marker-vehicle-'+id,
    html:
      `<div class="icon">
        <div class="inner">
        </div>
       </div>
      <div class="label">
        <div id="more-marker-vehicle-`+id+`" class="more">
        </div>
        <div class="less">
          <div class="text">`+address+`</div>
        </div>
       </div>`
  });

  return (
    <Marker
      key={id}
      position={[latitude, longitude]}
      icon={navIcon}
      vehicleid={id}
      eventHandlers={{
        click: (e) => {
          e.target._icon.classList.add("bulbazaur");
          console.log(e);
        },
      }}
    >
    </Marker>
  );
}

export default MarkerOrder;
