import React, {useState, useEffect, Component } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";
import axios from 'axios';
import { Provider } from 'react-redux';
import store from './store';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {login, useAuth, logout} from "./auth"
import './App.scss';
import { Dropdown } from 'rsuite';
import { DeviceUUID } from 'device-uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {DisplayOpenStreetMap} from './DisplayOpenStreetMap';
import {DisplayDevicesSocket} from './DisplayDevicesSocket';
import DisplayOrganizationsSocket from './DisplayOrganizationsSocket';
import {DisplayUsersSocket} from './DisplayUsersSocket';
import {DisplayDashboardSocket} from "./components/dashboard/Dashboard";
import DisplayReportsSocket from "./components/reports/DisplayReportsSocket";
import DisplayNotificationsSocket from "./components/notifications/DisplayNotificationsSocket";
import DisplayNotificationsRules from "./components/notifications/DisplayNotificationsRules";
import {DisplayAdminSocket} from "./components/admin/DisplayAdminSocket";
import {DisplayVehilceAccess} from "./components/vehilceAccess/vehilceAccessPage";
import NavigationNotificationsLatest from "./components/navigation/notifications";
import DisplayOrdersList from "./components/orders/DisplayOrdersList";
import {DisplayTemperatureSocket} from "./components/temperature/DisplayTemperatureSocket";
import DisplayEtollList from "./components/etollStatus/etollStatusList";
import DisplayDriverAssist from "./components/driverAssist/driverAssist";
import DisplaySentgeoList from "./components/sentgeoStatus/sentgeoStatusList";
import DisplayEtollSentRecords from "./components/etollStatus/sentRecords";
import useDocumentTitle from "./components/useDocumentTitle"
import {SelectOrg} from "./OrganizationSwitch";
import { RiRoadMapLine } from "react-icons/ri";
import { RiHome5Line } from "react-icons/ri";
import { RiUser3Line } from "react-icons/ri";
import { RiNotification4Line } from "react-icons/ri";
import { RiBuildingLine } from "react-icons/ri";
import { RiDraftLine } from "react-icons/ri";
import { RiCarLine } from "react-icons/ri";
import { RiFileChartLine } from "react-icons/ri";
import { RiLineChartLine } from "react-icons/ri";
import { TiThermometer } from "react-icons/ti";
import { BsCpu } from "react-icons/bs";
import { IoMdSettings } from "react-icons/io";
import { IoIosLogOut } from "react-icons/io";
import { RiSendToBack } from "react-icons/ri";
import { SiStripe } from "react-icons/si";
import { RiSteering2Line } from "react-icons/ri";


import { listVehcles } from './actions/Vehicle';
import { listSharedVehcles } from './actions/SharedVehicle';
import { listNotifications, latestNotifications } from './actions/Notification';
import { listNotificationsRules } from './actions/NotificationRules';
import { setCurrentOrganization } from './actions/Organization';
import {newsleterAccept} from './serviceWorkerRegistration';


const api_name = process.env.REACT_APP_API_URL;
const api_ssl = process.env.REACT_APP_API_SSL;
const api_ws = process.env.REACT_APP_API_WS;
const dm_pgps = process.env.REACT_APP_DM_PLGPS;
const domainName =  window.location.hostname;
var api_url = domainName+api_name;


var app_custom_class_name = '';
var favicon_url = '';
var manifest_url = '';
var system_name = '';
switch (domainName) {
  case dm_pgps:
    app_custom_class_name = 'plusgps_styles'
    favicon_url = '/favicon_plusgps.png'
    manifest_url = '/manifestplusgps.json'
    system_name = 'PlusGps'
    break;
  case '141.94.222.182':
    app_custom_class_name = 'default_styles'
    api_url = domainName+':5000'+api_name;
    favicon_url = '/favicon_smart.png'
    manifest_url = '/manifest.json'
    system_name = 'Smart Plusfleet'
    break;
  default:
    app_custom_class_name = 'default_styles'
    favicon_url = '/favicon_smart.png'
    manifest_url = '/manifest.json'
    system_name = 'Smart Plusfleet'
}

function getFaviconEl() {
  return document.getElementById("favicon");
}
function getManifestEl() {
  return document.getElementById("manifest");
}

const renderButton = (props, ref) => {
  return (
    <div {...props} ref={ref} className="icon-element user-info ">
      <span className="circle-initial">
        <RiUser3Line />
      </span>
    </div>
  );
}

const vehicleaccess = [
];

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      devicesSelect: [],
      vehicleAccessSelect: [],
      isUserAuthenticated: false,
      isUserAdmin: false,
      navWidth: false,
      org_id: '',
      roles: '',
      ws: null,
      currentTabOpen: '',
      organizationsList: null,
      org_current: []
    };
    this.handleCurrentTab = this.handleCurrentTab.bind(this);
    this.navWideChange = this.navWideChange.bind(this);
    this.logoutButton = this.logoutButton.bind(this);
    this.connect = this.connect.bind(this);
    this.sendToWs = this.sendToWs.bind(this);
    this.sendToToast = this.sendToToast.bind(this);
    // this.check = this.check.bind(this);
  }

  componentDidMount() {
    const device_uuid = new DeviceUUID().get();
    
    // this.setState({org_current: this.props.orgcurrent.list});
    localStorage.setItem("DEVICE_UUID", device_uuid)
    let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    // console.log('auth_get', auth_get)
    let at = '';
    let og = '';
    let rl = '';
    if(auth_get) {
      at = auth_get.access_token;
      og = auth_get.organization_id;
      rl = auth_get.roles;
    }
    if(at !== '') {
      store.dispatch(latestNotifications(auth_get.organization_id));
      store.dispatch(setCurrentOrganization(og));
      // console.log('Subscribtion check')
    //   newsleterAccept(device_uuid, auth_get.user_id, og);
      this.setState({
        isUserAuthenticated: true,
        org_id: og,
        roles: rl
      });
    }

    store.subscribe(() => {
      const newState = store.getState();
      let old_olist = this.state.org_current;
      let new_olist = newState.orgcurrent.list;
      // console.log('old_olist', old_olist, new_olist)
      if(old_olist !== new_olist) {
        // console.log('ORG changed new state!!!')

        this.setState({org_current: new_olist});
      }

     // check out your updated state
    });

    document.title = system_name;
    const favicon = getFaviconEl();
    const manifest = getManifestEl();
    favicon.href = favicon_url;
    manifest.href = manifest_url;


    axios.get(api_ssl+'://'+api_url+'/sharedvehicles/listtoshowonmap')
    .then(function (response) {
      let d = response.data.data;
      for (let i = 0; i < d.length; i++) {
        vehicleaccess.push(d[i]);
      }
    })
    .catch(function (error) {
      console.log(error);
    });




    if (this.state.ws === null || this.state.ws.readyState !== 1) {
      this.connect();
    }

    this.timeout = setInterval(() => {
      axios.get(api_ssl+'://'+api_url+'/sharedvehicles/listtoshowonmap')
      .then(function (response) {
        let d = response.data.data;
        for (let i = 0; i < d.length; i++) {
          vehicleaccess.push(d[i]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

      let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
      let at = '';
      if(auth_get) {
        at = auth_get.access_token;
        store.dispatch(latestNotifications(auth_get.organization_id));

        if (this.state.ws.readyState === 1) {
            let msg_to_server = JSON.stringify({"type":"devices_select", "at":at, "organization_id":auth_get.organization_id});
            let msg_to_server3 = JSON.stringify({"type":"vehicle_access_select", "at":at, "organization_id":auth_get.organization_id});
            this.state.ws.send(msg_to_server);
            this.state.ws.send(msg_to_server3);
        }
      }
    }, 10000)

  }

  // componentDidUpdate(prevProps) {
  //   console.log('org_current', this.props)
  //   // console.log('org_current', this.state.org_current)
  //   if(prevProps.orgcurrent !== this.props.orgcurrent) {
  //     this.setState({org_current: this.props.orgcurrent.list});
  //   }
  // }


  connect = () => {
    var ws = new W3CWebSocket(api_ws+'://'+api_url+'/new');
    let that = this; // cache the this


    let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    let at = '';
    let oi = '';
    if(auth_get) {
      at = auth_get.access_token;
      oi = auth_get.organization_id;
    }

    let msg_to_server = JSON.stringify({"type":"organizations_list", "at":at, "organization_id":oi});
    let msg_to_server2 = JSON.stringify({"type":"devices_select", "at":at, "organization_id":oi});
    let msg_to_server3 = JSON.stringify({"type":"vehicle_access_select", "at":at, "organization_id":oi});
    // let msg_to_server_notificatins_list = JSON.stringify({"type":"notifications_list", "at":at, "organization_id":auth_get.organization_id});
    // let msg_to_server_notificatins_rules_list = JSON.stringify({"type":"notificatins_rules_list", "at":at, "organization_id":auth_get.organization_id});


    ws.onopen = () => {
      that.setState({ ws: ws });
      let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
      if (auth_get) {
        ws.send(msg_to_server);
        ws.send(msg_to_server2);
        ws.send(msg_to_server3);
        // ws.send(msg_to_server_notificatins_list);
        // ws.send(msg_to_server_notificatins_rules_list);
      }
    };

    ws.onmessage = (message) => {
      let dataJson = JSON.parse(message.data);
      if(dataJson.type === 'organizations_add') {
        this.setState({modal: false});
      } else if(dataJson.type === 'organizations_edit') {
        // toast.success('Zmiany zostały zapisane');
      } else if(dataJson.type === 'organizations_list') {
        const data = dataJson.data;
        this.setState({organizationsList: data});
      } else if(dataJson.type === 'devices_select') {
        const data = dataJson.data;
        // console.log('devicesSelect');
        this.setState({devicesSelect: data});
        store.dispatch(listVehcles(data));
      } else if(dataJson.type === 'vehicle_access_select') {
        const data = dataJson.data;
        this.setState({vehicleAccessSelect: data});
        store.dispatch(listSharedVehcles(data));
      } else if(dataJson.type === 'notifications_list') {
        const data = dataJson.data;
        store.dispatch(listNotifications(data));
      }
    };

    ws.onclose = (e) => {
      that.setState({ ws: [] });
      setTimeout(function () {
        that.connect();
      }, 3000);
    };

    ws.onerror = (err) => {
      // console.error(err.message);
      ws.close();
    };
  };



  handleCurrentTab = (childData) => {
    this.setState({
      currentTabOpen: childData
    });
  };


  handleRolesChange = (childData) =>{
    if(childData) {
      this.setState({ roles: childData });
    }
  }



  handleCallback = (childData) =>{
    if(childData === 'loggedin') {
      this.setState({ isUserAuthenticated: true });
    } else if (childData === 'loggedout') {
      this.setState({ isUserAuthenticated: false });
    }
  }

  handleOrgChange = (childData) =>{
    this.setState({org_id: childData});
    let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    let at = '';
    let oi = '';
    if(auth_get) {
      at = auth_get.access_token;
      oi = auth_get.organization_id;
    }
    let msg_to_server = JSON.stringify({"type":"devices_select", "at":at, "organization_id":oi});
    this.state.ws.send(msg_to_server);
  }

  navWideChange(e) {
    if(e.target.classList.contains('wide')){
      this.setState({navWidth: false});
    } else {
      this.setState({navWidth: true});
    }
  };

  logoutButton(e) {
    logout();
    this.setState({isUserAuthenticated: false});
  };

  sendToWs = (childData) => {
    let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    let at = '';
    if(auth_get) {
      at = auth_get.access_token;
    }
    let msg_to_server3 = JSON.stringify({"type":childData[0], "at":at, "organization_id":auth_get.organization_id});
    this.state.ws.send(msg_to_server3);
  };

  sendToToast = (childData) => {
    let type = childData[1];
    let cont = childData[2];
    toast.success(cont);

  }


  render() {
    const isUserAuthenticated = this.state.isUserAuthenticated;
    const userRoles = this.state.roles;
    let func_etoll = this.state.org_current.func_etoll;
    // console.log('func_etoll', func_etoll);
    return (
      <Provider store = { store }>
      <Router>
      <div className={`container ${app_custom_class_name}`}>
          {isUserAuthenticated
            ?
            <div className={`container-left ${this.state.navWidth === true? 'wide': ''}`}>
              <div className="logo">
                {app_custom_class_name==='plusgps_styles'
                  ? (
                    <svg version="1.1" x="0px" y="0px"
                    	 viewBox="0 0 300 301">
                    <rect y="100" fill="#36459C" width="300" height="100"/>
                    <path fill="#46C7EF" d="M100,0c0,55.2-44.8,100-100,100v100C89.2,200,199.7,80,200,0H100z"/>
                    <path fill="#46C7EF" d="M200,300c0-55.2,44.8-100,100-100V100c-90-0.3-199.8,121-200,201L200,300z"/>
                    </svg>
                  )
                  : (
                    <svg version="1.1" x="0px" y="0px"
                       viewBox="0 0 153 159">
                       <g id="Layer_2">
                       <g>
                          <path className="st9" d="M27.13,154.25c-11.37-3.3-20.5-7.59-27.39-12.87l12.1-26.84c6.6,4.84,14.44,8.73,23.54,11.66
                            c9.09,2.94,18.18,4.4,27.28,4.4c10.12,0,17.6-1.5,22.44-4.51c4.84-3,7.26-7,7.26-11.99c0-3.67-1.43-6.71-4.29-9.13
                            c-2.86-2.42-6.53-4.36-11-5.83c-4.48-1.46-10.53-3.08-18.15-4.84c-11.74-2.79-21.34-5.57-28.82-8.36
                            c-7.48-2.78-13.9-7.26-19.25-13.42C5.5,66.36,2.82,58.15,2.82,47.88c0-8.94,2.42-17.05,7.26-24.31S22.22,10.55,31.97,6.3
                            c9.75-4.25,21.67-6.38,35.75-6.38c9.82,0,19.43,1.18,28.82,3.52c9.38,2.35,17.6,5.72,24.64,10.12l-11,27.06
                            c-14.23-8.06-28.46-12.1-42.68-12.1c-9.98,0-17.35,1.62-22.11,4.84c-4.77,3.23-7.15,7.48-7.15,12.76s2.75,9.21,8.25,11.77
                            c5.5,2.57,13.89,5.1,25.19,7.59c11.73,2.79,21.34,5.58,28.82,8.36c7.48,2.79,13.89,7.19,19.25,13.2
                            c5.35,6.02,8.03,14.16,8.03,24.42c0,8.8-2.46,16.83-7.37,24.09c-4.92,7.26-12.29,13.02-22.11,17.27
                            c-9.83,4.25-21.78,6.38-35.86,6.38C50.27,159.2,38.5,157.55,27.13,154.25z"/>
                        </g>
                        <circle className="st10" cx="129.5" cy="135.5" r="23.5"/>
                       </g>
                    </svg>
                  )
                }

              </div>
              <nav className={`main-nav ${this.state.navWidth === true? 'wide': ''}`}>
                <ul>
                  <li>
                    <NavLink exact activeClassName="active" to="/">
                      <RiHome5Line />
                      <span className="label"><span className="inner">Dashboard</span></span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to="/reports">
                      <RiFileChartLine />
                      <span className="label"><span className="inner">Raporty</span></span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to="/map">
                      <RiRoadMapLine />
                      <span className="label"><span className="inner">Mapa</span></span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to="/users">
                      <RiUser3Line />
                      <span className="label"><span className="inner">Użytkownicy</span></span>
                    </NavLink>
                  </li>
                  {userRoles === "admin"?
                  <li>
                    <NavLink activeClassName="active" to="/notifications">
                      <RiNotification4Line />
                      <span className="label"><span className="inner">Powiadomienia</span></span>
                    </NavLink>
                  </li>
                  :
                  ''
                  }
                  <li>
                    <NavLink activeClassName="active" to="/devices">
                      <RiCarLine />
                      <span className="label"><span className="inner">Pojazdy</span></span>
                    </NavLink>
                  </li>
                  {userRoles === "admin"?
                  <li>
                    <NavLink activeClassName="active" to="/organizations">
                      <RiBuildingLine />
                      <span className="label"><span className="inner">Organizacje</span></span>
                    </NavLink>
                  </li>
                  :
                  ''
                  }
                  <li className="menu-el-charts">
                    <NavLink activeClassName="active" to="/charts">
                      <RiLineChartLine />
                      <span className="label"><span className="inner">Wykresy</span></span>
                    </NavLink>
                  </li>
                  {userRoles === "admin"?
                    <li className="menu-el-adminpanel">
                      <NavLink activeClassName="active" to="/adminpanel">
                        <BsCpu />
                        <span className="label"><span className="inner">Administracja</span></span>
                      </NavLink>
                    </li>
                  :
                  ''
                  }
                  {userRoles === "admin"?
                    <li className="menu-el-orders">
                      <NavLink activeClassName="active" to="/orders">
                        <RiDraftLine />
                        <span className="label"><span className="inner">Zlecenia</span></span>
                      </NavLink>
                    </li>
                  :
                  ''
                  }
                  {func_etoll === true?
                    <li className="menu-el-etollStatus">
                      <NavLink activeClassName="active" to="/etollstatus">
                        <svg id="Layer_1" stroke="currentColor" fill="currentColor" stroke-width="0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.41 101.41"><path d="M76.41,37.61A38.21,38.21,0,1,0,0,38.91c.4,21.1,39.3,62.5,39.3,62.5a318.39,318.39,0,0,0,23.8-31.6H44.71c-3.3,4.3-5.8,7.1-5.8,7.1s-11-11.8-18.1-23.3h51.3c2.7-5.9,4.4-11.5,4.3-16m-38.5-22.3A23.07,23.07,0,0,1,61.51,38v.2H15.11a23.27,23.27,0,0,1,22.8-22.9"/></svg>
                        <span className="label"><span className="inner">Status E-toll</span></span>
                      </NavLink>
                    </li>
                  :
                  ''
                  }
                  {userRoles === "admin"?
                    <li className="menu-el-sentgeoStatus">
                      <NavLink activeClassName="active" to="/sentgeostatus">
                        <SiStripe />
                        <span className="label"><span className="inner">Status E-toll</span></span>
                      </NavLink>
                    </li>
                  :
                  ''
                  }
                  {userRoles === "admin"?
                    <li className="menu-el-driverassist">
                      <NavLink activeClassName="active" to="/driverassist">
                        <RiSteering2Line />
                        <span className="label"><span className="inner">Asystent Kierowcy</span></span>
                      </NavLink>
                    </li>
                  :
                  ''
                  }
                  {userRoles === "admin"?
                    <li className="menu-el-etollSentRecords">
                      <NavLink activeClassName="active" to="/etollsentrecords">
                        <RiSendToBack />
                        <span className="label"><span className="inner">Wysane do E-toll</span></span>
                      </NavLink>
                    </li>
                  :
                  ''
                  }
                </ul>
              </nav>
              <div className="nav-width-change">
                <div onClick={this.navWideChange} className={`main-nav-width ${this.state.navWidth === true? 'wide': ''}`} >
                  <span onClick={this.navWideChange} className={`arrow ${this.state.navWidth === true? 'wide': ''}`}>
                    <span></span>
                    <span></span>
                  </span>
                </div>
              </div>
            </div>
            :
            <div className="login-placeholder"></div>
          }

          <div className="right-panel">

          {isUserAuthenticated
            ?
            <div className="top-bar">
              <div className="left">
                {userRoles === "admin"?
                  <SelectOrg organizationsList={this.state.organizationsList} sendToWs={this.sendToWs} parentCallback={this.handleOrgChange} currentUserRoles={userRoles} />
                :
                ' '
                }
              </div>
              <div className="right">
                <div className="hidden-options">
                {vehicleaccess.map((acc, index) => (
                  <h5 key={index}>
                    <Link to={`/vehicleaccess/${acc.code}`}>{acc.name}</Link>
                  </h5>
                ))}
                </div>
                {userRoles === "admin"?
                <div className="notifications-latest-container">
                  <NavigationNotificationsLatest orgId={this.state.org_id} />
                </div>
                :
                ' '
                }
                <Dropdown className="user_menu" renderToggle={renderButton} placement="bottomEnd">
                  <Dropdown.Item icon={<IoIosLogOut />} onClick={this.logoutButton}>
                    <span className="label">
                      Wyloguj
                    </span>
                  </Dropdown.Item>
                </Dropdown>
              </div>
            </div>
            :
            <div>
            </div>
          }





            {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
            <Switch>
              <Route exact path="/devices">
                  {isUserAuthenticated
                    ?
                    <DisplayDevicesSocket activeTab={this.handleCurrentTab} orgId={this.state.org_id} currentUserRoles={userRoles}/>
                    :
                    <Loginaaf  parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
                  }
              </Route>
              <Route exact path="/reports">
                  {isUserAuthenticated
                    ?
                    <DisplayReportsSocket orgId={this.state.org_id} rolesChange={this.handleRolesChange} />
                    :
                    <Loginaaf  parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
                  }
              </Route>
              <Route exact path="/map">
                  {isUserAuthenticated
                    ?
                    <DisplayOpenStreetMap orgId={this.state.org_id} sendToWs={this.sendToWs} sendToToast={this.sendToToast} currentVehiclesAccess={this.state.vehicleAccessSelect} />
                    :
                    <Loginaaf parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
                  }
              </Route>
              <Route exact path="/profile">
                <Loginaaf parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
              </Route>
              <Route exact path="/users">
                  {isUserAuthenticated
                    ?
                    <DisplayUsersSocket activeTab={this.handleCurrentTab} orgId={this.state.org_id} currentUserRoles={userRoles}/>
                    :
                    <Loginaaf parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
                  }
              </Route>
              <Route exact path="/organizations">
                  {isUserAuthenticated
                    ?
                    userRoles === "admin"?
                      <DisplayOrganizationsSocket />
                      :
                      <Loginaaf parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
                    :
                    <Loginaaf parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
                  }
              </Route>
              <Route exact path="/charts">
                  <DisplayTemperatureSocket activeTab={this.handleCurrentTab} orgId={this.state.org_id} devicesSelect={this.state.devicesSelect} />
              </Route>
              <Route exact path="/notifications">
                  {isUserAuthenticated
                    ?
                    userRoles === "admin"?
                      <DisplayNotificationsSocket orgId={this.state.org_id} />
                      :
                      <Loginaaf parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
                    :
                    <Loginaaf parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
                  }
              </Route>
              <Route exact path="/notifications/rules">
                  {isUserAuthenticated
                    ?
                    userRoles === "admin"?
                      <DisplayNotificationsRules orgId={this.state.org_id} />
                      :
                      <Loginaaf parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
                    :
                    <Loginaaf parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
                  }
              </Route>
              <Route exact path="/etollstatus">
                  {isUserAuthenticated
                    ?
                    func_etoll === true?
                      <DisplayEtollList orgId={this.state.org_id} />
                      :
                      <Loginaaf parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
                    :
                    <Loginaaf parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
                  }
              </Route>
              <Route exact path="/etollsentrecords">
                  {isUserAuthenticated
                    ?
                    userRoles === "admin"?
                      <DisplayEtollSentRecords orgId={this.state.org_id} />
                      :
                      <Loginaaf parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
                    :
                    <Loginaaf parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
                  }
              </Route>
              <Route exact path="/sentgeostatus">
                  {isUserAuthenticated
                    ?
                    userRoles === "admin"?
                      <DisplaySentgeoList orgId={this.state.org_id} />
                      :
                      <Loginaaf parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
                    :
                    <Loginaaf parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
                  }
              </Route>
              <Route exact path="/driverassist">
                  {isUserAuthenticated
                    ?
                    userRoles === "admin"?
                      <DisplayDriverAssist orgId={this.state.org_id} />
                      :
                      <Loginaaf parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
                    :
                    <Loginaaf parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
                  }
              </Route>
              <Route exact path="/adminpanel">
                  {isUserAuthenticated
                    ?
                    userRoles === "admin"?
                      <DisplayAdminSocket />
                      :
                      <Loginaaf parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
                    :
                    <Loginaaf parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
                  }
              </Route>
              <Route exact path="/orders">
                  {isUserAuthenticated
                    ?
                    userRoles === "admin"?
                      <DisplayOrdersList />
                      :
                      <Loginaaf parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
                    :
                    <Loginaaf parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
                  }
              </Route>
              <Route exact path="/">
                {isUserAuthenticated
                  ?
                  <DisplayDashboardSocket orgId={this.state.org_id} />
                  :
                  <Loginaaf  parentCallback={this.handleCallback} rolesChange={this.handleRolesChange} />
                }
              </Route>
              <Route exact path="/vehicleaccess/:code" component={DisplayVehilceAccess} orgId={this.state.org_id} sendToWs={this.sendToWs} isUserAuthenticated={this.state.isUserAuthenticated} currentVehiclesAccess={this.state.vehicleAccessSelect} />
            </Switch>
          </div>
        </div>
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        {/* Same as */}
        <ToastContainer />
      </Router>
      </Provider>

    );
  }
}

export default App;


// function Login() {
const Loginaaf = ({ parentCallback, rolesChange  }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const onSubmitClick = (e)=>{
    e.preventDefault()
    let opts = {
      'username': username,
      'password': password
    }
    fetch(api_ssl+'://'+api_url+'/api/login', {
      method: 'post',
      body: JSON.stringify(opts)
    }).then(r => r.json())
      .then(token => {
        if (token.access_token){
          login(token);
          const profile = {
            access_token: JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY')).access_token,
            organization_id: token.organization_id,
            user_id: token.user_id,
            roles: token.roles
          };
          rolesChange(token.roles);
          store.dispatch(setCurrentOrganization(token.organization_id));
          localStorage.setItem("REACT_TOKEN_AUTH_KEY", JSON.stringify(profile))
          parentCallback('loggedin');
          const device_uuid = localStorage.getItem("DEVICE_UUID");
        //   newsleterAccept(device_uuid, token.user_id, token.organization_id);
        }
        else {
          console.log("Please type in correct username/password")
        }
      })
  }

  const handleUsernameChange = (e) => {
    setUsername(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const [logged] = useAuth();

  const onLogoutClick = (e)=>{
    logout();
    parentCallback('loggedout')
  }

  return (
    <div className="login-container">
      <div className="login-block">
        {!logged? <form action="#">
        <div className="logo">
        {app_custom_class_name==='plusgps_styles'
          ? (
            <div classname="logo_cont">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            	 viewBox="0 0 1288.9 300" enable-background="new 0 0 1288.9 300">
            <g id="Layer_3">
            	<rect y="100" fill="#36459C" width="300" height="100"/>
            	<path fill="#46C7EF" d="M100,0c0,55.2-44.8,100-100,100v100c36.4,0,70.6-9.7,100-26.8c30.4-17.6,55.7-42.9,73.2-73.2
            		C190.3,70.6,200,36.4,200,0H100z"/>
            	<path fill="#46C7EF" d="M200,300c0-55.2,44.8-100,100-100V100c-36.4,0-70.6,9.7-100,26.8c-30.4,17.6-55.7,42.9-73.2,73.2
            		c-17,29.4-26.8,63.6-26.8,100H200z"/>
            	<g>
            		<path fill="#36459C" d="M493,74.7c9.8,4.4,17.4,10.8,22.7,18.9c5.3,8.2,8,17.8,8,29c0,11-2.7,20.7-8,28.9
            			c-5.3,8.2-12.9,14.5-22.7,18.9c-9.8,4.4-21.4,6.6-34.7,6.6h-30.3v41.5h-34.8V68.1h65.1C471.6,68.1,483.1,70.3,493,74.7z
            			 M480.2,141.9c5.4-4.5,8.2-10.9,8.2-19.2c0-8.5-2.7-14.9-8.2-19.5c-5.4-4.5-13.4-6.8-23.9-6.8h-28.4v52.2h28.4
            			C466.8,148.7,474.7,146.5,480.2,141.9z"/>
            		<path fill="#36459C" d="M548.1,59.1h33.5v159.5h-33.5V59.1z"/>
            		<path fill="#36459C" d="M729.4,102.9v115.7h-31.8v-13.8c-4.4,5-9.7,8.9-15.9,11.5c-6.2,2.7-12.8,4-20,4
            			c-15.2,0-27.2-4.4-36.1-13.1c-8.9-8.7-13.3-21.7-13.3-38.9v-65.4h33.5v60.4c0,18.6,7.8,28,23.4,28c8,0,14.5-2.6,19.4-7.8
            			c4.9-5.2,7.3-13,7.3-23.3v-57.2H729.4z"/>
            		<path fill="#36459C" d="M771.3,216.8c-9.2-2.4-16.5-5.3-21.9-8.9l11.2-24.1c5.2,3.3,11.4,6,18.7,8.1c7.3,2.1,14.5,3.1,21.5,3.1
            			c14.2,0,21.3-3.5,21.3-10.5c0-3.3-1.9-5.7-5.8-7.1c-3.9-1.4-9.8-2.7-17.8-3.7c-9.5-1.4-17.3-3.1-23.4-4.9
            			c-6.2-1.9-11.5-5.2-16-9.9c-4.5-4.7-6.8-11.5-6.8-20.2c0-7.3,2.1-13.8,6.3-19.5c4.2-5.7,10.4-10.1,18.5-13.2
            			c8.1-3.2,17.7-4.7,28.7-4.7c8.2,0,16.3,0.9,24.4,2.7c8.1,1.8,14.8,4.3,20.1,7.4L839,135.2c-10.2-5.7-21.3-8.6-33.3-8.6
            			c-7.2,0-12.5,1-16.1,3c-3.6,2-5.4,4.6-5.4,7.7c0,3.6,1.9,6.1,5.8,7.5c3.9,1.4,10,2.8,18.5,4.1c9.5,1.6,17.2,3.3,23.2,5.1
            			c6,1.8,11.3,5.1,15.7,9.8c4.4,4.7,6.7,11.3,6.7,19.8c0,7.2-2.1,13.5-6.5,19.1c-4.3,5.6-10.6,9.9-18.8,13c-8.2,3.1-18,4.6-29.3,4.6
            			C789.8,220.3,780.4,219.1,771.3,216.8z"/>
            		<path fill="#36459C" d="M978.8,141h31.8V202c-8.2,6.2-17.6,10.9-28.4,14.2c-10.8,3.3-21.6,4.9-32.5,4.9c-15.6,0-29.7-3.3-42.1-10
            			c-12.5-6.7-22.3-15.9-29.3-27.7s-10.6-25.2-10.6-40.1c0-14.9,3.5-28.3,10.6-40.1s16.9-21.1,29.6-27.7c12.6-6.7,26.8-10,42.6-10
            			c13.2,0,25.2,2.2,35.9,6.7c10.8,4.4,19.8,10.9,27.1,19.3L991,112.2c-10.8-11.3-23.7-17-38.9-17c-9.6,0-18.1,2-25.6,6
            			c-7.5,4-13.3,9.7-17.4,17c-4.2,7.3-6.2,15.7-6.2,25.2c0,9.3,2.1,17.6,6.2,24.9c4.2,7.3,9.9,13,17.3,17.1
            			c7.4,4.1,15.8,6.1,25.3,6.1c10,0,19.1-2.2,27.1-6.5V141z"/>
            		<path fill="#36459C" d="M1138.2,108.6c8.8,4.9,15.7,11.9,20.7,20.9c5,9,7.5,19.4,7.5,31.3c0,11.9-2.5,22.3-7.5,31.3
            			c-5,9-11.9,15.9-20.7,20.9c-8.8,4.9-18.6,7.4-29.3,7.4c-14.8,0-26.4-4.7-34.8-14v54h-33.5V102.9h32v13.3c8.3-10,20.4-15,36.3-15
            			C1119.6,101.2,1129.4,103.7,1138.2,108.6z M1124.2,184.1c5.5-5.8,8.3-13.6,8.3-23.3c0-9.7-2.8-17.5-8.3-23.3
            			c-5.5-5.8-12.6-8.7-21.2-8.7s-15.7,2.9-21.2,8.7c-5.5,5.8-8.3,13.6-8.3,23.3c0,9.7,2.8,17.5,8.3,23.3c5.5,5.8,12.6,8.7,21.2,8.7
            			S1118.7,189.9,1124.2,184.1z"/>
            		<path fill="#36459C" d="M1199.6,216.8c-9.2-2.4-16.5-5.3-21.9-8.9l11.2-24.1c5.2,3.3,11.4,6,18.7,8.1c7.3,2.1,14.5,3.1,21.5,3.1
            			c14.2,0,21.3-3.5,21.3-10.5c0-3.3-1.9-5.7-5.8-7.1c-3.9-1.4-9.8-2.7-17.8-3.7c-9.5-1.4-17.3-3.1-23.4-4.9
            			c-6.2-1.9-11.5-5.2-16-9.9c-4.5-4.7-6.8-11.5-6.8-20.2c0-7.3,2.1-13.8,6.3-19.5c4.2-5.7,10.4-10.1,18.5-13.2
            			c8.1-3.2,17.7-4.7,28.7-4.7c8.2,0,16.3,0.9,24.4,2.7c8.1,1.8,14.8,4.3,20.1,7.4l-11.2,23.9c-10.2-5.7-21.3-8.6-33.3-8.6
            			c-7.2,0-12.5,1-16.1,3c-3.6,2-5.4,4.6-5.4,7.7c0,3.6,1.9,6.1,5.8,7.5c3.9,1.4,10,2.8,18.5,4.1c9.5,1.6,17.2,3.3,23.2,5.1
            			c6,1.8,11.3,5.1,15.7,9.8c4.4,4.7,6.7,11.3,6.7,19.8c0,7.2-2.1,13.5-6.5,19.1c-4.3,5.6-10.6,9.9-18.8,13c-8.2,3.1-18,4.6-29.3,4.6
            			C1218.1,220.3,1208.7,219.1,1199.6,216.8z"/>
            	</g>
              </g>
            </svg>
            </div>
          )
          : (
            <svg version="1.1" x="0px" y="0px"
               viewBox="0 0 153 159">
               <g id="Layer_2">
                <g>
                  <path className="st9" d="M27.13,154.25c-11.37-3.3-20.5-7.59-27.39-12.87l12.1-26.84c6.6,4.84,14.44,8.73,23.54,11.66
                    c9.09,2.94,18.18,4.4,27.28,4.4c10.12,0,17.6-1.5,22.44-4.51c4.84-3,7.26-7,7.26-11.99c0-3.67-1.43-6.71-4.29-9.13
                    c-2.86-2.42-6.53-4.36-11-5.83c-4.48-1.46-10.53-3.08-18.15-4.84c-11.74-2.79-21.34-5.57-28.82-8.36
                    c-7.48-2.78-13.9-7.26-19.25-13.42C5.5,66.36,2.82,58.15,2.82,47.88c0-8.94,2.42-17.05,7.26-24.31S22.22,10.55,31.97,6.3
                    c9.75-4.25,21.67-6.38,35.75-6.38c9.82,0,19.43,1.18,28.82,3.52c9.38,2.35,17.6,5.72,24.64,10.12l-11,27.06
                    c-14.23-8.06-28.46-12.1-42.68-12.1c-9.98,0-17.35,1.62-22.11,4.84c-4.77,3.23-7.15,7.48-7.15,12.76s2.75,9.21,8.25,11.77
                    c5.5,2.57,13.89,5.1,25.19,7.59c11.73,2.79,21.34,5.58,28.82,8.36c7.48,2.79,13.89,7.19,19.25,13.2
                    c5.35,6.02,8.03,14.16,8.03,24.42c0,8.8-2.46,16.83-7.37,24.09c-4.92,7.26-12.29,13.02-22.11,17.27
                    c-9.83,4.25-21.78,6.38-35.86,6.38C50.27,159.2,38.5,157.55,27.13,154.25z"/>
                </g>
                <circle className="st10" cx="129.5" cy="135.5" r="23.5"/>
               </g>
            </svg>
          )
        }
        </div>
        <h2>Zaloguj</h2>
          <div>
            <input type="text"
              placeholder="Username"
              onChange={handleUsernameChange}
              value={username}
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Password"
              onChange={handlePasswordChange}
              value={password}
              autoComplete="on"
            />
          </div>
          <button onClick={onSubmitClick} type="submit">
            Zaloguj
          </button>
        </form>
        :
        <div>
          <h2>Użytkownik</h2>
          <button onClick={onLogoutClick}>Wyloguj</button>
        </div>
      }
      </div>
    </div>
  )
}

// function Secret() {
//   const [message, setMessage] = useState('')
//
//   useEffect(() => {
//     authFetch("http://141.94.222.182:5000/api/protected").then(response => {
//       if (response.status === 401){
//         setMessage("Sorry you aren't authorized!")
//         return null
//       }
//       return response.json()
//     }).then(response => {
//       if (response && response.message){
//         setMessage(response.message)
//       }
//     })
//   }, [])
//   return (
//     <h2>Secret: {message}</h2>
//   )
// }
// function Nastes() {
//   return (
//     <h2>Baba</h2>
//   )
// }
// function devices() {
//   return (
//     <h2>Secret:</h2>
//   )
// }
