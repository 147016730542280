import React from "react";
// import {
//   Link
// } from "react-router-dom";
import { IconButton, Input, SelectPicker } from 'rsuite';
// import PlusIcon from '@rsuite/icons/Plus';
// import axios from 'axios';
// import { addDays, subDays } from "date-fns";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from '../../WithRouter';
import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment';

// import { AiOutlineThunderbolt } from "react-icons/ai";
// import { GiCartwheel } from "react-icons/gi";
// import { GiSattelite } from "react-icons/gi";
// import { RiBattery2ChargeLine } from "react-icons/ri";
import ReloadIcon from '@rsuite/icons/Reload';
import { sentgeoSentStatusRecords, sentgeoSentStatusSummary } from '../../actions/SentgeoSentRecordsStatus';



class DisplaySentgeoSentRecords extends React.Component {
  constructor(props) {
  super(props);
      this.state = {
        sentRecordsList: [],
        sentRecordsSumm: [],
        // integrationsSentgeo: [],
      };
      this.refreshSummary = this.refreshSummary.bind(this);
  }

  componentDidMount() {
    const params = {
    }
    this.props.sentgeoSentStatusRecords(params, this.props.history);
    this.props.sentgeoSentStatusSummary(params, this.props.history);

    this.timeout = setInterval(() => {
        const params = {
        }
        this.props.sentgeoSentStatusRecords(params, this.props.history);
        this.props.sentgeoSentStatusSummary(params, this.props.history);
    }, 10000)
  }

  componentDidUpdate(prevProps) {
    if(prevProps.sentgeosentstatuslist.list.data !== this.props.sentgeosentstatuslist.list.data) {
      this.setState({sentRecordsList: this.props.sentgeosentstatuslist.list.data});
    }
    if(prevProps.sentgeosentstatussummary.list.data !== this.props.sentgeosentstatussummary.list.data) {
      this.setState({sentRecordsSumm: this.props.sentgeosentstatussummary.list.data});
    }
  }

  refreshSummary(d) {
    const params = {
    }
    this.props.sentgeoSentStatusSummary(params, this.props.history);
  }

  render() {
    let sm = this.state.sentRecordsSumm;
    let last_rec_tmstp = 0;
    let last_snt_tmstp = 0;
    let rec_to_process_left = 0;
    let rec_to_process_redis = 0;
    if(sm.last_rec !== undefined) {
        last_rec_tmstp = sm.last_rec.timestamp;
        last_snt_tmstp = sm.last_snt.timestamp;
        rec_to_process_left = sm.records_to_process_left;
        rec_to_process_redis = sm.redis_rec_count;
    }
    console.log(this.state.sentRecordsList)
    return (
      <div className="content-wrapper notifications sentgeo-sent">
        <div className="content">
          <div className="content-left">
              <h2>Wysyłka Sent Geo</h2>
            {1 < sm.length
            ? (
                ''
            )
            : (
                <div className="summary-container"> 
                    <div className="group">
                        <div className="label">
                            Ostatni zapisany rekord:
                        </div>
                        <div className="value">
                            <Moment format="DD.MM.YYYY HH:mm:ss">
                                {new Date(last_rec_tmstp)}
                            </Moment>
                        </div>
                    </div>
                    <div className="group">
                        <div className="label">
                            Ostatni wysłany rekord:
                        </div>
                        <div className="value">
                            <Moment format="DD.MM.YYYY HH:mm:ss">
                                {new Date(last_snt_tmstp)}
                            </Moment>
                        </div>
                    </div>
                    <div className="group">
                        <div className="label">
                            Niewysłane rekordy w bazie:
                        </div>
                        <div className="value">
                            {rec_to_process_left}
                        </div>
                    </div>
                    <div className="group last">
                        <div className="label">
                            Rekordy oczekujące w Redis:
                        </div>
                        <div className="value">
                            {rec_to_process_redis}
                        </div>
                    </div>
                    <div className="group refresh">
                      <div className="but-cont">
                        <IconButton
                          icon={<ReloadIcon/>}
                          appearance="ghost"
                          circle size="sm"
                          onClick={ this.refreshSummary }/>
                      </div>
                    </div>
                </div>
                
            )}

          <ul>
            {this.state.sentRecordsList.map((el, i, elements) => (
              <li key={i} className='notification'>
                <div className="id">{el[0]}</div>
                <div className="sent_timestamp">
                    <Moment format="DD.MM.YYYY HH:mm:ss">
                        {new Date(el[0])}
                    </Moment>
                </div>


                <div className="duration">
                    {i+1 === elements.length
                    ? (
                        ''
                    )
                    : (
                        <div> 
                            <Moment duration={new Date(elements[i+1][0])} date= {new Date(el[0])}  trim />    
                        </div>
                        
                    )}
                </div>
                <div className="record_count">{el[1]}</div>
              </li>
            ))}
          </ul>

          </div>
        </div>
      </div>
    );
  }
}

DisplaySentgeoSentRecords.propTypes = {
    sentgeoSentStatusRecords: PropTypes.func.isRequired,
    sentgeoSentStatusSummary: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    sentgeosentstatuslist: state.sentgeosentstatuslist,
    sentgeosentstatussummary: state.sentgeosentstatussummary,
    vehicles: state.vehicles,
    errors: state.errors
});

export default connect(mapStateToProps,{ sentgeoSentStatusRecords, sentgeoSentStatusSummary })(withRouter(DisplaySentgeoSentRecords))
