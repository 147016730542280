import { GET_NOTIFICATIONS_LIST } from '../actions/types';
import isEmpty from '../validation/is-empty';

const initialState = {
  list: []
}

export default function(state = initialState, action ) {
    switch(action.type) {
        case GET_NOTIFICATIONS_LIST:
            return {
                ...state,
                list: action.payload
            }
        default:
            return state;
    }
}
