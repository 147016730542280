import * as React from 'react';
import axios from 'axios';
import { GET_ERRORS, GET_SHARED_VEHILCES_LIST } from './types';
const api_url = process.env.REACT_APP_API_URL;


// export const removeSharedVehicle = (organization, history) => dispatch => {
//     console.log('ssssssssssssssss',organization);
//     axios.post(api_url+'sharedvehicles/removedevice', organization)
//             .catch(err => {
//                 dispatch({
//                     type: GET_ERRORS,
//                     payload: err.response.data
//                 });
//             });
// }

export const listSharedVehcles = (sharedvehicleslist) => dispatch => {
  // console.log(sharedvehicleslist);
      dispatch({
          type: GET_SHARED_VEHILCES_LIST,
          payload: sharedvehicleslist
      });
  // axios({
  //     url: api_url+'organizations/list',
  // })
  // .then(response => {
  //     dispatch({
  //         type: GET_SHARED_VEHILCES_LIST,
  //         payload: response.data
  //     });
  // });
}
//
//
//
// export const setCurrentOrganization = organization => {
//     return {
//         type: GET_SHARED_VEHILCES_LIST,
//         payload: organization
//     }
// }
