import * as React from 'react';
import axios from 'axios';
import { GET_ERRORS, GET_ETOLL_SENT_STATUS_LIST, GET_ETOLL_SENT_STATUS_SUMMARY } from './types';
const api_name = process.env.REACT_APP_API_URL;
const api_ssl = process.env.REACT_APP_API_SSL;
const domainName =  window.location.hostname;
var api_url = domainName+api_name;

switch (domainName) {
  case '141.94.222.182':
    api_url = domainName+':5000'+api_name;
    break;
  default:
}

export const etollSentStatusRecords = (params) => dispatch => {
    axios.get(
      api_ssl+'://'+api_url+'/integrations/etoll/sentlist',
      { params: params }
    )
    .then(response => {
        dispatch({
            type: GET_ETOLL_SENT_STATUS_LIST,
            payload: response.data
        });
    });
}
export const etollSentStatusSummary = (params) => dispatch => {
    axios.get(
      api_ssl+'://'+api_url+'/integrations/etoll/sentstatus',
      { params: params }
    )
    .then(response => {
        dispatch({
            type: GET_ETOLL_SENT_STATUS_SUMMARY,
            payload: response.data
        });
    });
}
