import React from "react";
// import ReactDom from "react-dom";
// import store from '../../store';
import { connect } from 'react-redux';
import withRouter from '../../WithRouter';
import {newsleterAccept} from '../../serviceWorkerRegistration';


import axios from 'axios';
import {
  Pane,
  Polyline,
  MapContainer,
  TileLayer
} from 'react-leaflet';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MarkerOrder from "../../libs/MarkerOrder";
import {AddressAutofill} from "../../libs/AddressAutoFill";
import { IconButton, Button , Input, SelectPicker } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';

import { RiDeleteBinLine } from "react-icons/ri";



const api_name = process.env.REACT_APP_API_URL;
const api_ssl = process.env.REACT_APP_API_SSL;
const api_ws = process.env.REACT_APP_API_WS;
const domainName =  window.location.hostname;
var api_url = domainName+api_name;
var here_domain = domainName;
switch (domainName) {
  case '141.94.222.182':
    api_url = domainName+':5000'+api_name;
    here_domain = domainName+':3000';
    break;
  default:
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "#fff",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "#fff",
});


class DisplayOrdersList extends React.Component {

  constructor(props) {
  super(props);
      this.state = {
        bounds: [
          [50.505, -29.09],
          [52.505, 29.09]
        ],
        hereApiKey: 'u8lq0WyTrCJgz9q26G1sim6xiXBQOwoh6rUFU79I534',
        orderPoints:[
          {
            id: 'item-0',
            text: 'Dodaj adres punktu początkowego',
            address: ''
          },
          {
            id: 'item-1',
            text: 'Dodaj adres punktu końcowego',
            address: ''
          }
        ],
        points: [],
        routes: [],
        tools: [],
        toolsSummary: [],
        vehiclesList: this.prop
      };
      this.addPoint = this.addPoint.bind(this);
      this.removePoint = this.removePoint.bind(this);
      this.handleNewPoints = this.handleNewPoints.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleSortEnd = this.handleSortEnd.bind(this);
      this.onDragEnd = this.onDragEnd.bind(this);
      this.handleVehicleChange = this.handleVehicleChange.bind(this);
      this.handleSubmitNotification = this.handleSubmitNotification.bind(this);

  }

  componentDidMount() {
  }

  handleSubmitNotification(e) {
    e.preventDefault();
    newsleterAccept();
    console.log(localStorage.getItem("DEVICE_UUID"))



  }

  addPoint(d) {
    let arr = this.state.orderPoints;
    let arrLen = arr.length;
    let addPointId = arrLen-1;
    let l = arr.length+1;
    let lastPoint = arr.slice(-1)[0];
    let n = {
      id: 'item-'+addPointId,
      text: 'Dodaj adres punktu pośredniego',
      address: ''
    }
    arr.splice(-1);
    arr.push(n);
    lastPoint.id = 'item-'+arrLen;
    arr.push(lastPoint);
    this.setState({
      orderPoints: arr
    });
  }

  removePoint(e) {
    console.log(e);
  }

  handleNewPoints(d) {
    // console.log('childData');
    // console.log(d.id);
    let that = this;
    let id = d.id;
    // console.log('d--------------', d);
    // console.log('id--------------', id);
    let position = {
        lat: d.lat,
        lng: d.lng,
        properties: d.properties,
        address: d.properties.address.label
      };
    let orderPoints = this.state.orderPoints;
    let obj = orderPoints.find((o, i) => {
        if (o.id === id) {
          let a = this.state.orderPoints[i];
          // console.log('a', a);
          a.address = position.address;
          a.position = position;
          // console.log('a', a);
          orderPoints[i] = a;
          that.setState({
            orderPoints: orderPoints
          });
          return true; // stop searching
        }
    });
    // console.log('obj',orderPoints);
  }

  handleVehicleChange(event) {
    console.log(event);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.vehicles.list !== this.props.vehicles.list) {
      console.log('props changed!!!!!!');
      this.setState({vehiclesList: this.props.vehicles.list});
    }
  }

  handleSubmit(e) {
      e.preventDefault();
      let that = this;
      let points = this.state.orderPoints;
      let lastPoint = points.slice(-1);
      console.log('lastPoint',lastPoint);
      let firstLatToHere = points[0].position.lat;
      let firstLonToHere = points[0].position.lng;
      let lastLatToHere = lastPoint[0].position.lat;
      let lastLonToHere = lastPoint[0].position.lng;
      let viaPoints = [];
      let viaPointsTrue = false;
      if (points.length > 2){
        viaPoints = points.slice(1, -1);
        viaPointsTrue = true;
      }
      let params = {
        latFirst: firstLatToHere,
        lngFirst: firstLonToHere,
        latLast: lastLatToHere,
        lngLast: lastLonToHere,
        viaPoints: viaPoints,
        viaPointsTrue: viaPointsTrue
      }
      axios.post(api_ssl+'://'+api_url+'/orders/showroute', params)
        .then(function (response) {
            let summ = response.data.response.routes[0].sections[0].summary;
            let tools = response.data.response.routes[0].sections[0].tolls;

            let pricreTotalCurrency = '';
            let pricreTotalConvertedCurrency = '';
            let pricreTotal = 0;
            let pricreTotalConverted = 0;

            console.log(response.data.response);
            console.log('tools', tools);
            if(typeof tools !== "undefined") {
                for (let i = 0; i < tools.length; i++) {
                    let fares = tools[i].fares;
                    let price_total_fares_converted = 0;
                    let price_total_fares = 0;
                    let faresTotalConvertedCurrency = '';
                    let faresTotalCurrency = '';
                    for (let j = 0; j < fares.length; j++) {
                        price_total_fares_converted+=fares[j].convertedPrice.value;
                        price_total_fares+=fares[j].price.value;
                        faresTotalCurrency = fares[j].price.currency;
                        faresTotalConvertedCurrency = fares[j].convertedPrice.currency;
                    }
                    tools[i].faresTotalConverted = price_total_fares_converted;
                    tools[i].faresTotal = price_total_fares;
                    tools[i].faresTotalConvertedCurrency = faresTotalConvertedCurrency;
                    tools[i].faresTotalCurrency = faresTotalCurrency;
                    pricreTotalConverted+=price_total_fares_converted;
                    pricreTotalConvertedCurrency = faresTotalConvertedCurrency;

                    }
                    summ.pricreTotalConverted = pricreTotalConverted;
                    summ.pricreTotalConvertedCurrency = pricreTotalConvertedCurrency;
            } else {
                tools = 0;
            }
            console.log('tools', tools);

        //   console.log(response.data);

          let polylinesReceived = response.data.polylines;
        //   console.log('polylinesReceived',polylinesReceived);
          let polylines = [];
          for (let j = 0; j < polylinesReceived.length; j++) {
            // console.log(polylinesReceived[j]);
            polylines.push({index: j, points: polylinesReceived[j].polyline, active:1});
          }
        //   console.log('polylines',polylines);

          that.setState({
            routes: polylines,
            tools:tools,
            toolsSummary: summ
          });
        });

  }

  handleSortEnd = ({ oldIndex, newIndex }) => {
  };

  onDragEnd(result) {
    console.log(result);
    if (!result.destination) {
      return;
    }
    const items = reorder(
      this.state.orderPoints,
      result.source.index,
      result.destination.index
    );
    this.setState({
      orderPoints: items
    });
  }



  render() {
    console.log('sssssssssssssss');
    // console.log(this.props.vehicles.list);
    console.log(this.state.orderPoints);
    const bounds= this.state.bounds;
    // console.log('orderPoints',this.state.orderPoints);
    console.log('toolsSummary',this.state.toolsSummary);
    let total = 0;
    let currency = '';
    let distance = 0;
    let duration = 0;
    let typicalDuration = 0;
    if(typeof this.state.toolsSummary !== "undefined") {
        if(typeof this.state.toolsSummary.pricreTotalConverted !== "undefined") {
            total = Number(this.state.toolsSummary.pricreTotalConverted).toFixed(2);
        }
        currency = this.state.toolsSummary.pricreTotalConvertedCurrency;
        distance = Number(this.state.toolsSummary.length/1000).toFixed(2);
        if(typeof this.state.toolsSummary.duration !== "undefined") {
          duration = (new Date(this.state.toolsSummary.duration * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];
        }
        if(typeof this.state.toolsSummary.typicalDuration !== "undefined") {
          typicalDuration = (new Date(this.state.toolsSummary.typicalDuration * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];
        }
    }


    const vehicles = this.props.vehicles.list;
    let vehiclesSelect = [];
    // let vehiclesCurrent = '';
    for (let i = 0; i < vehicles.length; i++) {
      vehiclesSelect.push({ label: vehicles[i].label, value: vehicles[i].codename });
      // if(i==0) {
      //   vehiclesCurrent = vehicles[0].codename
      // }
    }



    return (
      <div className="content-wrapper orders">
        <div className="content-left">
          <Button appearance="primary" type="submit" onClick={this.handleSubmitNotification} className="btn btn-primary iron-button">
            Notify
          </Button>


          <form onSubmit={ this.handleSubmit }>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                  {this.state.orderPoints.map((point, i) => (
                    <Draggable key={point.id} draggableId={point.id} index={i}>
                      {(provided, snapshot) => (
                      <div
                        className="autofill-container"
                        key={i}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}>
                        <div className="handle" {...provided.dragHandleProps}>
                          <span></span><span></span><span></span><span></span><span></span><span></span>
                        </div>
                        <div className="wrapper">
                          <AddressAutofill pointData={point} newPoints={this.handleNewPoints}/>
                        </div>
                        <div className="delete" onClick={ this.removePoint }>
                          <RiDeleteBinLine/>
                        </div>
                      </div>
                      )}
                      </Draggable>
                  ))}
                  {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <IconButton
              icon={<PlusIcon/>}
              appearance="ghost"
              circle size="sm"
              onClick={ this.addPoint }/>

            <Input
              size="md"
              placeholder="Nazwa zlecenia"/>

            <Input
              size="md"
              placeholder="Numer zlecenia"/>

            <SelectPicker
              data={vehiclesSelect}
              placeholder="Pojazd"
              onChange={this.handleVehicleChange}
              locale={{
                placeholder: 'Wybierz',
                searchPlaceholder: 'Szukaj',
                noResultsText: 'Brak wyników'
              }}
              />

            <div className="form-group">
                <IconButton
                  appearance="primary"
                  type="submit"
                  className="new-button"
                  icon={<PlusIcon />}>
                    Kalkuluj trasę
                </IconButton>
            </div>
          </form>
          <div className="tools">
            <h3>Opłaty drogowe</h3>
            <div className="summary">
              <div className="element">
                <div className="label">
                  Suma opłat:
                </div>
                <div className="value">
                  {total} {currency}
                </div>
              </div>
              <div className="element">
                <div className="label">
                  Dystans:
                </div>
                <div className="value">
                  {distance} km
                </div>
              </div>
              <div className="element">
                <div className="label">
                  Czas trwania:
                </div>
                <div className="value">
                  {duration}
                </div>
              </div>
              <div className="element">
                <div className="label">
                  Typowy czas trwania:
                </div>
                <div className="value">
                  {typicalDuration}
                </div>
              </div>
            </div>
            <div>
            {
            this.state.tools?
                this.state.tools.map((tool, i) => (
                  <div key={i}>
                    <div>
                    {tool.countryCode}
                    </div>
                    <div>
                    {tool.tollSystem}
                    </div>
                    <div>
                    {tool.faresTotalConverted} {tool.faresTotalConvertedCurrency}
                    </div>
                    <div>
                    {tool.faresTotal} {tool.faresTotalCurrency}
                    </div>
                  </div>
                ))
                  :
                  ''
            }

            </div>
          </div>
        </div>
        <div className="content-right">
        <MapContainer scrollWheelZoom={true} bounds={bounds}>

        <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />



          {
            this.state.orderPoints.map((point, i) => (
                point.position && (
                  <MarkerOrder
                    key={i}
                    id={i}
                    latitude={point.position.lat}
                    longitude={point.position.lng}
                    address={point.position.address}
                  >
                  </MarkerOrder>
                )
            ))
          }


          {this.state.routes.map((route, i) => (
                <div key={i} className='daaaaaaaaaaaaaaaaaaaaaaaa'>
                <Pane name={'track'+route.index+'_0'} key={i} style={{ zIndex: 488 }}>
                  <Polyline
                    className={'active'+route.active}
                    zIndexOffset="10"
                    pathOptions={{color: '#8097ff', weight: 4}}
                    key={i}
                    positions={route.points}
                    />
                </Pane>
                </div>

          ))}



        </MapContainer>

        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
    vehicles: state.vehicles,
    errors: state.errors
});

export default connect(mapStateToProps)(withRouter(DisplayOrdersList))
