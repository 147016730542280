import React from "react";
import axios from 'axios';
import { addDays, subDays } from "date-fns";
import Moment from 'react-moment';
import 'moment-timezone';
import { Tab, Tabs as TabsComponent, TabList, TabPanel } from "react-tabs";
import {CalendarModal} from "./components/calendar_n";
import { Tooltip, Whisper, IconButton, Loader, DatePicker } from 'rsuite';

import PlusIcon from '@rsuite/icons/Plus';
import { RiEditLine } from "react-icons/ri";
import { RiLinkM } from "react-icons/ri";
import { RiRadarLine } from "react-icons/ri";
import { RiBaseStationLine } from "react-icons/ri";
import { RiRouteLine } from "react-icons/ri";
import { RiCloseLine } from "react-icons/ri";
import { RiDeleteBin2Line } from "react-icons/ri";
import { RiAddCircleLine } from "react-icons/ri";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { AiOutlineDashboard } from "react-icons/ai";
import { GiCarWheel } from "react-icons/gi";
import isEmpty from './validation/is-empty';
import { RiSearch2Line } from "react-icons/ri";

const api_name = process.env.REACT_APP_API_URL;
const api_ssl = process.env.REACT_APP_API_SSL;
const domainName =  window.location.hostname;
var api_url = domainName+api_name;
var url_link_to_map = domainName;

switch (domainName) {
  case '141.94.222.182':
    api_url = domainName+':5000'+api_name;
    url_link_to_map = domainName+':3000';
    break;
  default:
}

export class MapDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calMaxDays: 7,
      currentDevice: '',
      currentVehiclesAccess: [],
      currentTrack: null,
      modal: false,
      modalEdit: false,
      vehiclesList: [],
      trackTotalDistance: '',
      tracksList: [],
      goBack: '',
      organization_id: '',
      rgns: [{
        startDate: subDays(new Date(), 1),
        endDate: addDays(new Date(), 1),
        key: 'selection'
      }],
      loader: false,
      tracksLoader: false,
      calendarVisibility: false,
      name: '',
      nameValid: true,
      dateEndTimestamp: '',
      dateEndTimestampValid: true,
      dateEnd: addDays(new Date(), 1),
      editId: '',
      editDateEndTimestamp: ''
    };
    this.handleCloseDetails = this.handleCloseDetails.bind(this);
    this.showCalendar = this.showCalendar.bind(this);
    this.genetateTracks = this.genetateTracks.bind(this);
    this.handleTrackClick = this.handleTrackClick.bind(this);
    this.calendarChange = this.calendarChange.bind(this);
    this.removeShared = this.removeShared.bind(this);
    this.removeSharedVehicle = this.removeSharedVehicle.bind(this);
    this.checkIfVehicleAlreadyAdd = this.checkIfVehicleAlreadyAdd.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModalEdit = this.showModalEdit.bind(this);
    this.hideModalEdit = this.hideModalEdit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleEditSelect = this.handleEditSelect.bind(this);
    this.copyLink = this.copyLink.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAddSubmit = this.handleAddSubmit.bind(this);
    this.handleEditSubmit = this.handleEditSubmit.bind(this);
    this.handleRefreshSharedList = this.handleRefreshSharedList.bind(this);
  }

  componentWillReceiveProps (newProps) {
    if( newProps.currentVehiclesAccess !== this.props.currentVehiclesAccess ) {
      this.setState({currentVehiclesAccess: newProps.currentVehiclesAccess});
      this.setState({loader: false});
    }
    if( newProps.currentDevice !== this.props.currentDevice ) {
      this.setState({
        tracksList: [],
        trackTotalDistance: '',
        currentTrack: null
      });

    }
    if( newProps.tracksList !== this.props.tracksList ) {
      this.setState({
        tracksList: newProps.tracksList[0].tracks_list,
        trackTotalDistance: newProps.tracksList[0].total_tracks_distance,
        tracksLoader: false
      });
    }
    if( newProps.vehiclesList !== this.props.vehiclesList ) {
      this.setState({
        vehiclesList: newProps.vehiclesList,
      });
    }
    if( newProps.organizationid !== this.props.organizationid ) {
      this.setState({
        organization_id: newProps.organizationid,
      });
    }
  }

  handleRefreshSharedList() {
    this.props.sendToWs(['vehicle_access_select', 'list', 'reload']);
  }

  handleCloseDetails = () => {
    this.props.parentCallback('back');
  };

  showCalendar(event) {
    let a = event.currentTarget.getAttribute('calvis');
    if (a==='closed') {
      this.setState({calendarVisibility: true});
    } else {
      this.setState({calendarVisibility: false});
    }
  };

  calendarChange = (childData) => {
    this.setState({
      rgns: [{
        startDate: new Date(childData[0].rgns[0].startDate),
        endDate: new Date(childData[0].rgns[0].endDate),
        key: 'selection'
      }]
    });
  };

  genetateTracks(event) {
    this.props.curTrack([{
      startDate: new Date(this.state.rgns[0].startDate),
      endDate: new Date(this.state.rgns[0].endDate),
      device: this.props.currentDevice
    }]);
    this.setState({
      calendarVisibility: false,
      tracksLoader: true
    });
  };

  handleTrackClick = (track, event) => {
    this.setState({currentTrack: track.index});
    this.props.trackShow(track);
  };

  removeShared(id) {
    let that = this;
    axios.post(api_ssl+'://'+api_url+'/sharedvehicles/delete', {
      id: id
    })
    .then(function (response) {
      that.setState({loader: true});
      that.handleRefreshSharedList();
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  copyLink(d) {
    let url = api_ssl+'://'+url_link_to_map+'/vehicleaccess/'+d;
    // console.log('url',url);
    this.props.sendToToast(['vehicle_access_link_copy', 'success', 'Link został skopiowany do schowka']);
    navigator.clipboard.writeText(url);
  };

  removeSharedVehicle(l, i, r, id, imei, type) {
    let that = this;
    let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    if (type === 'remove') {
      l = l.filter(device => device.id !== i);
    } else if (type === 'add') {
      l.push({"id": i, "imei": imei, "reg": r});
    }
    axios.post(api_ssl+'://'+api_url+'/sharedvehicles/removedevice', {
      devices: l,
      id: i,
      reg: r,
      elid: id,
      org: auth_get.organization_id
    })
    .then(function (response) {
      that.setState({loader: true});
      that.handleRefreshSharedList();
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  checkIfVehicleAlreadyAdd(d) {
    const result = d.some(e => e.id === this.props.currentDevice.id);
    return result;
  }
  showModal(event) {
    this.setState({modal: true});
  };
  hideModal(event) {
    this.setState({
      modal: false,
      name: '',
      dateEndTimestamp: '',
    });
  };
  showModalEdit(i, n, t) {
    var d = new Date(parseInt(t));
    var dt = d.getTime();
    this.setState({
      modalEdit: true,
      editId: i,
      name: n,
      dateEnd: d,
      editDateEndTimestamp: dt
    });
  };
  hideModalEdit(event) {
    this.setState({
      modalEdit: false,
      editId: '',
      name: '',
      dateEnd: new Date()
    });
  };

  handleSelect(e){
    let t = e.getTime();
    this.setState({
      dateEndTimestamp: t
    });
    }
  handleEditSelect(e){
    let t = e.getTime();
    this.setState({
      dateEnd: e,
      editDateEndTimestamp: t
    });
    }
  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      [name]: value
    });
  }

  handleAddSubmit(event) {
    event.preventDefault();
    let that = this;
    let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    let i = this.props.currentDevice.id;
    let imei = this.props.currentDevice.device_id;
    let r = this.props.currentDevice.licenseplate;
    let d = [{"id": i, "imei": imei, "reg": r}];
    let t = Date.now();
    let n = this.state.name;
    let e = this.state.dateEndTimestamp;
    let formValid = true;
    if(!isEmpty(n)){
      this.setState({
        nameValid: true
      });
    } else {
      formValid = false;
      this.setState({
        nameValid: false
      });
    }
    if(!isEmpty(e)){
      this.setState({
        dateEndTimestampValid: true
      });
    } else {
      formValid = false;
      this.setState({
        dateEndTimestampValid: false
      });
    }
    if(formValid === true) {
      axios.post(api_ssl+'://'+api_url+'/sharedvehicles/add', {
        devices: d,
        name: this.state.name,
        validUntill: this.state.dateEndTimestamp,
        createdAt: t,
        org: auth_get.organization_id
      })
      .then(function (response) {
        that.setState({loader: true});
        that.handleRefreshSharedList();
      })
      .catch(function (error) {
        console.log(error);
      });
      this.setState({
        modal: false,
        name: '',
        dateEndTimestamp: '',
      });
    }
  }
  handleEditSubmit(event) {
    event.preventDefault();
    let that = this;
    let v = this.state.editDateEndTimestamp;
    axios.post(api_ssl+'://'+api_url+'/sharedvehicles/edit', {
      id: this.state.editId,
      name: this.state.name,
      validUntill: v
    })
    .then(function (response) {
      that.setState({loader: true});
      that.handleRefreshSharedList();
    })
    .catch(function (error) {
      console.log(error);
    });
    this.setState({
      modalEdit: false,
      name: '',
      dateEnd: new Date()
    });
  }

  render(){
    const currentDevice = this.props.currentDevice;
    var ignition,
        movement,
        gpsSpeed,
        operatorCode,
        satellitesForFix,
        voltage,
        odometer,
        fuel_lvl,
        total_fuel_used,
        last_connection_time,
        battery_voltage,
        battery_current,
        battery_level,
        ble_status,
        ble_temp_1,
        ble_humd_1,
        ble_batt_1,
        ble_temp_2,
        ble_humd_2,
        ble_batt_2,
        axis_x,
        axis_y,
        axis_z,
        network_type,
        record_timestamp;
    if (currentDevice.lastrecord) {
      ble_status = currentDevice.lastrecord.ble_status;
      ble_temp_1 = currentDevice.lastrecord.ble_temp_1;
      ble_humd_1 = currentDevice.lastrecord.ble_humd_1;
      ble_batt_1 = currentDevice.lastrecord.ble_batt_1;
      ble_temp_2 = currentDevice.lastrecord.ble_temp_2;
      ble_humd_2 = currentDevice.lastrecord.ble_humd_2;
      ble_batt_2 = currentDevice.lastrecord.ble_batt_2;
      battery_current = currentDevice.lastrecord.battery_current;
      battery_voltage = currentDevice.lastrecord.battery_voltage;
      battery_level = currentDevice.lastrecord.battery_level;
      fuel_lvl = currentDevice.lastrecord.fuel_lvl;
      gpsSpeed = currentDevice.lastrecord.gpsSpeed;
      ignition = currentDevice.lastrecord.ignition;
      last_connection_time = currentDevice.lastrecord.last_connection_time;
      movement = currentDevice.lastrecord.movement;
      odometer = currentDevice.lastrecord.odometer;
      operatorCode = currentDevice.lastrecord.operatorCode;
      satellitesForFix = currentDevice.lastrecord.satellitesForFix;
      total_fuel_used = currentDevice.lastrecord.total_fuel_used;
      record_timestamp = currentDevice.lastrecord.record_timestamp;
      voltage = currentDevice.lastrecord.voltage;
      axis_x = currentDevice.lastrecord.axis_x;
      axis_y = currentDevice.lastrecord.axis_y; 
      axis_z = currentDevice.lastrecord.axis_z;
      network_type = currentDevice.lastrecord.network_type;
    }
    const wwwwws = [
      {
        heading: "Status",
      },
      {
        heading: "Trasy",
      },
      {
        heading: "Info",
      },
      {
        heading: "Udostępnij",
      }
    ];
    const tabsNumber =  'tabs-'+wwwwws.length;
    let { nameValid, dateEndTimestampValid } = this.state;
    // console.log('currentDevice', currentDevice);

    const nt_label = [
      '3G',
      '2G',
      '4G',
      'CAT M1',
      'CAT NB1'
    ];
    let ntVal = nt_label[1];
    let deviceType = currentDevice.device_type;
    if(deviceType==='fmc650' || deviceType==='fmc130'){
      ntVal = nt_label[network_type];
    }
    // console.log('total_fuel_used', total_fuel_used)
    return (
      <div>
        <div className="cst-device-info">
        <div className="panel-header">
          <div className="name">
            <div className="licenseplate">{currentDevice.licenseplate}</div>
            <div className="codename">{currentDevice.name} / {currentDevice.codename}</div>
          </div>
          <div className="close-container">
            <button onClick={this.handleCloseDetails} id="details-btn-close" className="on"><span></span><span></span><span></span>
            </button>
          </div>
        </div>
          <div className={tabsNumber}>
          <TabsComponent>
            <TabList>
              {wwwwws.map(({ heading }, i) => (
                <Tab key={i}>{heading}</Tab>
              ))}
            </TabList>
            <TabPanel>
              <div className="info-container">
                <ul className="text-color elements-list">
                  <li><span className="label"><AiOutlineThunderbolt /> Zapłon: </span>{ignition == true? 'Wł': 'Wył'}</li>
                  <li><span className="label"><GiCarWheel /> Ruch: </span>{movement == true? 'Wł': 'Wył'}</li>
                  <li><span className="label"><AiOutlineDashboard /> Prędkość: </span>{gpsSpeed} km/h</li>
                  <li><span className="label"><RiRadarLine /> Satelitów: </span>{satellitesForFix}</li>
                  <li><span className="label"><RiBaseStationLine /> Typ sieci: </span>{ntVal}</li>
                </ul>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="cst-device-tracks">
                <div className="header">
                  <div className="cst-range-picker-container">
                    <div className="track-selector">
                      <div className="visible-date" onClick={this.showCalendar} calvis={this.state.calendarVisibility === true? 'opened': 'closed'}>
                        <CalendarModal  calMaxDays={this.state.calMaxDays} calRanges={this.calendarChange} calendarVisibility={this.state.calendarVisibility}/>
                      </div>
                      <IconButton
                        className="track-generate-button"
                        icon={<RiSearch2Line/>}
                        size="sm"
                        onClick={ this.genetateTracks }/>
                    </div>
                  </div>
                </div>
                <div className="tracks-list-container">
                <div className={`tracks-loader-container ${this.state.tracksLoader === true? 'loading': ''}`}>
                  <Loader size="md" />
                </div>

                {this.state.trackTotalDistance!=='' ? (
                    <div className="total_tracks_distance value">
                      Całkowity dystans:
                      <span> {this.state.trackTotalDistance} km</span>
                    </div>
                  ) : (
                    <div className="total_tracks_distance">
                    </div>
                )}

                {
                  this.state.tracksList.map((track, i) => (
                    this.state.currentTrack===track.index
                      ? (
                        <div className="track-container active" key={i}>
                        <div className="left">
                          <div>
                          Dystans: {track.distance} km
                          </div>
                          <div>
                          Czas Trwania: {track.duration}
                          </div>
                          <div>
                          <span>Start: </span>
                            <Moment format="DD.MM.YYYY HH:mm:ss">
                              {new Date(track.datefirst)}
                            </Moment>
                          </div>
                          <div>
                          <span>Koniec: </span>
                            <Moment format="DD.MM.YYYY HH:mm:ss">
                              {new Date(track.datelast)}
                            </Moment>
                          </div>
                        </div>
                        <div className="right">
                          <div className="button-track-show" onClick={() => this.handleTrackClick(track, i)}>
                            <RiRouteLine />
                          </div>
                        </div>
                      </div>
                      )
                      : (
                        <div className="track-container" key={i}>
                        <div className="left">
                          <div>
                          Dystans: {track.distance} km
                          </div>
                          <div>
                          Czas Trwania: {track.duration}
                          </div>
                          <div>
                          <span>Start: </span>
                            <Moment format="DD.MM.YYYY HH:mm:ss">
                              {new Date(track.datefirst)}
                            </Moment>
                          </div>
                          <div>
                          <span>Koniec: </span>
                            <Moment format="DD.MM.YYYY HH:mm:ss">
                               {new Date(track.datelast)}
                            </Moment>
                          </div>
                        </div>
                        <div className="right">
                          <div className="button-track-show" onClick={() => this.handleTrackClick(track, i)}>
                            <RiRouteLine />
                          </div>
                        </div>
                      </div>
                      )
                  ))
                }
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="info-container">
                <ul className="text-color margin-bottom elements-list">
                  <li><span className="label">Operator GSM: </span>{operatorCode}</li>
                  <li><span className="label">Napięcie: </span>{(voltage/1000).toFixed(2)} V</li>
                  <li><span className="label">Napięcie baterii: </span>{(battery_voltage/1000).toFixed(2)} V</li>
                  <li><span className="label">Ładowanie baterii: </span>{(battery_current/1000).toFixed(2)} mA</li>
                  <li><span className="label">Poziom naładowania baterii: </span>{battery_level} %</li>
                </ul>
                <ul className="text-color margin-bottom elements-list">
                  <li><span className="label">Przebieg: </span>{(odometer/1000).toFixed(2)} km</li>
                  <li><span className="label">Poziom paliwa: </span>{fuel_lvl} %</li>
                  <li><span className="label">Całkowite zużycie paliwa: </span>{total_fuel_used} l</li>
                </ul>
                <ul className="text-color margin-bottom elements-list">
                  <li><span className="label">Oś X: </span>{axis_x} mG</li>
                  <li><span className="label">Oś X: </span>{(axis_x/1000)*9.80665} m/s^2</li>
                  <li><span className="label">Oś Y: </span>{axis_y} mG</li>
                  <li><span className="label">Oś Y: </span>{(axis_y/1000)*9.80665} m/s^2</li>
                  <li><span className="label">Oś Z: </span>{axis_z} mG</li>
                  <li><span className="label">Oś Z: </span>{(axis_z/1000)*9.80665} m/s^2</li>
                </ul>
                <ul className="text-color margin-bottom elements-list">
                  <li><span className="label">Status bluetooth: </span>{ble_status}</li>
                  <li><span className="label">Sensor 1 temperatura: </span>{(ble_temp_1/100).toFixed(2)} °C</li>
                  <li><span className="label">Sensor 1 wilgotność: </span>{ble_humd_1} %</li>
                  <li><span className="label">Sensor 1 bateria: </span>{ble_batt_1} %</li>
                  <li><span className="label">Sensor 2 temperatura: </span>{(ble_temp_2/100).toFixed(2)} °C</li>
                  <li><span className="label">Sensor 2 wilgotność: </span>{ble_humd_2} %</li>
                  <li><span className="label">Sensor 2 bateria: </span>{ble_batt_2} %</li>
                </ul>
                <ul className="text-color margin-bottom elements-list">
                  <li><span className="label">Ost. poł: </span>
                  <Moment format="DD.MM.YYYY HH:mm:ss">
                    {new Date(last_connection_time)}
                  </Moment>
                  </li>
                  <li><span className="label">Ost. Rek: </span>
                  <Moment format="DD.MM.YYYY HH:mm:ss">
                    {new Date(record_timestamp)}
                  </Moment>
                  </li>
                </ul>
              </div>
            </TabPanel>
            <TabPanel>
            <ul className={`shared-vehicles-list ${this.state.loader === true? 'loading': ''}`}>

              <div className="shared-header">
                <div className="shared-title">
                  Udostępnione pojazdy
                  <div className="loader-container"><Loader /></div>
                </div>
                <IconButton
                  appearance="primary"
                  className="new-button"
                  size="sm"
                  onClick={this.showModal}
                  icon={<PlusIcon />}>Udostępnij</IconButton>
              </div>

            {
              this.state.currentVehiclesAccess.map((access, i) => (
                <li key={access.id} className="shared-vehicles-position">

                <div className="row-first">
                  <div className="name-date">
                    <div className="name">
                      {access.name}
                    </div>
                    <div className="date">
                      Dostęp ważny do:
                      <span className="date-margin">
                      <Moment format="DD.MM.YYYY HH:mm:ss">
                        {new Date(parseInt(access.valid_untill))}
                      </Moment>
                      </span>
                    </div>
                  </div>
                  <Whisper placement="top" trigger="hover" speaker={<Tooltip>Skopiuj link do schowka</Tooltip>}>
                    <div className="copy-link" onClick={() => this.copyLink(access.code)}>
                      <RiLinkM />
                    </div>
                  </Whisper>
                  <Whisper placement="top" trigger="hover" speaker={<Tooltip>Edytuj</Tooltip>}>
                    <div className="edit-vehicle-access" accessid={access.id} onClick={() => this.showModalEdit(access.id, access.name, access.valid_untill)}>
                      <RiEditLine />
                    </div>
                  </Whisper>

                </div>
                <div className="row-second">
                  <ul className="shared-vehicles-list-assigned-vehicles">
                  {
                    JSON.parse(access.devices).map((device, i) => (
                      <li key={device.id} className="assigned-vehicle">
                        <div className="reg">
                          {device.reg}
                        </div>

                        <Whisper placement="top" trigger="hover" speaker={<Tooltip>Usuń pojazd {device.reg}</Tooltip>}>
                        <div className="remove-vehicle" deviceid={device.id} onClick={() => this.removeSharedVehicle(JSON.parse(access.devices), device.id, device.reg, access.id, '', 'remove')}>
                          <RiCloseLine deviceid={device.id} />
                        </div>
                        </Whisper>
                      </li>
                    ))
                  }
                  {this.checkIfVehicleAlreadyAdd(JSON.parse(access.devices))===false
                    ? (
                    <li className="assign-vehicle">
                    <Whisper placement="top" trigger="hover" speaker={<Tooltip>Dodaj pojazd {currentDevice.licenseplate}</Tooltip>}>
                      <div className="add-vehicle" deviceid={access.id}  onClick={() => this.removeSharedVehicle(JSON.parse(access.devices), currentDevice.id, currentDevice.licenseplate, access.id, currentDevice.device_id, 'add')}>
                        <RiAddCircleLine />
                      </div>
                    </Whisper>

                    </li>
                  )
                  : (
                    <div></div>
                    )
                  }
                  </ul>
                  <Whisper placement="top" trigger="hover" speaker={<Tooltip>Usuń dostęp</Tooltip>}>
                    <div className="share-delete" onClick={() => this.removeShared(access.id)}>
                      <RiDeleteBin2Line />
                    </div>
                  </Whisper>
                </div>
                </li>
              ))
            }
            </ul>
            </TabPanel>
          </TabsComponent>
          </div>
        </div>
        <div className={`modal add ${this.state.modal === true? 'opened': ''}`}>
          <div className="wrapper">
            <div className="inner">
              <div className="header">
                <div className="left">
                  <h3>Udostępnij pojazd {currentDevice.licenseplate}</h3>
                </div>
                <div className="right">
                <button onClick={this.hideModal} id="modal-btn-close" className="on"><span></span><span></span><span></span>
                </button>
                </div>
              </div>
              <div className="content">
                <form onSubmit={this.handleAddSubmit} className="form-add-shared-vehiles">
                  <label className="name">
                    Nazwa dostępu:
                    <input type="text" value={this.state.name} name="name" onChange={this.handleInputChange} />
                    {!nameValid && <div className="validation-error">Pole 'Nazwa' jest wymagane</div>}
                  </label>
                  <label className="access-to-calendar">
                    Dostęp ważny do:
                    <DatePicker
                      format="yyyy-MM-dd HH:mm"
                      placeholder="Wybierz datę"
                      onChange={this.handleSelect}
                      />
                      {!dateEndTimestampValid && <div className="validation-error">Wybierz datę do której dostęp będzie ważny</div>}
                  </label>
                  <input type="submit" value="Dodaj" />
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className={`modal edit ${this.state.modalEdit === true? 'opened': ''}`}>
          <div className="wrapper">
            <div className="inner">
              <div className="header">
                <div className="left">
                  <h3>Edycja udostępnienia</h3>
                </div>
                <div className="right">
                <button onClick={this.hideModalEdit} id="modal-btn-close" className="on"><span></span><span></span><span></span>
                </button>
                </div>
              </div>
              <div className="content">
                <form onSubmit={this.handleEditSubmit}>
                  <label>
                    Nazwa:
                    <input type="text" value={this.state.name} name="name" onChange={this.handleInputChange} />
                  </label>
                  <label className="access-to-calendar">
                    Dostęp ważny do:
                    <DatePicker
                      format="yyyy-MM-dd HH:mm"
                      placeholder="Wybierz datę"
                      onChange={this.handleEditSelect}
                      value={this.state.dateEnd}
                      cleanable={false}
                      />
                  </label>
                  <input type="submit" value="Zapisz" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
