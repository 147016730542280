import { SET_CURRENT_ORGANIZATION } from '../actions/types';
// import isEmpty from '../validation/is-empty';

const initialState = {
  list: []
}

export default function(state = initialState, action ) {
    switch(action.type) {
        case SET_CURRENT_ORGANIZATION:
            // console.log(action.type);
            // console.log(action.payload);
            return {
                ...state,
                list: action.payload
            }
        default:
            return state;
    }
}
