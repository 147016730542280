import * as React from 'react';
import axios from 'axios';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { IoIosAdd } from "react-icons/io";
import {User} from "./components/users/DisplayUserDetails";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const api_name = process.env.REACT_APP_API_URL;
const api_ssl = process.env.REACT_APP_API_SSL;
const api_ws = process.env.REACT_APP_API_WS;
const domainName =  window.location.hostname;
var api_url = domainName+api_name;
var here_domain = domainName;
switch (domainName) {
  case '141.94.222.182':
    api_url = domainName+':5000'+api_name;
    here_domain = domainName+':3000';
    break;
  default:
}

// var windowReference = window.open();

function isOdd(n) {
   return Math.abs(n % 2) == 1;
}
function distance(lat1, lon1, lat2, lon2, unit) {
    if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
    }
    else {
        let radlat1 = Math.PI * lat1/180;
        let radlat2 = Math.PI * lat2/180;
        let theta = lon1-lon2;
        let radtheta = Math.PI * theta/180;
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit=="K") { dist = dist * 1.609344 }
        if (unit=="N") { dist = dist * 0.8684 }
        return dist;
    }
}
function anglesas(cx, cy, ex, ey) {
  let dy = ey - cy ;
  let dx = cx - ex ;
  return Math.atan2(dx, dy) * 180 / Math.PI;
}

export class DisplayUsersSocket extends React.Component {
  constructor(props) {
  super(props);
      this.state = {
        closeDetails: false,
        currentUser: [],
        currentUserRoles: this.props.currentUserRoles,
        data: [],
        is_active: 1,
        isUserAuthenticated: false,
        modal: false,
        name: '',
        organization_id: '0',
        roles: 'owner',
        username: '',
        ws: null,
        newPassword: [],
        preloader: 'preloader-show',
        orderProcessingTime: 0
      };
      this.handleRolesChange = this.handleRolesChange.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleUserAddSubmit = this.handleUserAddSubmit.bind(this);
      this.showModal = this.showModal.bind(this);
      this.hideModal = this.hideModal.bind(this);
      this.handleShowDetails = this.handleShowDetails.bind(this);
      this.handleCloseDetails = this.handleCloseDetails.bind(this);
      this.handleEditUser = this.handleEditUser.bind(this);
      this.handleNewPassword = this.handleNewPassword.bind(this);
      this.handleMailSend = this.handleMailSend.bind(this);
      this.orderSend = this.orderSend.bind(this);
  }

  timeout = 250; // Initial timeout duration as a class variable

  componentDidMount() {
    if (this.state.ws === null || this.state.ws.readyState !== 1) {
      this.connect();
    }
    let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    if(auth_get) {
      let oi = '';
      oi = auth_get.organization_id;
      this.setState({organization_id: oi})
    }
    this.timeout = setInterval(() => {
      if (this.state.ws.readyState === 1) {
        let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
        let at = '';
        let oi = '';
        if(auth_get) {
          at = auth_get.access_token;
          oi = auth_get.organization_id;
        }
        let msg_to_server = JSON.stringify({"type":"users_list", "at":at, "organization_id":oi});
        this.state.ws.send(msg_to_server);
      }
    }, 10000)
      this.props.activeTab('users');
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
    this.state.ws.close();
    this.setState({ ws: [] });
  }

  componentWillReceiveProps (newProps) {
    if( newProps.currentUserRoles !== this.props.currentUserRoles ) {
      this.setState({currentUserRoles:  newProps.currentUserRoles})
    }
    if( newProps.orgId !== this.props.orgId ) {
      this.setState({organization_id:  newProps.orgId})
      let accesss_token = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
      let at = '';
      if(accesss_token) {
        at = accesss_token.access_token;
      }
      let msg_to_server = JSON.stringify({"type":"users_list", "at":at, "organization_id":newProps.orgId});
      this.state.ws.send(msg_to_server);
    }
  }

  connect = () => {
    let ws = new W3CWebSocket(api_ws+'://'+api_url+'/users');
    let that = this;
    let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    let at = '';
    let oi = '';
    if(auth_get) {
      at = auth_get.access_token;
      oi = auth_get.organization_id;
    }
    let msg_to_server = JSON.stringify({"type":"users_list", "at":at, "organization_id":oi});

    ws.onopen = () => {
      that.setState({ ws: ws });
      ws.send(msg_to_server);
    };

    ws.onmessage = (message) => {
      let datajson = JSON.parse(message.data);
      let auth = datajson.auth;
        this.setState({ isUserAuthenticated: true, preloader: 'preloader-hide' });
        if(datajson.type === 'users_add') {
          this.setState({modal: false});
        } else if(datajson.type === 'users_edit') {
          toast.success('Zmiany zostały zapisane');
        } else if(datajson.type === 'users_list') {
          const data = datajson.data;
          this.setState({data: data});
        } else if(datajson.type === 'users_update_password') {
        } else if(datajson.type === 'users_send_mail') {
          const data = datajson.data;
        }
    };

    ws.onclose = (e) => {
      ws.close();
      that.setState({ ws: [] });
      setTimeout(function () {
        that.connect();
      }, 3000);
    };

    ws.onerror = (err) => {
      ws.close();
    };
  };
  showModal(event) {
   this.setState({modal: true});
  };
  hideModal(event) {
   this.setState({modal: false});
  };
  handleRolesChange(event) {
   this.setState({roles: event.target.value});
  }

   handleInputChange(event) {
     const target = event.target;
     const name = target.name;
     const value = target.value;
     this.setState({
       [name]: value
     });
   }

   handleUserAddSubmit(event) {
     event.preventDefault();
     const ts = this.state;
     let accesss_token = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
     let msg_add =  JSON.stringify({"type":"users_add", "username":ts.username, "roles":ts.roles, "is_active":ts.is_active, "organization_id":accesss_token.organization_id});
     this.state.ws.send(msg_add);

     let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
     let at = '';
     let oi = '';
     if(auth_get) {
       at = auth_get.access_token;
       oi = auth_get.organization_id;
     }
     let msg_to_server = JSON.stringify({"type":"users_list", "at":at, "organization_id":oi});

     this.state.ws.send(msg_to_server);
   }

  handleShowDetails = (user, event) => {
    let userKeys = Object.keys(user)
    for (var i = 0; i < Object.keys(user).length; i++) {
      let key = userKeys[i];
      let val = user[key];
      if (val === null || val === undefined) {
        user[key] = '';
      }
    }
    this.setState({
      closeDetails: true,
      currentUser: user,
    });
  };

  handleCloseDetails = () => {
    this.setState({
      closeDetails: false
    });
  };

  handleNewPassword(childData) {
    let accesss_token = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    let msg_to_server = JSON.stringify({"type":"users_update_password", "id": childData.u,"password": childData.p, "at":accesss_token.accesss_token, "organization_id":accesss_token.organization_id});
    this.state.ws.send(msg_to_server);
  }

  handleMailSend = (childData) =>{
    let accesss_token = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    let at = '';
    if(accesss_token) {
      at = accesss_token.access_token;
    }
    let msg_to_server = JSON.stringify({"type":"users_send_mail", "at":at, "organization_id":accesss_token.organization_id});
    this.state.ws.send(msg_to_server);
  }

  handleEditUser = (childData) => {
    let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    let at = '';
    if(auth_get) {
      at = auth_get.access_token;
    }
    let msg_edit = JSON.stringify({"type":"users_edit", "at":at, "organization_id":auth_get.organization_id, "data":childData[0]});
    const ts = this.state;
    this.state.ws.send(msg_edit);
    let msg_to_server = JSON.stringify({"type":"users_list", "at":at, "organization_id":auth_get.organization_id});
    this.state.ws.send(msg_to_server);
  };

  orderSend = () => {
    let startTime = performance.now();
    let that = this;
    fetch(api_ssl+'://'+here_domain+'/here.json')
    .then(response => response.json())
    .then((jsonData) => {
      let here_tracks = jsonData.result.balanced.response.route[0].leg;
      let waypoints = jsonData.result.balanced.configuration.waypoints;
      let tracks = [];
      let tracks_sygic = [];
      axios.get(api_ssl+'://'+api_url+'/vehicleposition')
        .then((response) => {
          let flat = response.data.lastrecord.lat;
          let flon = response.data.lastrecord.lng;
          let llat = waypoints[0].latitude;
          let llon = waypoints[0].longitude;
          let firstLat = parseInt((flat.toFixed(5))*100000).toFixed(0);
          let firstLon = parseInt((flon.toFixed(5))*100000).toFixed(0);
          let firstLatToHere = flat.toFixed(5);
          let firstLonToHere = flon.toFixed(5);
          let lastLat = parseInt((llat.toFixed(5))*100000).toFixed(0);
          let lastLon = parseInt((llon.toFixed(5))*100000).toFixed(0);
          let lastLatToHere = llat.toFixed(5);
          let lastLonToHere = llon.toFixed(5);
          const api_key = 'u8lq0WyTrCJgz9q26G1sim6xiXBQOwoh6rUFU79I534'
          let hereUrl = 'https://router.hereapi.com/v8/routes?transportMode=car&origin='+firstLatToHere+','+firstLonToHere+'&destination='+lastLatToHere+','+lastLonToHere+'&return=summary,polyline&apikey='+api_key;
          let polylineEncoded = null;
          axios.get(hereUrl)
            .then((response) => {
              polylineEncoded = response.data.routes[0].sections[0].polyline;
              tracks_sygic.push({
                lat: firstLat,
                lon: firstLon,
                lastlat: lastLat,
                lastlon: lastLon,
                points: null,
                polyline: polylineEncoded
              });
              for (let i = 0; i < here_tracks.length; i++) {
                let here_points = here_tracks[i].link;
                let track_points = [];
                let track_points_sygic = [];
                for (let j = 0; j < here_points.length; j++) {
                  let shape = here_points[j].shape;
                  let number = 0;
                  for (let k = 0; k < shape.length; k++) {
                    number++;
                    if (isOdd(number)==1) {
                      let lat = shape[k];
                      let lng = shape[k+1];
                      let record_before = track_points.slice(-1).pop();
                      let record_before_second = track_points[track_points.length - 2];
                      if(typeof record_before !== "undefined" && typeof record_before_second !== "undefined") {
                        if(lat !== record_before.lat && lng !== record_before.lng) {
                          let dst = distance(lat , lng, record_before.lat, record_before.lng, 'K')*1000;
                          let ang = anglesas(lat , lng, record_before.lat, record_before.lng);
                          let ang2 = anglesas(record_before.lat, record_before.lng, record_before_second.lat, record_before_second.lng);
                          let ang_dif = Math.abs(ang - ang2);
                          if(dst >= 50 && ang_dif >=1.35) {
                            track_points.push({ lat: lat, lng: lng, dst: dst, ang: ang});
                            track_points_sygic.push({ lat: parseInt((lat.toFixed(5))*100000).toFixed(0), lon: parseInt((lng.toFixed(5))*100000).toFixed(0) });
                          }
                        }
                      } else {
                        track_points.push({ lat: lat, lng: lng });
                      }
                    }
                  }
              }
                tracks.push(track_points);
                let thLat = parseInt((waypoints[i+1].latitude.toFixed(5))*100000).toFixed(0);
                let thLon = parseInt((waypoints[i+1].longitude.toFixed(5))*100000).toFixed(0)
                tracks_sygic.push({lat: thLat, lon: thLon, points: track_points_sygic});
              }
              this.setState({
                track_here: tracks
              });

              axios.post(api_ssl+'://'+api_url+'/orders/edit', tracks_sygic)
                .then(function (response) {
                  const myrouteurl8 = encodeURIComponent('|'+api_ssl+'://'+api_url+'/order.sif');
                  const urlsygic8 = 'com.sygic.aura://route_download'+myrouteurl8;
                  setTimeout(() => {
                    window.open(urlsygic8, '_blank');
                  })
                  // windowReference.location = urlsygic8;
                  let endTime = performance.now();
                  that.setState({
                    orderProcessingTime: parseInt(endTime - startTime)
                  });
                });
            });
     });
    })
    .catch((error) => {
      console.error(error)
    })
  };





  render() {
    let user_add_button_active = false;
    if (this.state.currentUserRoles === "admin" || this.state.currentUserRoles === "owner"){
      user_add_button_active = true;
    }
    let oid = this.state.organization_id;
    let order_add_button = false;
    if (oid === 1 ||oid  === 3){
      order_add_button = true;
    }
    return (
      this.state.isUserAuthenticated ?
      <div className={'content-wrapper users '+this.state.preloader}>
        <div className={`content-left ${this.state.closeDetails === true? 'opened': ''}`}>
          <div className="header">
            <div className="left">
              <h2>Użytkownicy</h2>
            </div>
            <div className="right">
                {user_add_button_active === true?
                <div className="button add" onClick={this.showModal}>
                  <IoIosAdd />
                  <span className="text">Dodaj użytkownika</span>
                </div>
                :
                ''
                }
                {order_add_button === true?
                <div onClick={() => this.orderSend()} className="button margin-left send-orderssssss">
                  <IoIosAdd />
                  Wyślij zlecenie NOWE
                </div>
                :
                ''
                }
            </div>
          </div>
          <div>
          Czas generowania nowego zlecenia: {this.state.orderProcessingTime} ms
          </div>
          <div className="content">
            <ul>
              {
                this.state.data.map((user, i) => (
                  <li className="user" key={user.id}>
                    <div className="left">
                      <div className="top-line">
                        <div className="username">{user.username}</div>
                        <div className="username">{user.roles}</div>
                        <div className="username">{user.is_active}</div>
                      </div>
                      <div className="middle-line">
                      </div>
                      <div className="bottom-line">
                      </div>
                    </div>
                      <div className="details">
                        <div className="right" onClick={() => this.handleShowDetails(user, i)}>
                          Szczegóły
                        </div>
                    </div>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
        <div className={`content-right ${this.state.closeDetails === true? 'opened': ''}`}>
          <div className="inner">
            <User currentUser={this.state.currentUser} currentUserRoles={this.state.currentUserRoles} newPassword={this.handleNewPassword} mailSend={this.handleMailSend} editUser={this.handleEditUser} goBack={this.handleCloseDetails} />
          </div>
        </div>
        <div className={`modal add ${this.state.modal === true? 'opened': ''}`}>
          <div className="wrapper">
            <div className="inner">
              <div className="header">
                <div className="left">
                  <h3>Dodaj użytkownika</h3>
                </div>
                <div className="right">
                <button onClick={this.hideModal} id="modal-btn-close" className="on"><span></span><span></span><span></span>
                </button>
                </div>
              </div>
              <div className="content">
                <form onSubmit={this.handleUserAddSubmit}>
                  <label>
                    Nazwa użytkownika:
                    <input type="text" value={this.state.username} name="username" onChange={this.handleInputChange} />
                  </label>
                  <label>
                    Rola:
                    <select value={this.state.roles}  onChange={this.handleRolesChange}>
                      <option value="administrator">Administrator</option>
                      <option value="owner">Właściciel</option>
                      <option value="operator">Operator</option>
                      <option value="speditor">Spedycja</option>
                      <option value="driver">Kierowca</option>
                    </select>
                  </label>
                  <label>
                    Użtytkownik aktywny:
                    <input type="text" value={this.state.is_active} name="username" onChange={this.handleInputChange} />
                  </label>
                  <input type="submit" value="Dodaj" />
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        {/* Same as */}
        <ToastContainer />
      </div>
      :
      <div className="content-wrapper users">
        <div className="auth-error">
          Nie masz uprawnień by wyświetlić tę stronę.
        </div>
      </div>
      // <Redirect to="/" />
    );
  }
}
