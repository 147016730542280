import * as React from 'react';
import axios from 'axios';
import { GET_ERRORS, SET_CURRENT_ORGANIZATION } from './types';
const api_name = process.env.REACT_APP_API_URL;
const api_ssl = process.env.REACT_APP_API_SSL;
const domainName =  window.location.hostname;
var api_url = domainName+api_name;

switch (domainName) {
  case '141.94.222.182':
    api_url = domainName+':5000'+api_name;
    break;
  default:
}

// export const setCurrentOrganization = organization => {
//     return {
//         type: SET_CURRENT_ORGANIZATION,
//         payload: organization
//     }
// }


export const setCurrentOrganization = (organization_id) => dispatch => {
    // console.log('Org changed', organization_id);
    axios.get(
      api_ssl+'://'+api_url+'/currentorg',
      { params: { id: organization_id } }
    )
    .then(response => {
        // console.log('Org params', response.data);

        dispatch({
            type: SET_CURRENT_ORGANIZATION,
            payload: response.data
        });
    });
}
