import React from "react";
import {
  Link
} from "react-router-dom";
import { IconButton, Input, SelectPicker } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';
import axios from 'axios';
import { addDays, subDays } from "date-fns";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from '../../WithRouter';
import Moment from 'react-moment';
import 'moment-timezone';

import { AiOutlineThunderbolt } from "react-icons/ai";
import { GiCartwheel } from "react-icons/gi";
import { GiSattelite } from "react-icons/gi";
import { RiBattery2ChargeLine } from "react-icons/ri";


import { listEtollStatus } from '../../actions/IntegrationsEtollStatus';



class DisplayEtollList extends React.Component {
  constructor(props) {
  super(props);
      this.state = {
        vehiclesList: [],
        integrationsEtoll: [],
      };
  }

  componentDidMount() {
    let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    let at = '';
    let oi = '';
    if(auth_get) {
      at = auth_get.access_token;
      oi = auth_get.organization_id;
      this.setState({
        organization_id: oi,
      });
    }
    const params = {
      organization_id: oi,
    }
    this.props.listEtollStatus(params, this.props.history);

    this.timeout = setInterval(() => {
      let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
      let at = '';
      let oi = '';
      if(auth_get) {
        at = auth_get.access_token;
        oi = auth_get.organization_id;
      }
      const params = {
        organization_id: oi,
      }
      this.props.listEtollStatus(params, this.props.history);
    }, 10000)
  }

  componentDidUpdate(prevProps) {
    if(prevProps.integrationsEtoll.list.data !== this.props.integrationsEtoll.list.data) {
      this.setState({integrationsEtoll: this.props.integrationsEtoll.list.data});
    }
    if(prevProps.orgId !== this.props.orgId ) {
      this.setState({organizationId:  this.props.orgId})
      const params = {
        organization_id: this.props.orgId,
      }
      this.props.listEtollStatus(params, this.props.history);
    }
  }

  render() {
    let iet = this.state.integrationsEtoll;
    iet.forEach(function(obj){
      console.log(obj)
      let sts = obj.value.sendToEtollTimestamp;
      let cts = Date.now();
      let tdiff = (cts-sts)/1000;
      let ign = obj.value.ignition;
      let mov = obj.value.movement;
      let sendstat = false;
      if(ign === 1 && mov === 1) {
        if(tdiff <= 120){
          sendstat = true;
        }
      } else {
        if(tdiff <= 900){
          sendstat = true;
        }
      }
      obj.dst = sendstat;
    });
    return (
      <div className="content-wrapper notifications integrations">
        <div className="content">
          <div className="content-left">
          <h2>Wysyłka E-toll</h2>
          <ul>
            <li className='notification head'>
              <div className="vehicle-container">
                <div className="dot"></div>
                <div className="vehicle">
                  <div className="codename">
                    Nazwa
                  </div>
                  <div className="licenseplate">
                    Rej.
                  </div>
                </div>
                <div className="date-start">
                  <div className="label">
                    Ostatnia wysyłka do Etoll:
                  </div>
                </div>
                <div className="parameters">
                  <div className="label">
                    Parametry
                  </div>
                </div>
              </div>
            </li>
            {this.state.integrationsEtoll.map((integration, i) => (
              <li key={i} className='notification'>
                {integration.value === false ? (
                  <div className="vehicle-container">
                    <div className={`dot dot-${integration.dst ? 'green' : 'red'}`}></div>
                    <div className="vehicle">
                      <div className="codename">
                        {integration.codename}
                      </div>
                      <div className="licenseplate">
                        {integration.licenseplate}
                      </div>
                    </div>
                    <div className="date-start">
                      Brak danych
                    </div>
                  </div>
                  ) : (
                  <div className="vehicle-container">
                    <div className={`dot dot-${integration.dst ? 'green' : 'red'}`}></div>
                    <div className="vehicle">
                      <div className="codename">
                        {integration.codename}
                      </div>
                      <div className="licenseplate">
                        {integration.licenseplate}
                      </div>
                    </div>
                    <div className="date-start">
                      <Moment format="DD.MM.YYYY HH:mm:ss">
                        {new Date(integration.value.recordTimestamp)}
                      </Moment>
                    </div>
                    <div className="parameters">
                      <div className="par ignition">
                        <div className="label">
                          <AiOutlineThunderbolt />
                        </div>
                          {integration.value.ignition ? 'Wł' : 'Wył'}
                      </div>
                      <div className="par movement">
                        <div className="label">
                          <GiCartwheel />
                        </div>
                          {integration.value.movement ? 'Wł' : 'Wył'}
                      </div>
                      <div className="par satelites">
                        <div className="label">
                          <GiSattelite />
                        </div>
                          {integration.value.sat}
                      </div>
                      <div className="par voltage">
                        <div className="label">
                          <RiBattery2ChargeLine />
                        </div>
                          {(integration.value.voltage/1000).toFixed(2)}V
                      </div>
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>

          </div>
        </div>
      </div>
    );
  }
}

DisplayEtollList.propTypes = {
    listEtollStatus: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    integrationsEtoll: state.integrationsEtoll,
    vehicles: state.vehicles,
    errors: state.errors
});

export default connect(mapStateToProps,{ listEtollStatus })(withRouter(DisplayEtollList))
