import React from "react";
import classnames from 'classnames';
import { Tab, Tabs as TabsComponent, TabList, TabPanel } from "react-tabs";
import { Button, Input, InputGroup } from 'rsuite';
import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';

export class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: [
        {
            "id": '',
            "username": '',
            "firstname": '',
            "lastname": '',
            "email": '',
            "phone": '',
            "roles": '',
            "is_active": '',
            "organization_id": ''
        }
      ],
      currentUserRoles: '',
      goBack: '',
      password: '',
      password_confirm: '',
      passwordVisible: false,
      id: '',
      username: '',
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      roles: '',
      is_active: '',
      errors: {}
    };
    this.handleCloseDetails = this.handleCloseDetails.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleMailSend = this.handleMailSend.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleUserEditSubmit = this.handleUserEditSubmit.bind(this);
    this.handlePasswordVisible = this.handlePasswordVisible.bind(this);
    this.newHandleInputChange = this.newHandleInputChange.bind(this);
  }

  componentWillReceiveProps (newProps) {
    if( newProps.currentUserRoles !== this.props.currentUserRoles ) {
      this.setState({currentUserRoles:  newProps.currentUserRoles})
    }
    if( newProps.currentUser !== this.props.currentUser ) {
      console.log('NEW PROPS USER');
      this.setState({currentUser:  newProps.currentUser})
      this.setState({
        id: newProps.currentUser.id,
        username: newProps.currentUser.username,
        firstname: newProps.currentUser.firstname,
        lastname: newProps.currentUser.lastname,
        email: newProps.currentUser.email,
        phone: newProps.currentUser.phone,
        roles: newProps.currentUser.roles,
        is_active: newProps.currentUser.is_active
      });
    }
  }

  handleCloseDetails = () => {
    this.props.goBack('back');
  };

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState({
      [name]: value
    });
  }

  newHandleInputChange(n, v ) {
      this.setState({
          [n]: v
      })
  }

  handlePasswordVisible(e) {
    const s = parseInt(e.currentTarget.getAttribute('state'));
    let v = false;
    if (s === 0) {
      v = true;
    }
    this.setState({
      passwordVisible: v
    })
  }

  handlePasswordChange(event) {
    event.preventDefault();
    console.log(this.props.currentUser);
    this.props.newPassword({'u': this.props.currentUser.id, 'p':this.state.password, 'pc':this.state.password_confirm});
  };

  handleMailSend(event) {
    event.preventDefault();
    // console.log(this.props.currentUser);
    this.props.mailSend('mail_send');
  };




  handleSelectChange(event) {
    const name = event.target.name;
    this.setState({[name]: event.target.value});
  }

  handleUserEditSubmit(event) {
    event.preventDefault();
    this.props.editUser([{
      id: this.state.id,
      username: this.state.username,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      phone: this.state.phone,
      postcode: this.state.postcode,
      roles: this.state.roles,
      is_active: this.state.is_active,
      country: this.state.country,
    }]);
  };

  render(){
    // const currentUser = this.props.currentUser;
    const { errors } = this.state;

    const tabsHeaders = [
      {
        heading: "Edycja",
      },
      {
        heading: "Ustawienia dostępu",
      }
    ];
    const tabsNumber =  'tabs-'+tabsHeaders.length;
    return (
      <div className="list-element-details">
        <div className="header">
          <div className="arrow-container">
            <span onClick={this.handleCloseDetails} className={`arrow ${this.state.navWidth === true? 'wide': ''}`}>
              <span></span>
              <span></span>
            </span>
          </div>
          <div className="name-container">
            <div className="name">{this.state.firstname} {this.state.lastname}</div>
            <div className="codenamer">{this.state.username}</div>
          </div>
        </div>

        <div className={tabsNumber}>
          <TabsComponent>
            <TabList>
              {tabsHeaders.map(({ heading }, i) => (
                <Tab key={i}>{heading}</Tab>
              ))}
            </TabList>
            <TabPanel>
              <div className="edit-object">
                <form onSubmit={this.handleUserEditSubmit}>
                  <label>
                    Nazwa użytkownika:
                    <input type="text" value={this.state.username} name="username" onChange={this.handleInputChange} />
                  </label>
                  <label>
                    Imię:
                    <input type="text" value={this.state.firstname} name="firstname" onChange={this.handleInputChange} />
                  </label>
                  <label>
                    Nazwisko:
                    <input type="text" value={this.state.lastname} name="lastname" onChange={this.handleInputChange} />
                  </label>
                  <label>
                    Email:
                    <input type="text" value={this.state.email} name="email" onChange={this.handleInputChange} />
                  </label>
                  <label>
                    Telefon:
                    <input type="text" value={this.state.phone} name="phone" onChange={this.handleInputChange} />
                  </label>
                  <label>
                    Uprawnienia:
                    <select value={this.state.roles} name="roles" onChange={this.handleSelectChange}>
                      {this.state.currentUserRoles === "admin"?
                        <option value="admin">Administrator</option>
                        :
                        ''
                      }
                      <option value="owner">Właściciel</option>
                      <option value="operator">Operator</option>
                      <option value="speditor">Spedycja</option>
                      <option value="driver">Kierowca</option>
                    </select>
                  </label>
                  <label>
                    Status:
                    <select value={this.state.is_active} name="is_active"  onChange={this.handleSelectChange}>
                      <option value="true">Użytkownik aktywny</option>
                      <option value="false">Użytkownik nieaktywny</option>
                    </select>
                  </label>
                  <input type="submit" value="Zapisz" />
                </form>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="update-password-form">
                <h3>Zmień hasło</h3>
                <form onSubmit={this.handlePasswordChange}>

                <div className="form-group">
                  <InputGroup inside>
                    <Input
                    type={this.state.passwordVisible ? 'text' : 'password'}
                    placeholder="Hasło"
                    autoComplete="current-password"
                    className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.password
                    })}
                    name="password"
                    onChange={value => {
                      this.newHandleInputChange('password', value);
                    }}
                    value={ this.state.password }
                    />
                    {errors.password && (<div className="invalid-feedback">{errors.password}</div>)}
                    <InputGroup.Button state={this.state.passwordVisible ? 1 : 0} onClick={this.handlePasswordVisible}>
                      {this.state.passwordVisible ? <EyeIcon /> : <EyeSlashIcon />}
                    </InputGroup.Button>
                  </InputGroup>
                </div>
                <div className="form-group">
                  <InputGroup inside>
                    <Input
                    type={this.state.passwordVisible ? 'text' : 'password'}
                    placeholder="Powtórz hasło"
                    autoComplete="current-password"
                    className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.password_confirm
                    })}
                    name="password_confirm"
                    onChange={value => {
                      this.newHandleInputChange('password_confirm', value);
                    }}
                    value={ this.state.password_confirm }
                    />
                    {errors.password_confirm && (<div className="invalid-feedback">{errors.password_confirm}</div>)}
                    <InputGroup.Button state={this.state.passwordVisible ? 1 : 0} onClick={this.handlePasswordVisible}>
                      {this.state.passwordVisible ? <EyeIcon /> : <EyeSlashIcon />}
                    </InputGroup.Button>
                  </InputGroup>
                </div>

                <input type="submit" value="Aktualizuj"/>
                </form>
              </div>
              {this.state.currentUserRoles === "admin"?
                <div>
                  <h3>Wyslij</h3>
                  <button onClick={this.handleMailSend} id="sendMail">
                    Wyślij Zaproszenie
                  </button>
                </div>
                :
                ''
              }
            </TabPanel>
          </TabsComponent>
        </div>
      </div>
    )
  }
}
