import * as React from 'react';
import axios from 'axios';
import { GET_ERRORS, GET_NOTIFICATIONS_LIST, GET_NOTIFICATIONS_LATEST } from './types';
const api_name = process.env.REACT_APP_API_URL;
const api_ssl = process.env.REACT_APP_API_SSL;
const domainName =  window.location.hostname;
var api_url = domainName+api_name;

switch (domainName) {
  case '141.94.222.182':
    api_url = domainName+':5000'+api_name;
    break;
  default:
}

// export const removeSharedVehicle = (organization, history) => dispatch => {
//     console.log('ssssssssssssssss',organization);
//     axios.post(api_url+'sharedvehicles/removedevice', organization)
//             .catch(err => {
//                 dispatch({
//                     type: GET_ERRORS,
//                     payload: err.response.data
//                 });
//             });
// }

export const listNotifications = (params) => dispatch => {
  // console.log(sharedvehicleslist);
      // dispatch({
      //     type: GET_NOTIFICATIONS_LIST,
      //     payload: notificationsList
      // });
    axios.get(
      api_ssl+'://'+api_url+'/notifications/list',
      { params: params }
    )
    .then(response => {
        dispatch({
            type: GET_NOTIFICATIONS_LIST,
            payload: response.data
        });
    });
}

export const latestNotifications = (organization_id) => dispatch => {
    // console.log('Function started!');
    axios.get(
      api_ssl+'://'+api_url+'/notifications/latest',
      { params: { organization_id: organization_id } }
    )
    .then(response => {
        dispatch({
            type: GET_NOTIFICATIONS_LATEST,
            payload: response.data
        });
    });
}

export const readNotification = (params, history) => dispatch => {
    console.log('Click read!');
    axios.post(api_ssl+'://'+api_url+'/notifications/read', params)
      .then(response => {
        console.log('params--------------------', params)
        let t = 'list';
        if (params['latest'] === true) {
            t = 'latest';
        }
        axios.get(
          api_ssl+'://'+api_url+'/notifications/'+t,
          { params: params }
        )
        .then(response => {
            dispatch({
                type: GET_NOTIFICATIONS_LIST,
                payload: response.data
            });
        });


        axios.get(
          api_ssl+'://'+api_url+'/notifications/latest',
          { params: params }
        )
        .then(response => {
            dispatch({
                type: GET_NOTIFICATIONS_LATEST,
                payload: response.data
            });
        });
      })
      .catch(err => {
          dispatch({
              type: GET_ERRORS,
              payload: err.response.data
          });
      });
}
