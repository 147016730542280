// import { useMemo, useRef } from "react";
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";

// import slideImg1 from '../img/marker.svg';



function CustomMarker(props) {
  const { id, latitude, longitude, heading, licenseplate, ignition, movement, speed } = props;
  // const classes = useStyles();
  // console.log(latitude, longitude);

  const marker_drive = '<svg version="1.1" id="Layer_1" style="transform: rotate('+heading+'deg);" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="12" height="12" '+
    'viewBox="0 0 77 77" enable-background="new 0 0 77 77" xml:space="preserve">'+
    '<path d="M72.7,77c-0.8,0-1.7-0.2-2.4-0.7L40.8,56.7c-1.4-0.9-3.3-0.9-4.7,0L6.6,76.3C4.6,77.5,2,77,0.7,75'+
    '	c-0.8-1.3-0.9-2.9-0.2-4.2L34.7,2.3c1.1-2.1,3.7-2.9,5.8-1.9c0.8,0.4,1.4,1.1,1.9,1.9l34.2,68.4c0.9,1.7,0.5,3.8-1,5.1'+
    '	C74.7,76.6,73.7,77,72.7,77z"/>'+
    '</svg>';

  let marker = '<div class="parking-mode">P</div>';
  if (movement === 1) {
    marker = marker_drive;
  }
  // console.log('Marker Loadkin');

  // const [prevPos, setPrevPos] = useState([latitude, longitude]);
  const navIcon = new L.divIcon({
    // iconUrl: slideImg1,
    iconAnchor: [0,0],
    popupAnchor: [0,0],
    // shadowUrl: null,
    shadowSize: "12px",
    // shadowAnchor: null,
    iconSize: new L.Point(140, 24),
    // className: classes.leafletDivIcon,
    className: 'marker-vehicle marker-vehicle-'+id,
    html:
      `<div class="icon">
        <div class="inner">
        </div>
       </div>
      <div class="label">
        <div id="more-marker-vehicle-`+id+`" class="more">
          <div className="el">Prędkość: `+speed+`<span class="unit"> km/h</span></div>
        </div>
        <div class="less">
          <div class="direction_icon">`+marker+`</div>
          <div class="text">`+licenseplate+`</div>
        </div>
       </div>`
  });

  // useEffect(() => {
  //   if (prevPos[1] !== longitude && prevPos[0] !== latitude) {
  //     setPrevPos([latitude, longitude]);
  //     console.log("effect log", prevPos, [latitude, longitude]);
  //   }
  // }, [latitude, longitude, prevPos]);


  return (
    <Marker
      key={id}
      position={[latitude, longitude]}
      icon={navIcon}
      vehicleid={id}
      eventHandlers={{
        click: (e) => {
          e.target._icon.classList.add("bulbazaur");
          // e.target._icon.className += " bulbazaur";
          // e.target.options.icon.options.className += " bulbazaur";
          console.log(e);
          // console.log(e.target);  // will print 'FooBar' in console
          // var s = document.getElementsByClassName('marker-vehicle-'+e.target.options.vehicleid);
          // console.log(s);
          // s.className += " otherclass";
          // s.target.className += " open";
          console.log('Blabla');  // will print 'FooBar' in console
        },
      }}
    >
    </Marker>


    // <h1>sdfsdf</h1>
  );
}

export default CustomMarker;
// <Popup className="marker-tooltip">
//     <div className="el">Zapłon: {ignition}</div>
//     <div className="el">Ruch: {movement}</div>
// </Popup>
