import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from '../../WithRouter';
import Moment from 'react-moment';
import 'moment-timezone';
import { IconButton, Input, InputPicker, CheckPicker, Toggle } from 'rsuite';
import TrashIcon from '@rsuite/icons/Trash';
import moment from 'moment';
import { deleteNotificationsRule, editNotificationsRule } from '../../actions/NotificationRules';


const notificationTypesList = {
  'satelitesNone': 'Brak satelitów',
  'satelitesLow': 'Słaby sygnał GPS',
  'voltageLow': 'Niskie napięcie akumulatora',
  'voltageNone': 'Utracono zasilanie',
  'ignitionNone': 'Jazda z wyłączonym zapłonem'
};

class NotificationRule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentNotificationRule: [],
      navWidth: true,
      vehiclesList: [],
      ruleName: '',
      ruleType: '',
      vehiclesSelected: [],
      organization_id: ''
    };
    this.handleCloseDetails = this.handleCloseDetails.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleVehiclesChange = this.handleVehiclesChange.bind(this);
    this.handleEditSubmit = this.handleEditSubmit.bind(this);
  }

  handleCloseDetails = () => {
    this.props.goBack('back');
  };

  componentDidMount() {
    let notification = this.props.currentNotificationRule;
    // console.log('ddddddddddddddddddd');
    // console.log(notification);
    // console.log('ddddddddddddddddddd');
    this.setState({
      currentNotificationRule: notification,
      organization_id:  this.props.orgId
    });
  }

  componentDidUpdate(prevProps) {
    if(prevProps.vehicles.list !== this.props.vehicles.list) {
      this.setState({vehiclesList: this.props.vehicles.list});
    }
    if(prevProps.orgId !== this.props.orgId ) {
      this.setState({organization_id:  this.props.orgId})
    }
    if(prevProps.currentNotificationRule !== this.props.currentNotificationRule) {
      let nr = this.props.currentNotificationRule;
      let ds = this.props.currentNotificationRule.vehicles;
      console.log('ds', ds);
      let vl = this.state.vehiclesList;
      let sn = [];
      for (var i = 0; i < ds.length; i++) {
        console.log('ds[i]',ds[i].value);
        let obj = vl.find(o => o.value === ds[i].value);
        sn.push(
          obj.value
        );
      }

      console.log('sn', sn);
      this.setState({
        currentNotificationRule: nr,
        ruleName: nr.name,
        ruleType: nr.type,
        vehiclesSelected: sn
      });

    }

  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      ruleName: value
    });
  }

  handleTypeChange(event) {
    this.setState({ruleType: event});
  }

  handleVehiclesChange(event) {
    let ds = event;
    let vl = this.state.vehiclesList;
    let sn = [];
    for (var i = 0; i < ds.length; i++) {
      let obj = vl.find(o => o.value === ds[i]);
      sn.push(
        obj
      );
    }
    this.setState({vehiclesSelected: sn});
  }

  deleteRule(id) {
    const params = {
      _id: id,
      organization_id: this.state.organization_id,
    }
    this.props.deleteNotificationsRule(params, this.props.history);
  };

  handleEditSubmit(event) {
    const params = {
      _id: this.statecurrentNotificationRule._id,
      name: this.state.ruleName,
      type: this.state.ruleType,
      vehicles: this.state.vehiclesSelected,
      organization_id: this.state.organization_id,
    }
    this.props.editNotificationsRule(params, this.props.history);
  }


  render(){
    let notification = this.state.currentNotificationRule;
    // console.log(notification);
    // console.log(' this.state.organization_id', this.state.organization_id);
    // console.log(' this.state.organization_id', this.props);
    const showreport = [
      { label: 'Brak satelitów', value: 'satelitesNone' },
      { label: 'Słaby sygnał GPS', value: 'satelitesLow' },
      { label: 'Niskie napięcie akumulatora', value: 'voltageLow' },
      { label: 'Utracono zasilanie', value: 'voltageNone' },
      { label: 'Jazda z wyłączonym zapłonem', value: 'ignitionNone' },
    ];
    // console.log('name', this.state.ruleName);
    return (
      <div className="list-element-details">
        <div className="header">
          <div className="arrow-container">
            <span onClick={this.handleCloseDetails} className={`arrow ${this.state.navWidth === true? 'wide': ''}`}>
              <span></span>
              <span></span>
            </span>
          </div>
          <div className="name-container">
            <div className="licenseplate">{this.state.ruleName}</div>
            <div className="type">{notificationTypesList[this.state.ruleType]}</div>
          </div>
        </div>
        <div className="notification-details">
        <form onSubmit={this.handleEditSubmit}>
        <div className="add-form-element">
          <label>
            Nazwa:
            <input type="text" value={this.state.ruleName} name="name" onChange={this.handleInputChange} />
          </label>
        </div>
        <div className="add-form-element">
          <span className="label">
          Typ powiadomienia:
          </span>
          <InputPicker
            className="full-width"
            cleanable={false}
            value={this.state.ruleType}
            onChange={this.handleTypeChange}
            data={showreport} />
        </div>
        <div className="add-form-element">
          <span className="label">
            Wybierz pojazdy
          </span>
          <CheckPicker
            className="full-width"
            value={this.state.vehiclesSelected}
            data={this.state.vehiclesList}
            onChange={this.handleVehiclesChange}
            locale={{
              placeholder: 'Wybierz',
              searchPlaceholder: 'Szukaj',
              noResultsText: 'Brak wyników'
            }}
          />
        </div>
        <div className="form-buttons">
        <IconButton
          appearance="primary"
          className="delete-button"
          size="md"
          onClick={() => this.deleteRule(notification._id)}
          icon={<TrashIcon />}>Usuń</IconButton>
          <input type="submit" value="Zapisz zmiany" />
          </div>
        </form>
          <div className="bottom-line">
          </div>
        </div>
      </div>
    )
  }

}

NotificationRule.propTypes = {
    deleteNotificationsRule: PropTypes.func.isRequired,
    editNotificationsRule: PropTypes.func.isRequired,
};


const mapStateToProps = state => ({
    vehicles: state.vehicles,
    errors: state.errors
});

export default connect(mapStateToProps,{ deleteNotificationsRule, editNotificationsRule })(withRouter(NotificationRule))
