import React from "react";
import {
  Link
} from "react-router-dom";
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from '../../WithRouter';
import Moment from 'react-moment';
import { IconButton, Input, InputPicker, CheckPicker, Toggle } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';

import { listNotificationsRules, addNotificationsRule, toggleNotificationsRule } from '../../actions/NotificationRules';
import NotificationRule from "./DisplayNotificationRulesDetails";


const api_name = process.env.REACT_APP_API_URL;
const api_ssl = process.env.REACT_APP_API_SSL;
const domainName =  window.location.hostname;
var api_url = domainName+api_name;
var url_link_to_map = domainName;

switch (domainName) {
  case '141.94.222.182':
    api_url = domainName+':5000'+api_name;
    url_link_to_map = domainName+':3000';
    break;
  default:
}

const notificationTypesList = {
  'satelitesNone': 'Brak satelitów',
  'satelitesLow': 'Słaby sygnał GPS',
  'voltageLow': 'Niskie napięcie akumulatora',
  'voltageNone': 'Utracono zasilanie',
  'ignitionNone': 'Jazda z wyłączonym zapłonem'
};

class DisplayNotificationsRules extends React.Component {
  constructor(props) {
  super(props);
      this.state = {
        data: [],
        organization_id: '',
        ruleName: '',
        vehiclesList: [],
        vehiclesSelected: [],
        ruleToggle: false,
        panelDetails: false,
        ruleType: 'satelitesNone',
        notificationsRules: [],
        currentNotificationRule: [],
        modal: false,
        ws: null
      };
      this.showModal = this.showModal.bind(this);
      this.hideModal = this.hideModal.bind(this);
      this.handleAddSubmit = this.handleAddSubmit.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleTypeChange = this.handleTypeChange.bind(this);
      this.handleVehiclesChange = this.handleVehiclesChange.bind(this);
      this.handleShowDetails = this.handleShowDetails.bind(this);
      this.handleCloseDetails = this.handleCloseDetails.bind(this);
      this.handleRuleToggle = this.handleRuleToggle.bind(this);
  }

  componentDidMount() {
    let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    let at = '';
    let oi = '';
    if(auth_get) {
      at = auth_get.access_token;
      oi = auth_get.organization_id;
      this.setState({
        organization_id: oi,
      });
    }

    this.props.listNotificationsRules(oi, this.props.history);

    this.timeout = setInterval(() => {
      let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
      let at = '';
      let oi = '';
      if(auth_get) {
        at = auth_get.access_token;
        oi = auth_get.organization_id;
      }
      this.props.listNotificationsRules(oi, this.props.history);
    }, 10000)

  }

  handleShowDetails = (notification, event) => {
    this.setState({
      panelDetails: true,
      currentNotificationRule: notification,
    });
  };
  handleCloseDetails = (notification, event) => {
    this.setState({
      panelDetails: false
    });
  }

  componentDidUpdate(prevProps) {
    // console.log(prevProps);
    // console.log(this.props);
    if(prevProps.notificationsRules.list.data !== this.props.notificationsRules.list.data) {
      this.setState({notificationsRules: this.props.notificationsRules.list.data});
    }
    if(prevProps.vehicles.list !== this.props.vehicles.list) {
      this.setState({vehiclesList: this.props.vehicles.list});
    }

    if(prevProps.orgId !== this.props.orgId ) {
      this.setState({organization_id:  this.props.orgId})
      this.props.listNotificationsRules(this.props.orgId, this.props.history);
    }
  }

  handleAddSubmit(event) {
    event.preventDefault();
    const that = this;
    let vehicles = that.state.vehiclesSelected;
    vehicles = vehicles.filter((i)=>i !== null && typeof i !== 'undefined');
    console.log(vehicles);

    const params = {
      name: that.state.ruleName,
      type: that.state.ruleType,
      organization_id: that.state.organization_id,
      vehicles: vehicles,
    }
    const add = this.props.addNotificationsRule(params, this.props.history);
    this.setState({
      modal: false,
      ruleName: '',
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      ruleName: value
    });
  }

  handleTypeChange(event) {
    this.setState({ruleType: event});
  }

  handleRuleToggle = (rule) => {
    // console.log(rule);
    // this.setState({ruleType: event});
    const params = {
      _id: rule._id,
      active: rule.active,
      organization_id: this.state.organization_id,
    }
    this.props.toggleNotificationsRule(params, this.props.history);
    // this.props.listNotificationsRules(this.props.orgId, this.props.history);
  }

  handleVehiclesChange(event) {
    let ds = event;
    // console.log('---ds',ds);
    let vl = this.state.vehiclesList;
    let sn = [];
    for (var i = 0; i < ds.length; i++) {
      let obj = vl.find(o => o.value === ds[i]);
      sn.push(
        obj
      );
    }
    // console.log('---sn',sn);
    this.setState({vehiclesSelected: sn});
  }

  showModal(event) {
    this.setState({modal: true});
  };

  hideModal(event) {
    this.setState({modal: false});
  };

  render() {
    console.log('ssssssssssssssssss', this.state.organization_id);
    const showreport = [
      { label: 'Brak satelitów', value: 'satelitesNone' },
      { label: 'Słaby sygnał GPS', value: 'satelitesLow' },
      { label: 'Niskie napięcie akumulatora', value: 'voltageLow' },
      { label: 'Utracono zasilanie', value: 'voltageNone' },
      { label: 'Jazda z wyłączonym zapłonem', value: 'ignitionNone' },
    ];
    // console.log(this.state.notificationsRules);
    return (
      <div className="content-wrapper notifications-rules">
        <div className="header">
          <div className="left">
            <ul className="header-list">
            <li className="header-list-element">
              <Link to="/notifications">
                Lista Powiadomień
              </Link>
            </li>
            <li className="header-list-element active">
              <Link to="/notifications/rules">
                Reguły Powiadomień
              </Link>
            </li>
            </ul>
          </div>
          <div className="right">
            <IconButton
              appearance="primary"
              onClick={this.showModal}
              className="new-button"
              size="sm"
              icon={<PlusIcon />}>
                Dodaj regułę
            </IconButton>
          </div>
        </div>
        <div className="content">
          <div className={`content-left ${this.state.panelDetails === true? 'opened': ''}`}>
          <ul>
          {this.state.notificationsRules.map((rule, i) => (
            <li key={i} className='notification'>
            <div className="left">
              <div className="top-line">
                <div className="notification=type">
                  {notificationTypesList[rule.type]}
                </div>
              </div>
              <div className="middle-line">
                <div className="rule">
                  <div className="label">
                    Reguła:
                  </div>
                  <div className="value">
                    <div className="codename">
                      {rule.name}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottom-line">
                <div className="date-end">
                  <div className="label">
                    Data utworzenia:
                  </div>
                  <Moment format="DD.MM.YYYY HH:mm:ss">
                    {new Date(rule.created_at)}
                  </Moment>
                </div>
              </div>
            </div>
            <div className="right">
              <div className="switch">
                <Toggle checked={rule.active} size="md" onChange={() => this.handleRuleToggle(rule)} />
              </div>
              <div className="details" onClick={() => this.handleShowDetails(rule, i)}>
                Szczegóły
              </div>
            </div>
            </li>
          ))}
          </ul>
          </div>
          <div className={`content-right ${this.state.panelDetails === true? 'opened': ''}`}>
            <div className="inner">
              <NotificationRule goBack={this.handleCloseDetails} orgId={this.state.organization_id} currentNotificationRule={this.state.currentNotificationRule} />
            </div>
          </div>
        </div>
        <div className={`modal add ${this.state.modal === true? 'opened': ''}`}>
          <div className="wrapper">
            <div className="inner">
              <div className="header">
                <div className="left">
                  <h3>Dodaj regułę powiadomień</h3>
                </div>
                <div className="right">
                <button onClick={this.hideModal} id="modal-btn-close" className="on"><span></span><span></span><span></span>
                </button>
                </div>
              </div>
              <div className="content">
                <form onSubmit={this.handleAddSubmit}>
                <div className="add-form-element">
                  <label>
                    Nazwa:
                    <input type="text" value={this.state.ruleName} name="name" onChange={this.handleInputChange} />
                  </label>
                </div>
                <div className="add-form-element">
                  <span className="label">
                  Typ powiadomienia:
                  </span>
                  <InputPicker
                    className="full-width"
                    cleanable={false}
                    value={this.state.ruleType}
                    onChange={this.handleTypeChange}
                    data={showreport} />
                </div>
                <div className="add-form-element">
                  <span className="label">
                    Wybierz pojazdy
                  </span>
                  <CheckPicker
                    className="full-width"
                    data={this.state.vehiclesList}
                    onChange={this.handleVehiclesChange}
                    locale={{
                      placeholder: 'Wybierz',
                      searchPlaceholder: 'Szukaj',
                      noResultsText: 'Brak wyników'
                    }}
                  />
                </div>
                  <input type="submit" value="Dodaj" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DisplayNotificationsRules.propTypes = {
    listNotificationsRules: PropTypes.func.isRequired,
    addNotificationsRule: PropTypes.func.isRequired,
    toggleNotificationsRule: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    vehicles: state.vehicles,
    notificationsRules: state.notificationsRules,
    errors: state.errors
});

export default connect(mapStateToProps,{ listNotificationsRules,addNotificationsRule, toggleNotificationsRule })(withRouter(DisplayNotificationsRules))
