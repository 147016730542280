import React from "react";
import axios from 'axios';
import { Input } from 'rsuite';


export class AddressAutofill extends React.Component {
  constructor(props) {
  super(props);
      this.state = {
        hereApiKey: 'u8lq0WyTrCJgz9q26G1sim6xiXBQOwoh6rUFU79I534',
        inputValue:'',
        listOpened: false,
        position: [],
        suggestions: []
      };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleGeocodeCheck = this.handleGeocodeCheck.bind(this);
  }


  componentDidMount() {
    console.log('New component mounted!');

    console.log(this.props.pointData.address);
    if(this.state.inputValue === '') {
      this.setState({inputValue:  this.props.pointData.address});
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.pointData !== this.props.pointData) {
      console.log('props changed!!!!!!');
      console.log(this.props.pointData.id);
      console.log(this.props.pointData.address);
      this.setState({inputValue:  this.props.pointData.address});
    }
  }

handleInputChange(event) {
  const target = event.target;
  const value = target.value;
  const l = value.length;
  this.setState({inputValue: value})

  if (l>=1){
    this.setState({listOpened: true})
  } else {
    this.setState({listOpened: false})
  }
  const phrase = value.replace(/ /g, '+');
  const hereUrl = 'https://autocomplete.search.hereapi.com/v1/'+
    'autocomplete'+
    '?q='+phrase+
    '&apiKey='+this.state.hereApiKey;
  let that = this;
  axios.get(hereUrl)
    .then((response) => {
      // console.log(response.data.items);
      that.setState({suggestions: response.data.items})
    });
}

handleGeocodeCheck(suggestion) {
      console.log(this.props.pointData);


      const value = suggestion.address.label;
      // const phrase = value.replace(/ /g, '%2');
      const phrase = encodeURIComponent(value);
      const hereUrl = 'https://geocode.search.hereapi.com/v1/'+
        'geocode'+
        '?q='+phrase+
        '&apiKey='+this.state.hereApiKey;
      let that = this;
      axios.get(hereUrl)
        .then((response) => {
          this.props.newPoints(
            {
              id: this.props.pointData.id,
              lat: response.data.items[0].position.lat,
              lng: response.data.items[0].position.lng,
              properties: suggestion
            }
          );

          that.setState({
            position: response.data.items[0].position,
            inputValue: value,
            listOpened: false
          })
        });
}


  render() {
    // console.log('Position', this.state.position);
    // console.log('List opened', this.state.listOpened);
    return (
      <div className="autocomplete-container">
        <input
          onChange={this.handleInputChange}
          name="address_point_001"
          placeholder={this.props.pointData.text}
          type="text"
          autoComplete="off"
          value={this.state.inputValue}
          />
          <ul className={`suggestions-list ${this.state.listOpened === true? 'opened': ''}`}>
          {
            this.state.suggestions.map((suggestion, i) => (
              <li key={i} onClick={() => this.handleGeocodeCheck(suggestion)}>{suggestion.address.label}</li>
            ))
          }
          </ul>
        </div>
    );
  }
}
