import { Marker, Popup } from "react-leaflet";
import L from "leaflet";
import Moment from 'react-moment';
import 'moment-timezone';




function MarkerTrack(props) {
  const { id, latitude, longitude, first, last } = props;
  let marker = '<div class="parking-mode">P</div>';
  const navIcon = new L.divIcon({
    // iconUrl: slideImg1,
    iconAnchor: [0,0],
    popupAnchor: [0,0],
    // shadowUrl: null,
    shadowSize: "12px",
    // shadowAnchor: null,
    iconSize: new L.Point(140, 24),
    // className: classes.leafletDivIcon,
    className: 'marker-track',
    html: `<div class="icon"><div class="inner"></div></div><div class="label"><div class="direction_icon">`+marker+`</div></div>`
  });

  // useEffect(() => {
  //   if (prevPos[1] !== longitude && prevPos[0] !== latitude) {
  //     setPrevPos([latitude, longitude]);
  //     console.log("effect log", prevPos, [latitude, longitude]);
  //   }
  // }, [latitude, longitude, prevPos]);

  var startDate = new Date(first);
  var endDate = new Date(last);



  return (
    <Marker
      key={id}
      position={[latitude, longitude]}
      icon={navIcon}
    >
        <Popup className="marker-tooltip">
            <div className="el">Początek postoju:
             <Moment format="DD.MM.YYYY HH:mm:ss">
               {startDate}
             </Moment>
             </div>
            <div className="el">Koniec postoju:
             <Moment format="DD.MM.YYYY HH:mm:ss">
               {endDate}
             </Moment>
            </div>
        </Popup>
    </Marker>


    // <h1>sdfsdf</h1>
  );
}

export default MarkerTrack;
