import React from "react";
import {
  Pane,
  Polyline,
  MapContainer,
  TileLayer,
  useMap
} from 'react-leaflet';
import MarkerOrder from "../../libs/MarkerOrder";
import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment';

function UpdateMapCentre(props) {
  const map = useMap();
  if(props.lastActionTimestamp.prev !== props.lastActionTimestamp.action) {
    props.handleLastActionTimeChange('upd');
    // const lat = props.mapCentre.lat;
    // const lng = props.mapCentre.lng;
    // const zoom = 12;
    const b = props.bounds;
    map.fitBounds(b, {padding: [20, 20]})
  }
  return null;
}



export class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bounds: [
        [50.505, -29.09],
        [52.505, 29.09]
      ],
      lastActionTimestamp: {
        prev: Date.now(),
        action: Date.now()
      },
      markers: [],
      routes: [],
      currentNotification: [],
      navWidth: true
    };
    this.handleCloseDetails = this.handleCloseDetails.bind(this);
  }

  handleCloseDetails = () => {
    this.props.goBack('back');
  };

  componentDidMount() {
    let notification = this.props.currentNotification;
    // console.log('f0000000000', );
    if(Object.keys(notification).length > 1) {
      // console.log('notification większe niż 1');
      let samples = notification.samples;
      console.log('samples', samples);
      let flat = samples[0].coordinates[0];
      let flon = samples[0].coordinates[1];
      let llat = flat - 1;
      let llon = flon - 1;
      let routes = [];
      if(samples.length > 1) {
        llat = samples.slice(-1)[0].coordinates[0];
        llon = samples.slice(-1)[0].coordinates[1];
        let points = [];
        for (var i = 0; i < samples.length; i++) {
          points.push(samples[i].coordinates);
        }
        // console.log('points', points);
        routes = {index: 0, points: points, active:1}
      }
      this.setState({
        markers: [
          { lat: flat, lng: flon, text: 'Rozpoczęcie'},
          { lat: llat, lng: llon, text: 'Zakończenie'}
        ],
        bounds: [
          [flat, flon],
          [llat, llon]
        ],
        routes: [
          routes
        ]
      });
    }
    this.setState({
      currentNotification: notification
    });
  }

  componentDidUpdate(prevProps) {
    if(prevProps.currentNotification !== this.props.currentNotification) {
      let notification = this.props.currentNotification;
      // console.log('f11111111111', notification.length);
      if(Object.keys(notification).length > 1) {
        // console.log('notification większe niż 1');
        let samples = notification.samples;
        console.log('samples', samples);
        let flat = samples[0].coordinates[0];
        let flon = samples[0].coordinates[1];
        let llat = flat - 1;
        let llon = flon - 1;
        let routes = [];
        if(samples.length > 1) {
          llat = samples.slice(-1)[0].coordinates[0];
          llon = samples.slice(-1)[0].coordinates[1];
          let points = [];
          for (var i = 0; i < samples.length; i++) {
            points.push(samples[i].coordinates);
          }
          // console.log('points', points);
          routes = {index: 0, points: points, active:1}
        }

        const newTimestamp = {
          prev: this.state.lastActionTimestamp.prev,
          action: Date.now()
        }

        this.setState({
          markers: [
            { lat: flat, lng: flon, text: 'Rozpoczęcie'},
            { lat: llat, lng: llon, text: 'Zakończenie'}
          ],
          lastActionTimestamp: newTimestamp,
          bounds: [
            [flat, flon],
            [llat, llon]
          ],
          routes: [
            routes
          ]
        });
      }
      this.setState({currentNotification: this.props.currentNotification});
    }
  }

  handleLastActionTimeChange = (childData) => {
    const nt = {
      prev: Date.now(),
      action: Date.now(),
    };
    this.setState({
      lastActionTimestamp: nt
    });
  };

  render(){
    let notification = this.state.currentNotification;
    // const bounds= ;
    // console.log('markers', this.state.markers);
    // console.log('bounds', this.state.bounds);
    // console.log('routes', this.state.routes);
    return (
      <div className="list-element-details">
        <div className="header">
          <div className="arrow-container">
            <span onClick={this.handleCloseDetails} className={`arrow ${this.state.navWidth === true? 'wide': ''}`}>
              <span></span>
              <span></span>
            </span>
          </div>
          <div className="name-container">
            <div className="licenseplate">{notification.typeName}</div>
            <div className="codename">{notification.licenseplate}</div>
          </div>
        </div>
        <div className="notification-details">
          <div className="bottom-line">
            <div className="date-start">
              <div className="label">
                Rozpoczęcie:
              </div>
              <Moment format="DD.MM.YYYY HH:mm:ss">
                {new Date(notification.first)}
              </Moment>
            </div>
            <div className="date-end">
              <div className="label">
                Zakończenie:
              </div>
              <Moment format="DD.MM.YYYY HH:mm:ss">
                {new Date(notification.last)}
              </Moment>
            </div>
            <div className="duration">
              <div className="label">
                Czas trwania:
              </div>
                {notification.duration}
            </div>
          </div>
        </div>

        <div className="map-framer">
        <MapContainer scrollWheelZoom={true} bounds={this.state.bounds}>
          <UpdateMapCentre mapCentre={this.state.newCords} bounds={this.state.bounds} handleLastActionTimeChange={this.handleLastActionTimeChange} lastActionTimestamp={this.state.lastActionTimestamp}/>
          <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {
              this.state.markers.map((point, i) => (
                  point && (
                    <MarkerOrder
                      key={i}
                      id={i}
                      latitude={point.lat}
                      longitude={point.lng}
                      address={point.text}
                    >
                    </MarkerOrder>
                  )
              ))
            }

            {this.state.routes.map((route, i) => (
              route.points && (
                  <div key={i} className='daaaaaaaaaaaaaaaaaaaaaaaa'>
                  <Pane name={'track'+route.index+'_0'} key={i} style={{ zIndex: 488 }}>
                    <Polyline
                      className={'active'+route.active}
                      zIndexOffset="10"
                      pathOptions={{color: '#8097ff', weight: 4}}
                      key={i}
                      positions={route.points}
                      />
                  </Pane>
                  </div>
                )
            ))}

        </MapContainer>
        </div>
      </div>
    )
  }





}
