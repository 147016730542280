// import * as React from 'react';
import React, { useRef, useEffect } from "react";

import {
  Pane,
  Polyline,
  useMap
} from 'react-leaflet';
import L from "leaflet";
import "leaflet-polylinedecorator";
import { antPath } from "leaflet-ant-path";
import MarkerTrack from "./libs/MarkerTrack";
// import { PolylineDecorator } from "./libs/CustomPatch";
import { w3cwebsocket as W3CWebSocket } from "websocket";
const api_name = process.env.REACT_APP_API_URL;
const api_ssl = process.env.REACT_APP_API_SSL;
const api_ws = process.env.REACT_APP_API_WS;
const domainName =  window.location.hostname;
var api_url = domainName+api_name;
switch (domainName) {
  case '141.94.222.182':
    api_url = domainName+':5000'+api_name;
    break;
  default:
}

const toTimestamp = (strDate) => {
  const dt = Date.parse(strDate);
  return dt / 1000;
}

function durationFormated(t) {
    var sec_num = parseInt(t, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
}

function PolylineDecorator({ patterns, polyline }) {
  const map = useMap();
  // console.log('Decorator Run');

  useEffect(() => {
    if (!map) return;

    const options = { use: L.polyline, delay: 400, dashArray: [10,20], className: "dddddddddddaaaaaaaaaaaasssssssssss", weight: 5, color: "#8097ff", pulseColor: "#5573df" };
    const pathActive = L.polyline.antPath(polyline, options);
    let layerGroup = L.layerGroup([pathActive]);
    // map.removeLayer(layerGroup);
    map.eachLayer(function (layer) {
        let layerClass = layer.options.className;
        if(layerClass === 'dddddddddddaaaaaaaaaaaasssssssssss') {
          map.removeLayer(layer);
        }
    });
    layerGroup.addTo(map);

  }, [map]);

  return null;
}

function PolylineRemover({ currentIndex }) {
  const map = useMap();
  if (!map) return;
  if (currentIndex === null) {
    map.eachLayer(function (layer) {
        let layerClass = layer.options.className;
        if(layerClass === 'dddddddddddaaaaaaaaaaaasssssssssss') {
          map.removeLayer(layer);
        }
    });
  }
  return null;
}

export class MapTrack extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTrack: [],
      currentDevice: [],
      currentIndex: null,
      data: [],
      tracks: [],
      track_here: [],
      trackMarkers: [],
      trackOptions: {color: '#5573df', weight: 6},
      ws: null
    };
    this.handleTrackDetails = this.handleTrackDetails.bind(this);

  }

  componentWillReceiveProps (newProps) {
    if( newProps.currentTrack !== this.props.currentTrack ) {
      this.setState({currentTrack: newProps.currentTrack});
      if (newProps.currentTrack.length > 0) {
        let msg_to_server = JSON.stringify({
          "type":"tracks_list",
          "device_id": newProps.currentTrack[0].device.codename,
          "current_track_start": toTimestamp(newProps.currentTrack[0].startDate),
          "current_track_end": toTimestamp(newProps.currentTrack[0].endDate),
        });
        let device_id = newProps.currentTrack[0].device.codename;
        let current_track_start = toTimestamp(newProps.currentTrack[0].startDate);
        let current_track_end = toTimestamp(newProps.currentTrack[0].endDate);
        console.log('current_track_start', current_track_start);
        console.log('current_track_end', current_track_end);
        if(current_track_start === current_track_end) {
          current_track_end = current_track_end+86399;
        }
        // fetch('http://141.94.222.182:3000/here.json')
        // .then(response => response.json())
        // .then((jsonData) => {
        //   function isOdd(n) {
        //      return Math.abs(n % 2) == 1;
        //   }
        //   function distance(lat1, lon1, lat2, lon2, unit) {
        //       if ((lat1 == lat2) && (lon1 == lon2)) {
        //           return 0;
        //       }
        //       else {
        //           var radlat1 = Math.PI * lat1/180;
        //           var radlat2 = Math.PI * lat2/180;
        //           var theta = lon1-lon2;
        //           var radtheta = Math.PI * theta/180;
        //           var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        //           if (dist > 1) {
        //               dist = 1;
        //           }
        //           dist = Math.acos(dist);
        //           dist = dist * 180/Math.PI;
        //           dist = dist * 60 * 1.1515;
        //           if (unit=="K") { dist = dist * 1.609344 }
        //           if (unit=="N") { dist = dist * 0.8684 }
        //           return dist;
        //       }
        //   }
        //   function anglesas(cx, cy, ex, ey) {
        //     var dy = ey - cy ;
        //     var dx = cx - ex ;
        //     return Math.atan2(dx, dy) * 180 / Math.PI;
        //   }
        //   let here_tracks = jsonData.result.balanced.response.route[0].leg;
        //   let waypoints = jsonData.result.balanced.configuration.waypoints;
        //   console.log(waypoints);
        //   var tracks = [];
        //   var tracks_sygic = [];
        //   tracks_sygic.push({lat: waypoints[0].latitude, lon: waypoints[0].longitude, points: null});
        //
        //   for (let i = 0; i < here_tracks.length; i++) {
        //     let here_points =here_tracks[i].link;
        //     var track_points = [];
        //     var track_points_sygic = [];
        //     for (let j = 0; j < here_points.length; j++) {
        //       let shape = here_points[j].shape;
        //       let number = 0;
        //       for (let k = 0; k < shape.length; k++) {
        //         number++;
        //         if (isOdd(number)==1) {
        //           let lat = shape[k]
        //           let lng = shape[k+1]
        //           let record_before = track_points.slice(-1).pop()
        //           let record_before_second = track_points.slice(-2).pop()
        //           if(typeof record_before !== "undefined") {
        //             if(lat !== record_before.lat && lng !== record_before.lng) {
        //               let dst = distance(lat , lng, record_before.lat, record_before.lng, 'K')*1000;
        //               let ang = anglesas(lat , lng, record_before.lat, record_before.lng);
        //               // if(dist >= 100 && dist <= 120) {
        //               //   track_points_sygic.push({ lat: ((lat.toFixed(5))*100000).toFixed(0), lon: ((lng.toFixed(5))*100000).toFixed(0) });
        //               //
        //               // }
        //               if(dst >= 50) {
        //                 // console.log('distance', dst);
        //                 // console.log('angle', ang);
        //                 // console.log('angle', record_before_second);
        //                 track_points.push({ lat: lat, lng: lng, dst: dst, ang: ang});
        //                 track_points_sygic.push({ lat: parseInt((lat.toFixed(5))*100000).toFixed(0), lon: parseInt((lng.toFixed(5))*100000).toFixed(0) });
        //               }
        //             }
        //           } else {
        //             track_points.push({ lat: lat, lng: lng });
        //             // track_points_sygic.push({ lat: ((lat.toFixed(5))*100000).toFixed(0), lon: ((lng.toFixed(5))*100000).toFixed(0) });
        //
        //           }
        //         }
        //       }
        //   }
        //     tracks.push(track_points);
        //     tracks_sygic.push({lat: waypoints[i+1].latitude, lon: waypoints[i+1].longitude, points: track_points_sygic});
        //   }
        //   console.log('--------------TRACKS');
        //   console.log(tracks_sygic);
        //   console.log('--------------TRACKS');
        //   this.setState({
        //     track_here: tracks
        //   });
        // })
        // .catch((error) => {
        //   // handle your errors here
        //   console.error(error)
        // })




        // fetch('http://141.94.222.182:5000/order.sif')
        // .then(response => response.json())
        // .then((jsonData) => {
        //   let sygicPoints = jsonData.routeParts[0].roads[0].points;
        //   console.log('--------------TRACKS');
        //   console.log(jsonData.routeParts[0].roads[0].points);
        //   console.log('--------------TRACKS');


        //   var tracks = [];
        //   var track_points = [];

        //   for (let i = 0; i < sygicPoints.length; i++) {
        //     let lat = sygicPoints[i].lat/100000;
        //     let lng = sygicPoints[i].lon/100000;
        //     track_points.push({ lat: lat, lng: lng });

        //   }
        //   tracks.push(track_points);
        //   console.log(tracks);
        //   this.setState({
        //     track_here: tracks
        //   });

        // })
        // .catch((error) => {
        //   // handle your errors here
        //   console.error(error)
        // })








        fetch(api_ssl+'://'+api_url+'/tk?device_id='+device_id+'&current_track_start='+current_track_start+'&current_track_end='+current_track_end)
          .then(response => response.json())
          .then((jsonData) => {
            // jsonData is parsed json object received from url
            let datajson = jsonData;
            if(datajson.type === 'devices_add') {
              this.setState({modal: false});
            } else if(datajson.type === 'tracks_list') {
              this.setState({ positions: [], trackMarkers: [] });
              var tracks_to_send = [];
              var distance_total = 0;
              let h = 0;
              if(datajson.data[0]) {
                let firstTrack = JSON.parse(JSON.parse(JSON.stringify(datajson.data[0])));
                let firstPoint = JSON.parse(JSON.parse(JSON.stringify(firstTrack.samples)))[0];
                let firstTrackFirst = (new Date(firstTrack.first.$date)).getTime();
                let firstTrackLast = (new Date(firstTrack.last.$date)).getTime();

                const firstMarker = [{id:0, licenseplate: firstTrack.deviceId, lat: firstPoint.coordinates[0], lng: firstPoint.coordinates[1], timestamp: firstPoint.timestamp_rec.$date, first: firstTrackFirst, last: firstTrackLast }];

                this.setState({
                  trackMarkers: this.state.trackMarkers.concat(firstMarker)
                });
              }

              console.log('datajson.data', datajson.data);
              for (let j = 0; j < datajson.data.length; j++) {
                const par = JSON.parse(JSON.parse(JSON.stringify(datajson.data[j])));
                const nsamples = par.nsamples;
                const type = par.type;
                const first = (new Date(par.first.$date)).getTime();
                const last = (new Date(par.last.$date)).getTime();
                const duration = durationFormated((last-first)/1000);
                const track_points = par.samples;
                const track_points_parsed = JSON.parse(JSON.parse(JSON.stringify(track_points)));
                // if(nsamples >= 2 && type === 'break') {
                if(type === 'break') {
                  const lated = track_points_parsed[0].coordinates[0];
                  const lnged = track_points_parsed[0].coordinates[1];
                  const tmstp = track_points_parsed[0].timestamp_rec.$date;
                  const boost = [{id:j+1, licenseplate: par.deviceId, lat: lated, lng: lnged, timestamp: tmstp, first: first, last: last }];
                  this.setState({
                    trackMarkers: this.state.trackMarkers.concat(boost)
                  });
                }
                var track_complete = [];
                var blat = [];
                var blng = [];

                function toTimestamp(strDate){
                   var datum = Date.parse(strDate);
                   return datum/1000;
                }
                let odometer_list = [];
                for (let i = 0; i < track_points_parsed.length; i++) {
                  const lated = track_points_parsed[i].coordinates[0];
                  const lnged = track_points_parsed[i].coordinates[1];
                  let tmstp = track_points_parsed[i].timestamp_rec.$date;
                  if(typeof tmstp !== 'number' ){
                    tmstp = toTimestamp(tmstp);
                  }
                  const odotr = parseInt(track_points_parsed[i].odometer);
                  if(odotr === 0 || odotr === null) {
                  } else {
                    odometer_list.push(odotr);
                  }
                  track_complete.push({ lat: lated, lng: lnged, odometer: odotr, timestamp: tmstp });
                  blat.push(lated);
                  blng.push(lnged);
                }
                const latMin = Math.min.apply(null, blat);
                const latMax = Math.max.apply(null, blat);
                const lngMin = Math.min.apply(null, blng);
                const lngMax = Math.max.apply(null, blng);
                track_complete = track_complete.sort((a, b) => a.timestamp - b.timestamp);
                // odometer_list = odometer_list.sort();
                odometer_list.sort(function(a, b) {
                    return a - b;
                });
                let odoFirst = odometer_list.slice(0)[0];
                let odoLast = odometer_list.slice(-1)[0];
                // console.log('odoFirst', odoFirst, 'odoLast', odoLast);
                let distance =  (odoLast-odoFirst)/1000;
                if (distance <= -1 || odoFirst === undefined || odoLast === undefined) {
                  distance = 0;
                }
                // console.log('distance', distance);

                distance_total+=distance;
                if(par.deviceId==='rgs001' || par.deviceId==='rgs002' || par.deviceId==='rgs003' || par.deviceId==='rgs004' || par.deviceId==='rgs005') {
                  if(nsamples >= 1 && type === 'track') {
                    // console.log('distance', distance);
                    var dada = {
                      index: h,
                      datefirst: first,
                      datelast: last,
                      odoFirst: odoFirst,
                      odoLast: odoLast,
                      distance: Math.round(distance * 100) / 100,
                      duration: duration,
                      nsamples: nsamples,
                      points: track_complete,
                      bounds: [
                        [latMin, lngMin],
                        [latMax, lngMax]
                      ],
                      type: type,
                      trackOptions: {color: '#5573df', weight: 6}
                    };
                    h++;
                    tracks_to_send.push(dada);
                  }
                } else {
                  if(nsamples >= 1 && type === 'track' && distance > 1) {
                    // console.log('distance', distance);
                    var dada = {
                      index: h,
                      datefirst: first,
                      datelast: last,
                      odoFirst: odoFirst,
                      odoLast: odoLast,
                      distance: Math.round(distance * 100) / 100,
                      duration: duration,
                      nsamples: nsamples,
                      points: track_complete,
                      bounds: [
                        [latMin, lngMin],
                        [latMax, lngMax]
                      ],
                      type: type,
                      trackOptions: {color: '#5573df', weight: 6}
                    };
                    h++;
                    tracks_to_send.push(dada);
                  }
                }
              }
              // console.log('distance_total',distance_total);
              this.setState({
                tracks: tracks_to_send
              });
              console.log('tracks',tracks_to_send);
              this.props.tracksList([{
                tracks_list: tracks_to_send,
                total_tracks_distance: (distance_total).toFixed(2)
              }]);
            }
          })
          .catch((error) => {
            // handle your errors here
            console.error(error)
          })

      }
    }
    if( newProps.currentIndex !== this.props.currentIndex ) {
      // console.log('Track change!!!!!', newProps.currentIndex);
      let c = newProps.currentIndex;
      this.setState({currentIndex: c});
    }
    if( newProps.currentDevice !== this.props.currentDevice ) {
      this.setState({currentDevice: newProps.currentDevice});
      let c = newProps.currentIndex;
      this.setState({currentIndex: null});
      this.setState({tracks: [], trackMarkers: []});
    }

  }

  componentDidMount() {
    // if (this.state.ws === null || this.state.ws.readyState !== 1) {
    //   this.connect();
    // }
    // this.timeout = setInterval(() => {
    //   if (this.state.ws.readyState === 1) {
    //     let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    //     let at = '';
    //     let oi = '';
    //     if(auth_get) {
    //       at = auth_get.access_token;
    //       oi = auth_get.organization_id;
    //     }
    //     let msg_to_server = JSON.stringify({"type":"users_list", "at":at, "organization_id":oi});
    //     this.state.ws.send(msg_to_server);
    //   }
    // }, 10000)
  }

  componentWillUnmount() {
    // clearInterval(this.timeout);
    // this.state.ws.close();
    // this.setState({ ws: [] });
  }

  handleTrackDetails = (event) => {
    console.log('Polyline clicked');
    console.log(event);
  };

  render() {
    var current = this.state.currentIndex;
    var tracksToShow = [];
    const filteredNumbers = this.state.tracks.map((track, i) => {
      let act = 0;
      if (this.state.currentIndex===track.index) {
        act = 1;
      }
      var dada = {
        index: track.index,
        active: act,
        datefirst: track.first,
        datelast: track.last,
        distance: track.distance,
        duration: track.duration,
        nsamples: track.nsamples,
        points: track.points,
        bounds: track.bounds,
        type: track.type,
        trackOptions: track.trackOptions
      };
      tracksToShow.push(dada);
    });
    const arrow = [
      {
        offset: "100%",
        repeat: 0,
        symbol: L.Symbol.arrowHead({
          pixelSize: 15,
          polygon: false,
          pathOptions: { stroke: true }
        })
      }
    ];
    return (
      <div>
      {this.state.track_here.map((track, i) => (
        <Polyline
          className={'active_track_here'}
          zIndexOffset="10"
          pathOptions={{color: '#8097ff', weight: 4}}
          key={i}
          positions={track}
          />
      ))}


      <PolylineRemover currentIndex={this.state.currentIndex} />

      {
        this.state.trackMarkers.map((marker, i) => (
          <MarkerTrack
            key={i}
            id={i}
            latitude={marker.lat}
            longitude={marker.lng}
            first={marker.first}
            last={marker.last}
          >
          </MarkerTrack>
        ))
      }
      {tracksToShow.map((track, i) => (
        track.active===1
          ? (
            <div key={i} className='ffsssssssssssssssssssssssssss'>
            <Pane name="yellow-rectangle" style={{ zIndex: 499 }}>
            <Pane name={'track'+track.index+'_1'} key={i} style={{ zIndex: 499 }}>
                <PolylineDecorator key={i} patterns={arrow} polyline={track.points} />
            </Pane>
            </Pane>
            </div>
          )
          : (
            <div key={i} className='daaaaaaaaaaaaaaaaaaaaaaaa'>
            <Pane name={'track'+track.index+'_0'} key={i} style={{ zIndex: 488 }}>
              <Polyline
                className={'active'+track.active}
                zIndexOffset="10"
                pathOptions={{color: '#8097ff', weight: 4}}
                key={i}
                positions={track.points}
                onClick={this.handleTrackDetails}
                />
            </Pane>
            </div>
          )
      ))}
      </div>
    )
  }
}
