import React, { useEffect } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {
  MapContainer,
  TileLayer,
  MapConsumer,
  useMap
} from 'react-leaflet';
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import L, { MarkerCluster } from 'leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import CustomMarker from "./libs/CustomMarker";
// import AirplaneMarker from "./libs/AirplaneMarker";
import Moment from 'react-moment';
import 'moment-timezone';
import {MapDevice} from "./DisplayOpenStreetMapDetails";
import {MapTrack} from "./openStreetTracks";
import { RiFileListLine } from "react-icons/ri";
import { RiAlbumLine } from "react-icons/ri";
import { RiMap2Line } from "react-icons/ri";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { AiOutlineDashboard } from "react-icons/ai";
import { GiCartwheel } from "react-icons/gi";
import { GiSattelite } from "react-icons/gi";
import { RiBattery2ChargeLine } from "react-icons/ri";
import { ToastContainer, toast } from 'react-toastify';
// import CountryCodes from './data/CountryCodes.json';
import { countryCodes } from "./data/CountryCodes";
import 'react-toastify/dist/ReactToastify.css';
// const Leaflet = window.L;
const api_name = process.env.REACT_APP_API_URL;
const api_ssl = process.env.REACT_APP_API_SSL;
const api_ws = process.env.REACT_APP_API_WS;
const domainName =  window.location.hostname;
var api_url = domainName+api_name;
switch (domainName) {
  case '141.94.222.182':
    api_url = domainName+':5000'+api_name;
    break;
  default:
}

// NOTE: iconCreateFunction is running by leaflet, which is not support ES6 arrow func syntax
// eslint-disable-next-line
const createClusterCustomIcon = function (cluster: MarkerCluster) {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: 'custom-marker-cluster',
    iconSize: L.point(33, 33, true),
  })
}

function UpdateMapCentre(props) {
  const map = useMap();
  if(props.lastActionTimestamp.prev !== props.lastActionTimestamp.action) {
    props.handleLastActionTimeChange('upd');
    // const lat = props.mapCentre.lat;
    // const lng = props.mapCentre.lng;
    // const zoom = 12;
    const b = props.bounds;
    map.fitBounds(b, {padding: [20, 20]})
  }
  return null;
}


export class DisplayOpenStreetMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bounds: [
        [50.505, -29.09],
        [52.505, 29.09]
      ],
      currentVehiclesAccess: [],
      newCords: {lat: 49.7200, lng: 13.2183},
      currentDevice: [],
      data: [],
      markers: [52.5309825, 13.3845921],
      currentTrack: [],
      currentIndex: null,
      lastActionTimestamp: {
        prev: Date.now(),
        action: Date.now()
      },
      preloader: 'preloader-show',
      firstrun: 1,
      tracksList: [],
      ws: [],
      panelVisible: true,
      sateliteVisible: false,
      organization_id: ''
    };
    this.handleShowDetails = this.handleShowDetails.bind(this);
    this.handleShowVehicle = this.handleShowVehicle.bind(this);
    this.handleCloseDetails = this.handleCloseDetails.bind(this);
    this.handleCurrentTrack = this.handleCurrentTrack.bind(this);
    this.handleTrackShow = this.handleTrackShow.bind(this);
    this.handleLastActionTimeChange = this.handleLastActionTimeChange.bind(this);
    this.switchMapPanel = this.switchMapPanel.bind(this);
    this.handleShowSatelite = this.handleShowSatelite.bind(this);
    this.sendToWs = this.sendToWs.bind(this);
    this.sendToToast = this.sendToToast.bind(this);
    this.connect = this.connect.bind(this);
  }


  componentDidMount() {
    if (this.state.ws === null || this.state.ws.readyState !== 1) {
      this.connect();
    }

    this.timeout = setInterval(() => {
      if (this.state.ws.readyState === 1) {
        let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
        let at = '';
        if(auth_get) {
          at = auth_get.access_token;
        }
        let msg_to_server = JSON.stringify({"type":"devices_list", "at":at, "organization_id":auth_get.organization_id});
        this.state.ws.send(msg_to_server);
      }
    }, 10000)

  }

  componentWillUnmount() {
    clearInterval(this.timeout);
    this.state.ws.close();
    this.setState({ ws: [] });
  }


  // static getDerivedStateFromProps(nextProps, prevState){
  //     console.log('orgId',prevState.currentVehiclesAccess);
  //     console.log('orgId',nextProps.currentVehiclesAccess);
  //     if (nextProps.currentVehiclesAccess !== prevState.currentVehiclesAccess) {
  //               return { currentVehiclesAccess: nextProps.currentVehiclesAccess };
  //     }
  //     else return null;
  // }




  componentWillReceiveProps (newProps) {
    // console.log(newProps);
    if( newProps.currentVehiclesAccess !== this.props.currentVehiclesAccess ) {
      this.setState({currentVehiclesAccess: newProps.currentVehiclesAccess});
    }
    if( newProps.orgId !== this.props.orgId ) {
      this.setState({organization_id:  newProps.orgId})
      let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
      let at = '';
      let oi = '';
      if(auth_get) {
        at = auth_get.access_token;
        oi = auth_get.organization_id;
      }
      let msg_to_server = JSON.stringify({"type":"devices_list", "at":at, "organization_id":oi});
      this.state.ws.send(msg_to_server);
    }
  }



  connect = () => {
    var ws = new W3CWebSocket(api_ws+'://'+api_url+'/devices');
    let that = this; // cache the this


    let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    let at = '';
    let oi = '';
    if(auth_get) {
      at = auth_get.access_token;
      oi = auth_get.organization_id;
    }
    let msg_to_server = JSON.stringify({"type":"devices_list", "at":at, "organization_id":oi});

    ws.onopen = () => {
      that.setState({ ws: ws });
      ws.send(msg_to_server);
    };

    ws.onmessage = (message) => {
      let datajson = JSON.parse(message.data);
      if(datajson.type === 'devices_add') {
        this.setState({modal: false});
      } else if(datajson.type === 'devices_list') {
        const data = datajson.data;
        const marke = [];
        var blat = [];
        var blng = [];
        for (let i = 0; i < data.length; i++) {
          const lated = parseFloat(data[i].lastrecord.lat);
          const lnged = parseFloat(data[i].lastrecord.lng);
          let countryCode = 0;
          let countryShort = 'nn';
          const operatorCode = String(data[i].lastrecord.operatorCode);
          const boost = [lated, lnged];
          marke.push(boost);
          this.setState({
            markers: this.state.markers.concat(boost)
          });
          blat.push(lated);
          blng.push(lnged);

          if(operatorCode.length > 2) {
            countryCode = operatorCode.substring(0, 3);
            countryShort = countryCodes[countryCodes.findIndex(e => e.code === countryCode)].short;
            if(countryShort !== null || countryShort !== undefined || countryShort !== 'null') {
              data[i].countryShort = countryShort;
            }
          }
        }
        that.setState({data: data});

        if (that.state.firstrun === 1) {
          const latMin = Math.min.apply(null, blat);
          const latMax = Math.max.apply(null, blat);
          const lngMin = Math.min.apply(null, blng);
          const lngMax = Math.max.apply(null, blng);
          const newBounds = [
            [latMin, lngMin],
            [latMax, lngMax]
          ];
          const newTimestamp = {
            prev: this.state.lastActionTimestamp.prev,
            action: Date.now()
          }
          that.setState({
            bounds: newBounds,
            lastActionTimestamp: newTimestamp,
            preloader: 'preloader-hide',
            firstrun: 0,
            organization_id: oi
          });
        }
      }
    };

    ws.onclose = (e) => {
      that.setState({ ws: [] });
      setTimeout(function () {
        that.connect();
      }, 10000);
    };

    ws.onerror = (err) => {
      console.error(err.message);
      ws.close();
    };
  };


  handleShowDetails = (device, event) => {
    // const map = useMap();
    // console.log(map);

    this.setState({
      currentTrack: [],
      closeDetails: true,
      currentDevice: device,
    });
  };


  handleShowSatelite(e) {
    var d = document.getElementById("map-type-control");
    if(d.classList.contains('satelite')){
      this.setState({sateliteVisible: false});
    } else {
      this.setState({sateliteVisible: true});
    }
  }
  switchMapPanel(e) {
    var d = document.getElementById("panel-left-map");
    if(d.classList.contains('opened')){
      this.setState({panelVisible: false});
    } else {
      this.setState({panelVisible: true});
    }
  };

  handleShowVehicle = (device, event) => {
    const latMin = parseFloat(device.lastrecord.lat)-0.2;
    const lngMin = parseFloat(device.lastrecord.lng)-0.2;
    const latMax = parseFloat(device.lastrecord.lat)+0.2;
    const lngMax = parseFloat(device.lastrecord.lng)+0.2;
    const newBounds = [
      [latMin, lngMin],
      [latMax, lngMax]
    ];
    const newTimestamp = {
      prev: this.state.lastActionTimestamp.prev,
      action: Date.now()
    }
    this.setState({
      bounds: newBounds,
      lastActionTimestamp: newTimestamp,
      currentDevice: device,
    });
  };
  handleCloseDetails = (childData) => {
    this.setState({
      closeDetails: false
    });
  };
  handleCurrentTrack = (childData) => {
    this.setState({
      currentTrack: childData
    });
  };

  handleTracksList = (childData) => {
    this.setState({
      tracksList: childData
    });
  };

  handleTrackShow = (childData) => {
    const latMin = childData.points.slice(-1)[0].lat;
    const lngMin = childData.points.slice(-1)[0].lng;
    // const latMax = childData.points[0].lat;
    // const lngMax = childData.points[0].lng;
    const newBounds = [
      childData.bounds
    ];
    this.setState({
      currentIndex: childData.index
    });


    const newTimestamp = {
      prev: this.state.lastActionTimestamp.prev,
      action: Date.now()
    }

    this.setState({
      newCords: {lat: latMin, lng: lngMin},
      bounds: newBounds,
      lastActionTimestamp: newTimestamp
    });
  };

  handleLastActionTimeChange = (childData) => {
    const nt = {
      prev: Date.now(),
      action: Date.now(),
    };
    this.setState({
      lastActionTimestamp: nt
    });
  };

  sendToWs = (childData) => {
    this.props.sendToWs(childData);
  };

  sendToToast = (childData) => {
    this.props.sendToToast(childData);
  };


  render() {
    const bounds= this.state.bounds;
    let oid = this.state.organization_id;
    let show_sat_control = false;
    if (oid===1 || oid===2|| oid===3) {
      show_sat_control = true;
    }
    // console.log('Org ok?', oid, show_sat_control);
    return (
      <div className={'content-wrapper map '+this.state.preloader}>
        <div id="panel-left-map" className={`panel left ${this.state.panelVisible === true? 'opened': ''}`}>
          <ul className="vehicles-list">
            {
              this.state.data.map((device, i) => (
                <li className={this.state.currentDevice.id === device.id? 'vehicle active': 'vehicle'} key={device.id}>
                  <div className="left">
                    <div className="top-line">
                      <div className="licenseplate">
                        {device.licenseplate}
                        <div className={device.countryShort === undefined? 'country-short-disabled': 'country-short'}>{device.countryShort}</div>
                      </div>
                      <div className="codename">{device.name} / {device.codename}</div>
                    </div>
                    <ul className="middle-line text-color">
                      <li><span className="label"><AiOutlineThunderbolt /></span>{device.lastrecord.ignition ? 'Wł' : 'Wył'}</li>
                      <li><span className="label"><GiCartwheel /></span>{device.lastrecord.movement ? 'Wł' : 'Wył'}</li>
                      <li><span className="label"><AiOutlineDashboard /></span>{(device.lastrecord.gpsSpeed*1).toFixed(0)}<span className="unit"> km/h</span></li>
                      <li><span className="label"><RiBattery2ChargeLine /></span>{(device.lastrecord.voltage/1000).toFixed(2)}<span className="unit"> V</span></li>
                    </ul>
                    <div className="bottom-line text-color">
                      <div><span className="label">Ost. Rek: </span>
                      <Moment format="DD.MM.YYYY HH:mm:ss">
                        {new Date(parseInt(device.lastrecord.record_timestamp))}
                      </Moment>
                      </div>
                    </div>
                  </div>
                  <div className="details">
                    <div className="details-button" onClick={() => this.handleShowDetails(device, i)}>
                      <RiFileListLine />
                    </div>
                    <div className="show-vehicle-on-map-button" onClick={() => this.handleShowVehicle(device, i)}>
                      <RiAlbumLine />
                    </div>
                  </div>
                </li>
              ))
            }
          </ul>
        </div>
        <div className={`panel details ${this.state.closeDetails === true? 'opened': ''} ${this.state.panelVisible === true? '': 'mobile-map-view'}`}>
          <div className="inner">
            <MapDevice
            organizationid={this.state.organization_id}
            currentVehiclesAccess={this.state.currentVehiclesAccess}
            vehiclesList={this.state.data}
            currentDevice={this.state.currentDevice}
            tracksList={this.state.tracksList}
            sendToWs={this.sendToWs}
            sendToToast={this.sendToToast}
            curTrack={this.handleCurrentTrack}
            trackShow={this.handleTrackShow}
            parentCallback={this.handleCloseDetails} />
          </div>
        </div>

        <MapContainer scrollWheelZoom={true} bounds={bounds}>

          <UpdateMapCentre mapCentre={this.state.newCords} bounds={this.state.bounds} handleLastActionTimeChange={this.handleLastActionTimeChange} lastActionTimestamp={this.state.lastActionTimestamp}/>
          {show_sat_control==true ? (
            <div id="map-type-control" onClick={() => this.handleShowSatelite()} className={`map-type-control ${this.state.sateliteVisible === true? 'satelite': ''}`}>
              <GiSattelite />
            </div>
          ) : (
              <div className="dwsds">
              </div>
          )}
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MapConsumer>
            {(map) => {
              // console.log("map center:", map.getCenter());
              // map.on("click", function (e) {
              //   // console.log(e);
              //   const { lat, lng } = e.latlng;
              //   L.marker([lat, lng], { icon }).addTo(map);
              //   // console.log('ddddddddddd', lat, lng);
              //
              // });
              return null;
            }}
          </MapConsumer>

          <MapTrack currentDevice={this.state.currentDevice} currentTrack={this.state.currentTrack} currentIndex={this.state.currentIndex} tracksList={this.handleTracksList} />

          <MarkerClusterGroup
            chunkedLoading
            iconCreateFunction={createClusterCustomIcon}
            maxClusterRadius={60}
            removeOutsideVisibleBounds={true}
            polygonOptions={{
              fill: '#ffffff',
              color: '#5573df',
              weight: 2,
              opacity: 0.6,
              fillOpacity: 0.1,
            }}
          >
          {
            this.state.data.map((device, i) => (


            <CustomMarker
              key={i}
              id={i}
              latitude={device.lastrecord.lat}
              longitude={device.lastrecord.lng}
              heading={device.lastrecord.gpsHeading}
              licenseplate={device.licenseplate}
              ignition={device.lastrecord.ignition}
              movement={device.lastrecord.movement}
              speed={device.lastrecord.gpsSpeed}
            >
            </CustomMarker>

            ))
          }
          </MarkerClusterGroup>
          {this.state.sateliteVisible==true ? (
            <ReactLeafletGoogleLayer apiKey={'AIzaSyCubNZ2rpkRVorWfXmihnWZxyJY_tftXo0'} type={'roadmap'}/>
            ) : (
              <div className="fdasffd">
              </div>
          )}

        </MapContainer>
          <div className={`map-switch ${this.state.panelVisible === true? 'opened': ''}`} onClick={this.switchMapPanel}>
            <div>
            <div>
              <RiMap2Line />
            </div>
            </div>
          </div>
      </div>

    )
  }
}
