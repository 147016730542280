import React, { useState } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { SelectPicker } from 'rsuite';
import { setCurrentOrganization } from './actions/Organization';
import store from './store';

const api_name = process.env.REACT_APP_API_URL;
const api_ssl = process.env.REACT_APP_API_SSL;
const api_ws = process.env.REACT_APP_API_WS;
const domainName =  window.location.hostname;
var api_url = domainName+api_name;
switch (domainName) {
  case '141.94.222.182':
    api_url = domainName+':5000'+api_name;
    break;
  default:
}

export class SelectOrg extends React.Component {
  constructor(props) {
  super(props);
      this.state = {
        data: [],
        selectedOrg: JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY')).organization_id,
        // ws: null
      };
      this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {

  }

  static getDerivedStateFromProps(props, state) {
    // console.log('props update!!!!', props);
    if(props.organizationsList != state.organizationsList) {
      let olist = [];
      let data = props.organizationsList;
      for (var i = 0; i < data.length; i++) {
        const l = data[i].name;
        const v = data[i].id;
        olist.push({ label: l, value: v });
      }
      return {
        data: olist
      }
    }
    // console.log('props update!!!!', state);
    return null;
  }

  handleChange(event) {
    const value = event
    this.props.parentCallback(value);
    this.setState({selectedOrg: value});
    const profile = {
      access_token: JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY')).access_token,
      organization_id: value,
      user_id:  JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY')).user_id,
      roles: this.props.currentUserRoles
    };
    console.log('Params get!')
    store.dispatch(setCurrentOrganization(value));
    localStorage.setItem("REACT_TOKEN_AUTH_KEY", JSON.stringify(profile))
    this.props.sendToWs(['vehicle_access_select', 'list', 'reload']);
    
  }

  render() {
    return (
      <div className="select-org">
          <SelectPicker
            size="lg"
            cleanable={false}
            value={this.state.selectedOrg}
            data={this.state.data}
            onChange={this.handleChange}
            locale={{
              placeholder: 'Wybierz',
              searchPlaceholder: 'Szukaj',
              noResultsText: 'Brak wyników'
            }}
          />
      </div>
    );
  }
}
