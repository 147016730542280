import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// import reportWebVitals from './reportWebVitals';
// import store from './app/store'
// import { Provider } from 'react-redux'
// require('dotenv').config()
// console.log('dotenv',process.env)

// ReactDOM.render(
//   <Provider>
//     <App />
//   </Provider>,
//   document.getElementById('root')
// )

//
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


