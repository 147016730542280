import React from "react";
// import { DateRangePicker } from 'react-date-range';
import { subDays } from "date-fns";
// import { addDays, subDays } from "date-fns";
// import { pl } from 'date-fns/locale'
// import PropTypes from 'prop-types';
// import Moment from 'react-moment';
// import 'moment-timezone';
import { DateRangePicker } from 'rsuite';

export class CalendarModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarVisibility: false,
      calMaxDays: this.props.calMaxDays,
      rgns: [{
        startDate: subDays(new Date(), 1),
        endDate: new Date(),
        valul: [subDays(new Date(), 1), new Date()],
        key: 'selection'
      }],
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.noKeybord = this.noKeybord.bind(this);
  }


  noKeybord = (e) => {
    console.log(e);
    // e.preventDefault();
    // return false;
  }
  handleSelect(ranges){
    console.log(ranges[0])
    console.log(ranges[1])
      this.props.calRanges([{
        rgns: [{
          startDate: new Date(ranges[0]),
          endDate: new Date(ranges[1]),
          key: 'selection'
        }]
      }]);
    }


  render(){
    const { combine, allowedMaxDays, afterToday } = DateRangePicker;
    // const readOnlyOnFocus = (e) => {
    //    console.log(e.target.readOnly=true)
    // }
    return (
      <div className={`cst-range-picker`}>
        <DateRangePicker
          showOneCalendar
          cleanable={false}
          defaultValue={[subDays(new Date(), 1), new Date()]}
          disabledDate={combine(allowedMaxDays(this.state.calMaxDays), afterToday())}
          onChange={this.handleSelect}
          onEnter={this.noKeybord}
          editable={false}
          locale={{
            sunday: "Nie",
            monday: "Pon",
            tuesday: "Wt",
            wednesday: "Śr",
            thursday: "Czw",
            friday: "Pi",
            saturday: "Sob",
            ok: "OK",
            today: "Dziś",
            yesterday: "Wczoraj",
            last7Days: "Ost. 7 dni",
            formattedMonthPattern:'MM, yyyy',
            formattedDayPattern: 'dd MM, yyyy'
          }}
        />
    </div>
    )
  }
}
