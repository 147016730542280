export const countryCodes = [
  {
  code: "289",
  country: "Abkhazia",
  short: "GE-AB"
  },
  {
  code: "412",
  country: "Afghanistan",
  short: "AF"
  },
  {
  code: "276",
  country: "Albania",
  short: "AL"
  },
  {
  code: "603",
  country: "Algeria",
  short: "DZ"
  },
  {
  code: "544",
  country: "American Samoa (United States of America)",
  short: "AS"
  },
  {
  code: "213",
  country: "Andorra",
  short: "AD"
  },
  {
  code: "631",
  country: "Angola",
  short: "AO"
  },
  {
  code: "365",
  country: "Anguilla (United Kingdom)",
  short: "AI"
  },
  {
  code: "344",
  country: "Antigua and Barbuda",
  short: "AG"
  },
  {
  code: "722",
  country: "Argentina",
  short: "AR"
  },
  {
  code: "283",
  country: "Armenia",
  short: "AM"
  },
  {
  code: "363",
  country: "Aruba",
  short: "AW"
  },
  {
  code: "505",
  country: "Australia",
  short: "AU"
  },
  {
  code: "232",
  country: "Austria",
  short: "AT"
  },
  {
  code: "400",
  country: "Azerbaijan",
  short: "AZ"
  },
  {
  code: "364",
  country: "Bahamas",
  short: "BS"
  },
  {
  code: "426",
  country: "Bahrain",
  short: "BH"
  },
  {
  code: "470",
  country: "Bangladesh",
  short: "BD"
  },
  {
  code: "342",
  country: "Barbados",
  short: "BB"
  },
  {
  code: "257",
  country: "Belarus",
  short: "BY"
  },
  {
  code: "206",
  country: "Belgium",
  short: "BE"
  },
  {
  code: "702",
  country: "Belize",
  short: "BZ"
  },
  {
  code: "616",
  country: "Benin",
  short: "BJ"
  },
  {
  code: "350",
  country: "Bermuda",
  short: "BM"
  },
  {
  code: "402",
  country: "Bhutan",
  short: "BT"
  },
  {
  code: "736",
  country: "Bolivia",
  short: "BO"
  },
  {
  code: "362",
  country: "Bonaire, Sint Eustatius and Saba",
  short: "BQ"
  },
  {
  code: "218",
  country: "Bosnia and Herzegovina",
  short: "BA"
  },
  {
  code: "652",
  country: "Botswana",
  short: "BW"
  },
  {
  code: "724",
  country: "Brazil",
  short: "BR"
  },
  {
  code: "995",
  country: "British Indian Ocean Territory (United Kingdom)",
  short: "IO"
  },
  {
  code: "348",
  country: "British Virgin Islands (United Kingdom)",
  short: "VG"
  },
  {
  code: "528",
  country: "Brunei",
  short: "BN"
  },
  {
  code: "284",
  country: "Bulgaria",
  short: "BG"
  },
  {
  code: "613",
  country: "Burkina Faso",
  short: "BF"
  },
  {
  code: "642",
  country: "Burundi",
  short: "BI"
  },
  {
  code: "456",
  country: "Cambodia",
  short: "KH"
  },
  {
  code: "624",
  country: "Cameroon",
  short: "CM"
  },
  {
  code: "302",
  country: "Canada",
  short: "CA"
  },
  {
  code: "625",
  country: "Cape Verde",
  short: "CV"
  },
  {
  code: "346",
  country: "Cayman Islands (United Kingdom)",
  short: "KY"
  },
  {
  code: "623",
  country: "Central African Republic",
  short: "CF"
  },
  {
  code: "622",
  country: "Chad",
  short: "TD"
  },
  {
  code: "730",
  country: "Chile",
  short: "CL"
  },
  {
  code: "460",
  country: "China",
  short: "CN"
  },
  {
  code: "461",
  country: "China",
  short: "CN"
  },
  {
  code: "732",
  country: "Colombia",
  short: "CO"
  },
  {
  code: "654",
  country: "Comoros",
  short: "KM"
  },
  {
  code: "629",
  country: "Congo",
  short: "CG"
  },
  {
  code: "548",
  country: "Cook Islands (Pacific Ocean)",
  short: "CK"
  },
  {
  code: "712",
  country: "Costa Rica",
  short: "CR"
  },
  {
  code: "219",
  country: "Croatia",
  short: "HR"
  },
  {
  code: "368",
  country: "Cuba",
  short: "CU"
  },
  {
  code: "362",
  country: "Cura�ao",
  short: "CW"
  },
  {
  code: "280",
  country: "Cyprus",
  short: "CY"
  },
  {
  code: "230",
  country: "Czech Republic",
  short: "CZ"
  },
  {
  code: "630",
  country: "Democratic Republic of the Congo",
  short: "CD"
  },
  {
  code: "238",
  country: "Denmark (Kingdom of Denmark)",
  short: "DK"
  },
  {
  code: "638",
  country: "Djibouti",
  short: "DJ"
  },
  {
  code: "366",
  country: "Dominica",
  short: "DM"
  },
  {
  code: "370",
  country: "Dominican Republic",
  short: "DO"
  },
  {
  code: "514",
  country: "East Timor",
  short: "TL"
  },
  {
  code: "740",
  country: "Ecuador",
  short: "EC"
  },
  {
  code: "602",
  country: "Egypt",
  short: "EG"
  },
  {
  code: "706",
  country: "El Salvador",
  short: "SV"
  },
  {
  code: "627",
  country: "Equatorial Guinea",
  short: "GQ"
  },
  {
  code: "657",
  country: "Eritrea",
  short: "ER"
  },
  {
  code: "248",
  country: "Estonia",
  short: "EE"
  },
  {
  code: "636",
  country: "Ethiopia",
  short: "ET"
  },
  {
  code: "750",
  country: "Falkland Islands (United Kingdom)",
  short: "FK"
  },
  {
  code: "288",
  country: "Faroe Islands (Kingdom of Denmark)",
  short: "FO"
  },
  {
  code: "542",
  country: "Fiji",
  short: "FJ"
  },
  {
  code: "244",
  country: "Finland",
  short: "FI"
  },
  {
  code: "208",
  country: "France",
  short: "FR"
  },
  {
  code: "742",
  country: "French Guiana (France)",
  short: "GF"
  },
  {
  code: "647",
  country: "French Indian Ocean Territories (France)",
  short: "RE"
  },
  {
  code: "547",
  country: "French Polynesia (France)",
  short: "PF"
  },
  {
  code: "628",
  country: "Gabon",
  short: "GA"
  },
  {
  code: "607",
  country: "Gambia",
  short: "GM"
  },
  {
  code: "282",
  country: "Georgia",
  short: "GE"
  },
  {
  code: "262",
  country: "Germany",
  short: "DE"
  },
  {
  code: "620",
  country: "Ghana",
  short: "GH"
  },
  {
  code: "266",
  country: "Gibraltar (United Kingdom)",
  short: "GI"
  },
  {
  code: "202",
  country: "Greece",
  short: "GR"
  },
  {
  code: "290",
  country: "Greenland (Kingdom of Denmark)",
  short: "GL"
  },
  {
  code: "352",
  country: "Grenada",
  short: "GD"
  },
  {
  code: "340",
  country: "Guadeloupe (France)",
  short: "GP"
  },
  {
  code: "310",
  country: "Guam (United States of America)",
  short: "GU"
  },
  {
  code: "311",
  country: "Guam (United States of America)",
  short: "GU"
  },
  {
  code: "704",
  country: "Guatemala",
  short: "GT"
  },
  {
  code: "234",
  country: "Guernsey (United Kingdom)",
  short: "GG"
  },
  {
  code: "611",
  country: "Guinea",
  short: "GN"
  },
  {
  code: "632",
  country: "Guinea-Bissau",
  short: "GW"
  },
  {
  code: "738",
  country: "Guyana",
  short: "GY"
  },
  {
  code: "372",
  country: "Haiti",
  short: "HT"
  },
  {
  code: "708",
  country: "Honduras",
  short: "HN"
  },
  {
  code: "454",
  country: "Hong Kong (People�s Republic of China)",
  short: "HK"
  },
  {
  code: "216",
  country: "Hungary",
  short: "HU"
  },
  {
  code: "274",
  country: "Iceland",
  short: "IS"
  },
  {
  code: "404",
  country: "India",
  short: "IN"
  },
  {
  code: "405",
  country: "India",
  short: "IN"
  },
  {
  code: "406",
  country: "India",
  short: "IN"
  },
  {
  code: "510",
  country: "Indonesia",
  short: "ID"
  },
  {
  code: "432",
  country: "Iran",
  short: "IR"
  },
  {
  code: "418",
  country: "Iraq",
  short: "IQ"
  },
  {
  code: "272",
  country: "Ireland",
  short: "IE"
  },
  {
  code: "234",
  country: "Isle of Man (United Kingdom)",
  short: "IM"
  },
  {
  code: "425",
  country: "Israel",
  short: "IL"
  },
  {
  code: "222",
  country: "Italy",
  short: "IT"
  },
  {
  code: "612",
  country: "Ivory Coast",
  short: "CI"
  },
  {
  code: "338",
  country: "Jamaica",
  short: "JM"
  },
  {
  code: "440",
  country: "Japan",
  short: "JP"
  },
  {
  code: "441",
  country: "Japan",
  short: "JP"
  },
  {
  code: "234",
  country: "Jersey (United Kingdom)",
  short: "JE"
  },
  {
  code: "416",
  country: "Jordan",
  short: "JO"
  },
  {
  code: "401",
  country: "Kazakhstan",
  short: "KZ"
  },
  {
  code: "639",
  country: "Kenya",
  short: "KE"
  },
  {
  code: "545",
  country: "Kiribati",
  short: "KI"
  },
  {
  code: "467",
  country: "Korea, North",
  short: "KP"
  },
  {
  code: "450",
  country: "Korea, South",
  short: "KR"
  },
  {
  code: "221",
  country: "Kosovo",
  short: "XK"
  },
  {
  code: "419",
  country: "Kuwait",
  short: "KW"
  },
  {
  code: "437",
  country: "Kyrgyzstan",
  short: "KG"
  },
  {
  code: "457",
  country: "Laos",
  short: "LA"
  },
  {
  code: "247",
  country: "Latvia",
  short: "LV"
  },
  {
  code: "415",
  country: "Lebanon",
  short: "LB"
  },
  {
  code: "651",
  country: "Lesotho",
  short: "LS"
  },
  {
  code: "618",
  country: "Liberia",
  short: "LR"
  },
  {
  code: "606",
  country: "Libya",
  short: "LY"
  },
  {
  code: "295",
  country: "Liechtenstein",
  short: "LI"
  },
  {
  code: "246",
  country: "Lithuania",
  short: "LT"
  },
  {
  code: "270",
  country: "Luxembourg",
  short: "LU"
  },
  {
  code: "455",
  country: "Macau (People�s Republic of China)",
  short: "MO"
  },
  {
  code: "294",
  country: "North Macedonia",
  short: "MK"
  },
  {
  code: "646",
  country: "Madagascar",
  short: "MG"
  },
  {
  code: "650",
  country: "Malawi",
  short: "MW"
  },
  {
  code: "502",
  country: "Malaysia",
  short: "MY"
  },
  {
  code: "472",
  country: "Maldives",
  short: "MV"
  },
  {
  code: "610",
  country: "Mali",
  short: "ML"
  },
  {
  code: "278",
  country: "Malta",
  short: "MT"
  },
  {
  code: "551",
  country: "Marshall Islands",
  short: "MH"
  },
  {
  code: "340",
  country: "Martinique (France)",
  short: "MQ"
  },
  {
  code: "609",
  country: "Mauritania",
  short: "MR"
  },
  {
  code: "617",
  country: "Mauritius",
  short: "MU"
  },
  {
  code: "334",
  country: "Mexico",
  short: "MX"
  },
  {
  code: "550",
  country: "Micronesia, Federated States of",
  short: "FM"
  },
  {
  code: "259",
  country: "Moldova",
  short: "MD"
  },
  {
  code: "212",
  country: "Monaco",
  short: "MC"
  },
  {
  code: "428",
  country: "Mongolia",
  short: "MN"
  },
  {
  code: "297",
  country: "Montenegro",
  short: "ME"
  },
  {
  code: "354",
  country: "Montserrat (United Kingdom)",
  short: "MS"
  },
  {
  code: "604",
  country: "Morocco",
  short: "MA"
  },
  {
  code: "643",
  country: "Mozambique",
  short: "MZ"
  },
  {
  code: "414",
  country: "Myanmar",
  short: "MM"
  },
  {
  code: "649",
  country: "Namibia",
  short: "NA"
  },
  {
  code: "536",
  country: "Nauru",
  short: "NR"
  },
  {
  code: "429",
  country: "Nepal",
  short: "NP"
  },
  {
  code: "204",
  country: "Netherlands (Kingdom of the Netherlands)",
  short: "NL"
  },
  {
  code: "546",
  country: "New Caledonia",
  short: "NC"
  },
  {
  code: "530",
  country: "New Zealand",
  short: "NZ"
  },
  {
  code: "710",
  country: "Nicaragua",
  short: "NI"
  },
  {
  code: "614",
  country: "Niger",
  short: "NE"
  },
  {
  code: "621",
  country: "Nigeria",
  short: "NG"
  },
  {
  code: "555",
  country: "Niue",
  short: "NU"
  },
  {
  code: "505",
  country: "Norfolk Island",
  short: "NF"
  },
  {
  code: "310",
  country: "Northern Mariana Islands (United States of America)",
  short: "MP"
  },
  {
  code: "242",
  country: "Norway",
  short: "NO"
  },
  {
  code: "422",
  country: "Oman",
  short: "OM"
  },
  {
  code: "410",
  country: "Pakistan",
  short: "PK"
  },
  {
  code: "552",
  country: "Palau",
  short: "PW"
  },
  {
  code: "425",
  country: "Palestine",
  short: "PS"
  },
  {
  code: "714",
  country: "Panama",
  short: "PA"
  },
  {
  code: "537",
  country: "Papua New Guinea",
  short: "PG"
  },
  {
  code: "744",
  country: "Paraguay",
  short: "PY"
  },
  {
  code: "716",
  country: "Peru",
  short: "PE"
  },
  {
  code: "515",
  country: "Philippines",
  short: "PH"
  },
  {
  code: "260",
  country: "Poland",
  short: "PL"
  },
  {
  code: "268",
  country: "Portugal",
  short: "PT"
  },
  {
  code: "330",
  country: "Puerto Rico (United States of America)",
  short: "PR"
  },
  {
  code: "427",
  country: "Qatar",
  short: "QA"
  },
  {
  code: "226",
  country: "Romania",
  short: "RO"
  },
  {
  code: "250",
  country: "Russian Federation",
  short: "RU"
  },
  {
  code: "635",
  country: "Rwanda",
  short: "RW"
  },
  {
  code: "340",
  country: "Saint Barth�lemy (France)",
  short: "BL"
  },
  {
  code: "658",
  country: "Saint Helena, Ascension and Tristan da Cunha",
  short: "SH"
  },
  {
  code: "356",
  country: "Saint Kitts and Nevis",
  short: "KN"
  },
  {
  code: "358",
  country: "Saint Lucia",
  short: "LC"
  },
  {
  code: "340",
  country: "Saint Martin (France)",
  short: "MF"
  },
  {
  code: "308",
  country: "Saint Pierre and Miquelon",
  short: "PM"
  },
  {
  code: "360",
  country: "Saint Vincent and the Grenadines",
  short: "VC"
  },
  {
  code: "549",
  country: "Samoa",
  short: "WS"
  },
  {
  code: "292",
  country: "San Marino",
  short: "SM"
  },
  {
  code: "626",
  country: "S�o Tom� and Pr�ncipe",
  short: "ST"
  },
  {
  code: "420",
  country: "Saudi Arabia",
  short: "SA"
  },
  {
  code: "608",
  country: "Senegal",
  short: "SN"
  },
  {
  code: "220",
  country: "Serbia",
  short: "RS"
  },
  {
  code: "633",
  country: "Seychelles",
  short: "SC"
  },
  {
  code: "619",
  country: "Sierra Leone",
  short: "SL"
  },
  {
  code: "525",
  country: "Singapore",
  short: "SG"
  },
  {
  code: "362",
  country: "Sint Maarten",
  short: "SX"
  },
  {
  code: "231",
  country: "Slovakia",
  short: "SK"
  },
  {
  code: "293",
  country: "Slovenia",
  short: "SI"
  },
  {
  code: "540",
  country: "Solomon Islands",
  short: "SB"
  },
  {
  code: "637",
  country: "Somalia",
  short: "SO"
  },
  {
  code: "655",
  country: "South Africa",
  short: "ZA"
  },
  {
  code: "659",
  country: "South Sudan",
  short: "SS"
  },
  {
  code: "214",
  country: "Spain",
  short: "ES"
  },
  {
  code: "413",
  country: "Sri Lanka",
  short: "LK"
  },
  {
  code: "634",
  country: "Sudan",
  short: "SD"
  },
  {
  code: "746",
  country: "Suriname",
  short: "SR"
  },
  {
  code: "653",
  country: "Swaziland",
  short: "SZ"
  },
  {
  code: "240",
  country: "Sweden",
  short: "SE"
  },
  {
  code: "228",
  country: "Switzerland",
  short: "CH"
  },
  {
  code: "417",
  country: "Syria",
  short: "SY"
  },
  {
  code: "466",
  country: "Taiwan",
  short: "TW"
  },
  {
  code: "436",
  country: "Tajikistan",
  short: "TJ"
  },
  {
  code: "640",
  country: "Tanzania",
  short: "TZ"
  },
  {
  code: "520",
  country: "Thailand",
  short: "TH"
  },
  {
  code: "615",
  country: "Togo",
  short: "TG"
  },
  {
  code: "554",
  country: "Tokelau",
  short: "TK"
  },
  {
  code: "539",
  country: "Tonga",
  short: "TO"
  },
  {
  code: "374",
  country: "Trinidad and Tobago",
  short: "TT"
  },
  {
  code: "605",
  country: "Tunisia",
  short: "TN"
  },
  {
  code: "286",
  country: "Turkey",
  short: "TR"
  },
  {
  code: "438",
  country: "Turkmenistan",
  short: "TM"
  },
  {
  code: "376",
  country: "Turks and Caicos Islands",
  short: "TC"
  },
  {
  code: "553",
  country: "Tuvalu",
  short: "TV"
  },
  {
  code: "641",
  country: "Uganda",
  short: "UG"
  },
  {
  code: "255",
  country: "Ukraine",
  short: "UA"
  },
  {
  code: "424",
  country: "United Arab Emirates",
  short: "AE"
  },
  {
  code: "430",
  country: "United Arab Emirates (Abu Dhabi)",
  short: "AE"
  },
  {
  code: "431",
  country: "United Arab Emirates (Dubai)",
  short: "AE"
  },
  {
  code: "234",
  country: "United Kingdom",
  short: "GB"
  },
  {
  code: "235",
  country: "United Kingdom",
  short: "GB"
  },
  {
  code: "310",
  country: "United States of America",
  short: "US"
  },
  {
  code: "311",
  country: "United States of America",
  short: "US"
  },
  {
  code: "312",
  country: "United States of America",
  short: "US"
  },
  {
  code: "313",
  country: "United States of America",
  short: "US"
  },
  {
  code: "314",
  country: "United States of America",
  short: "US"
  },
  {
  code: "315",
  country: "United States of America",
  short: "US"
  },
  {
  code: "316",
  country: "United States of America",
  short: "US"
  },
  {
  code: "332",
  country: "United States Virgin Islands",
  short: "VI"
  },
  {
  code: "748",
  country: "Uruguay",
  short: "UY"
  },
  {
  code: "434",
  country: "Uzbekistan",
  short: "UZ"
  },
  {
  code: "541",
  country: "Vanuatu",
  short: "VU"
  },
  {
  code: "734",
  country: "Venezuela",
  short: "VE"
  },
  {
  code: "452",
  country: "Vietnam",
  short: "VN"
  },
  {
  code: "543",
  country: "Wallis and Futuna",
  short: "WF"
  },
  {
  code: "421",
  country: "Yemen",
  short: "YE"
  },
  {
  code: "645",
  country: "Zambia",
  short: "ZM"
  },
  {
  code: "648",
  country: "Zimbabwe",
  short: "ZW"
  }
];
