import React from "react";
import { Tab, Tabs as TabsComponent, TabList, TabPanel } from "react-tabs";
import { Toggle } from 'rsuite';

export class Organization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOrg: [
        {
            "id": "",
            "name": "",
            "codename": "",
            "company_name": "",
            "vat": "",
            "street": "",
            "postcode": "",
            "city": "",
            "country": "",
            "func_border_crossing": "",
            "func_driver_identify": "",
            "func_etoll": ""
        }
      ],
      id: '',
      name: '',
      codename: '',
      company_name: '',
      vat: '',
      street: '',
      postcode: '',
      city: '',
      country: '',
      func_border_crossing: false,
      func_driver_identify: false,
      func_etoll: false,
      goBack: ''
    };
    this.handleCloseDetails = this.handleCloseDetails.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.handleDriverIdentifyChange = this.handleDriverIdentifyChange.bind(this);
    this.handleEtollChange = this.handleEtollChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOrganizationEditSubmit = this.handleOrganizationEditSubmit.bind(this);
    this.handleOrganizationFunctionsSubmit = this.handleOrganizationFunctionsSubmit.bind(this);
  }

  componentWillReceiveProps (newProps) {
    console.log('newProps.selectedOrg.func_border_crossing', newProps.selectedOrg.func_border_crossing)
    if( newProps.selectedOrg !== this.props.selectedOrg ) {
      this.setState({selectedOrg:  newProps.selectedOrg})
      this.setState({
        id: newProps.selectedOrg.id,
        name: newProps.selectedOrg.name,
        codename: newProps.selectedOrg.codename,
        company_name: newProps.selectedOrg.company_name,
        vat: newProps.selectedOrg.vat,
        street: newProps.selectedOrg.street,
        postcode: newProps.selectedOrg.postcode,
        city: newProps.selectedOrg.city,
        country: newProps.selectedOrg.country,
        func_border_crossing: newProps.selectedOrg.func_border_crossing,
        func_driver_identify: newProps.selectedOrg.func_driver_identify,
        func_etoll: newProps.selectedOrg.func_etoll,
      });
    }
  }

  handleCloseDetails = () => {
    this.props.goBack('back');
  };

  handleInputChange(event) {
    console.log('Tadam!!!');
    const target = event.target;
    // const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const value = target.value;

    this.setState({
      [name]: value
    });
  };

  handleOrganizationEditSubmit(event) {
    event.preventDefault();
    this.props.editOrg([{
        form_type: 0,
        id: this.state.id,
        name: this.state.name,
        codename: this.state.codename,
        company_name: this.state.company_name,
        vat: this.state.vat,
        street: this.state.street,
        postcode: this.state.postcode,
        city: this.state.city,
        country: this.state.country
    }]);
  };

  handleOrganizationFunctionsSubmit(event) {
    console.log('func_border_crossing', this.state.func_border_crossing)
    event.preventDefault();
    this.props.editOrg([{
        form_type: 1,
        id: this.state.id,
        func_border_crossing: this.state.func_border_crossing,
        func_driver_identify: this.state.func_driver_identify,
        func_etoll: this.state.func_etoll
    }]);
  };


  handleSwitchChange(e) {
    this.setState({ func_border_crossing:e });
  }
  handleDriverIdentifyChange(e) {
    this.setState({ func_driver_identify:e });
  }
  handleEtollChange(e) {
    console.log('func etoll var', e)
    this.setState({ func_etoll:e });
  }



  render(){
    // const selectedOrg = this.props.selectedOrg;
    const tabsHeaders = [
      {
        heading: "Edycja",
      },
      {
        heading: "Funkcje",
      }
    ];
    const tabsNumber =  'tabs-'+tabsHeaders.length;
    return (
      <div className="list-element-details">
        <div className="header">
          <div className="arrow-container">
            <span onClick={this.handleCloseDetails} className={`arrow ${this.state.navWidth === true? 'wide': ''}`}>
              <span></span>
              <span></span>
            </span>
          </div>
          <div className="name-container">
            <div className="name">{this.state.name}</div>
            <div className="codenamer">{this.state.codename}</div>
          </div>
        </div>

        <div className={tabsNumber}>
          <TabsComponent>
            <TabList>
              {tabsHeaders.map(({ heading }, i) => (
                <Tab key={i}>{heading}</Tab>
              ))}
            </TabList>
            <TabPanel>
              <div className="edit-object">
                <form onSubmit={this.handleOrganizationEditSubmit}>
                  <label>
                    Nazwa skrócona:
                    <input type="text" value={this.state.name} name="name" onChange={this.handleInputChange} />
                  </label>
                  <label>
                    Codename:
                    <input type="text" value={this.state.codename} name="codename" onChange={this.handleInputChange} />
                  </label>
                  <label>
                    Nazwa firmy:
                    <input type="text" value={this.state.company_name} name="company_name" onChange={this.handleInputChange} />
                  </label>
                  <label>
                    NIP:
                    <input type="text" value={this.state.vat} name="vat" onChange={this.handleInputChange} />
                  </label>
                  <label>
                    Ulica:
                    <input type="text" value={this.state.street} name="street" onChange={this.handleInputChange} />
                  </label>
                  <label>
                    Kod pocztowy:
                    <input type="text" value={this.state.postcode} name="postcode" onChange={this.handleInputChange} />
                  </label>
                  <label>
                    Miasto:
                    <input type="text" value={this.state.city} name="city" onChange={this.handleInputChange} />
                  </label>
                  <label>
                    Kraj:
                    <input type="text" value={this.state.country} name="country" onChange={this.handleInputChange} />
                  </label>
                  <input type="submit" value="Zapisz" />
                </form>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="edit-object functions">
                <form onSubmit={this.handleOrganizationFunctionsSubmit}>
                    <div className="element">
                        <div className="label">
                            Raport przekraczania granic
                        </div>
                        <div className="switch">
                            <Toggle onChange={this.handleSwitchChange} checked={this.state.func_border_crossing} />
                        </div>
                    </div>
                    <div className="element">
                        <div className="label">
                            Status E-toll
                        </div>
                        <div className="switch">
                            <Toggle onChange={this.handleEtollChange} checked={this.state.func_etoll} />
                        </div>
                    </div>
                    <div className="element">
                        <div className="label">
                            Identyfikacja kierowców
                        </div>
                        <div className="switch">
                            <Toggle onChange={this.handleDriverIdentifyChange} checked={this.state.func_driver_identify} />
                        </div>
                    </div>
                  <input type="submit" value="Zapisz" />
                </form>
                </div>
            </TabPanel>
          </TabsComponent>
        </div>
      </div>
    )
  }
}
