import React from "react";
import {
  Link
} from "react-router-dom";
import { IconButton, Input, SelectPicker } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';
import axios from 'axios';
import { addDays, subDays } from "date-fns";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from '../../WithRouter';
import Moment from 'react-moment';
import 'moment-timezone';

// import { listEtollStatus } from '../../actions/IntegrationsEtollStatus';
import {QrScanner} from '@yudiel/react-qr-scanner';






class DisplayDriverAssist extends React.Component {
  constructor(props) {
  super(props);
      this.state = {
        vehiclesList: [],
        integrationsEtoll: [],
        text: '',
        stopDecoding: false
      };
  }

  componentDidMount() {
    let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    let at = '';
    let oi = '';
    if(auth_get) {
      at = auth_get.access_token;
      oi = auth_get.organization_id;
      this.setState({
        organization_id: oi,
        stopDecoding: false
      });
    }
    const params = {
      organization_id: oi,
    }
    // this.props.listEtollStatus(params, this.props.history);

    // this.timeout = setInterval(() => {
    //   let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    //   let at = '';
    //   let oi = '';
    //   if(auth_get) {
    //     at = auth_get.access_token;
    //     oi = auth_get.organization_id;
    //   }
    //   const params = {
    //     organization_id: oi,
    //   }
    //   this.props.listEtollStatus(params, this.props.history);
    // }, 10000)
  }

  componentWillUnmount() {
    this.setState({stopDecoding: true});
  }

  componentDidUpdate(prevProps) {
    // if(prevProps.integrationsEtoll.list.data !== this.props.integrationsEtoll.list.data) {
    //   this.setState({integrationsEtoll: this.props.integrationsEtoll.list.data});
    // }
    // if(prevProps.orgId !== this.props.orgId ) {
    //   this.setState({organizationId:  this.props.orgId})
    //   const params = {
    //     organization_id: this.props.orgId,
    //   }
    //   this.props.listEtollStatus(params, this.props.history);
    // }
  }

  render() {
    // let iet = this.state.integrationsEtoll;
    // iet.forEach(function(obj){
    //   let sts = obj.value.sendToEtollTimestamp;
    //   let cts = Date.now();
    //   let tdiff = (cts-sts)/1000;
    //   let ign = obj.value.ignition;
    //   let mov = obj.value.movement;
    //   let sendstat = false;
    //   if(ign === 1 && mov === 1) {
    //     if(tdiff <= 120){
    //       sendstat = true;
    //     }
    //   } else {
    //     if(tdiff <= 900){
    //       sendstat = true;
    //     }
    //   }
    //   obj.dst = sendstat;
    // });
    // let that = this;
    return (
      <div className="content-wrapper driver-assist">
        <div className="text-container">
            {this.state.text}
        </div>
        <div className="camera-container">
            <QrScanner
                onDecode={(result) => {
                    console.log(result);
                    this.setState({text: result});
                }
                }
                onError={(error) => console.log(error?.message)}
                stopDecoding={this.state.stopDecoding}
            />
        </div>
      </div>
    );
  }
}

// DisplayDriverAssist.propTypes = {
//     listEtollStatus: PropTypes.func.isRequired,
// };

const mapStateToProps = state => ({
    errors: state.errors
});

export default connect(mapStateToProps,{})(withRouter(DisplayDriverAssist))
