import React from 'react';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import Moment from 'react-moment';
import 'moment-timezone';

const api_name = process.env.REACT_APP_API_URL;
const api_ssl = process.env.REACT_APP_API_SSL;
const api_ws = process.env.REACT_APP_API_WS;
const domainName =  window.location.hostname;
var api_url = domainName+api_name;
switch (domainName) {
  case '141.94.222.182':
    api_url = domainName+':5000'+api_name;
    break;
  default:
}





function vehicleStatus(i,m) {
  if (i===1 && m===1) {
    return 'Jazda';
  } else {
    return 'Postój'
  }
}

function timeFormated(t) {
  // console.log(t);
  var timestamp = new Date(parseInt(t));
  var tsp_da = ("0" + timestamp.getDate()).slice(-2);
  var tsp_mo = ("0" + timestamp.getMonth()).slice(-2);
  var tsp_ye = timestamp.getFullYear();
  var tsp_ho = ("0" + timestamp.getHours()).slice(-2);
  var tsp_mi = ("0" + timestamp.getMinutes()).slice(-2);
  var tsp_se = ("0" + timestamp.getSeconds()).slice(-2);
  // var currentTimezone = timestamp.getTimezoneOffset();
  // currentTimezone = (currentTimezone/60) * -1;
  // var gmt = 'GMT';
  // if (currentTimezone !== 0) {
  //   gmt += currentTimezone > 0 ? ' +' : ' ';
  //   gmt += currentTimezone;
  // }
  var dateFormat = tsp_da+'.'+tsp_mo+'.'+tsp_ye+' '+tsp_ho+':'+tsp_mi+':'+tsp_se;
  // var dateFormat = tsp_da+'.'+tsp_mo+'.'+tsp_ye+' '+tsp_ho+':'+tsp_mi+':'+tsp_se+' ['+gmt+']';
  return dateFormat;
}



const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, value }) => {


  const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN) * 1.2;
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="#535353" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {value}
    </text>
  );
};



export class DisplayDashboardSocket extends React.Component {
  constructor(props) {
  super(props);
      this.state = {
        data: [],
        ws: [],
        organization_id: '',
        data01: [],
        data02: []
      };
  }

  timeout = 250; // Initial timeout duration as a class variable
  componentDidMount() {
    console.log('Websocket opening');
    var ws = new W3CWebSocket(api_ws+'://'+api_url+'/dashboard');
    this.setState({
      ws: ws
    });
    this.connect(ws);
  }
  //
  componentWillUnmount() {
    this.state.ws.close();
  }

  componentWillReceiveProps (newProps) {
    if( newProps.orgId !== this.props.orgId ) {
      this.setState({organization_id:  newProps.orgId})
      let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
      let at = '';
      let oi = '';
      if(auth_get) {
        at = auth_get.access_token;
        oi = auth_get.organization_id;
      }
      let msg_to_server = JSON.stringify({"type":"dashboard_devices_list", "at":at, "organization_id":oi});
      this.send(msg_to_server);
    }
  }


  connect = (ws) => {
      let that = this;
      var connectInterval;


      let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
      let at = '';
      let oi = '';
      if(auth_get) {
        at = auth_get.access_token;
        oi = auth_get.organization_id;
      }
      let msg_to_server = JSON.stringify({"type":"dashboard_devices_list", "at":at, "organization_id":oi});


      this.send = function (message, callback) {
        this.waitForConnection(function () {
            ws.send(message);
            if (typeof callback !== 'undefined') {
              callback();
            }
        }, 1000);
      };

      this.waitForConnection = function (callback, interval) {
        if (ws !== undefined) {
          if (ws.readyState === 1) {
              callback();
          } else {
              var that = this;
              setTimeout(function () {
                  that.waitForConnection(callback, interval);
              }, interval);
          }
        }
      };

      this.send(msg_to_server);

      setInterval(() => {
        let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
        let at = '';
        let oi = '';
        // console.log('auth_get',auth_get)
        if(auth_get) {
          at = auth_get.access_token;
          oi = auth_get.organization_id;
        }
        let msg_to_server = JSON.stringify({"type":"dashboard_devices_list", "at":at, "organization_id":oi});
        this.send(msg_to_server);
      }, 10000)
    if (ws !== undefined) {
      ws.onopen = () => {
          // console.log("Websocket devices: connected!");
          this.setState({ ws: ws });

          that.timeout = 250; // reset timer to 250 on open of websocket connection
          clearTimeout(connectInterval); // clear Interval on on open of websocket connection
      };


      ws.onmessage = (message) => {
         let datajson = JSON.parse(message.data);
         if(datajson.type === 'dashboard_devices_list') {
            const data = datajson.data;

            let data_drive = 0;
            let data_stop = 0;
            let dev_connected = 0;
            let dev_not_connected = 0;
            const ctime = Date.now();
            // console.log('ctime',ctime);

            for (let j = 0; j < data.length; j++) {
              let vehicle = data[j];
              let ignition = vehicle.lastrecord.ignition;
              let movement = vehicle.lastrecord.movement;
              let gpsSpeed = vehicle.lastrecord.gpsSpeed;
              let last_connection_time = vehicle.lastrecord.last_connection_time;
              let timeDiff = (ctime-last_connection_time)/1000;
              if(ignition === 1 && movement === 1 && gpsSpeed >= 5) {
                data_drive++
              } else {
                data_stop++
              }
              if(timeDiff >= 120) {
                dev_not_connected++
              } else {
                dev_connected++
              }
            }


            const data01 = [
              { name: 'Jazda', value: data_drive },
              { name: 'Postój', value: data_stop },
            ];
            const data02 = [
              { name: 'Połączenie aktywne', value: dev_connected },
              { name: 'Brak połączenia', value: dev_not_connected },
            ];

            this.setState({
              data: data,
              data01: data01,
              data02: data02
            });
          }

      };

      // websocket onclose event listener
      ws.onclose = e => {
          that.timeout = that.timeout + that.timeout; //increment retry interval
          connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
      };

      // websocket onerror event listener
      ws.onerror = err => {
          ws.close();
      };
    }
  };

  check = () => {
      const { ws } = this.state;
      if (!ws || ws.readyState === WebSocket.CLOSED) this.connect(ws); //check if websocket instance is closed, if so call `connect` function.
  };


  render() {
    // console.log(this.state.data);
    return (
      <div className="content-wrapper dashboard">
          <div className="panel dashboard_devices_status">
            <div>
              <h2>Status urządzeń</h2>
            </div>
            <div className="line header">
              <div className="licenseplate">Rejestracja</div>
              <div className="status">Status</div>
              <div className="speed">Prędkość</div>
              <div className="lastrecord">Ostatni rekord</div>
            </div>
            {this.state.data.map((device, index) => (
              <div key={index} className="line list text-color">
                <div className="licenseplate">{device.licenseplate}</div>
                <div className="status">{vehicleStatus(device.lastrecord.ignition, device.lastrecord.movement)}</div>
                <div className="speed">{(device.lastrecord.gpsSpeed*1).toFixed(0)} km/h</div>
                <div className="lastrecord">
                  <Moment format="DD.MM.YYYY HH:mm:ss">
                    {new Date(device.lastrecord.record_timestamp)}
                  </Moment>
                  </div>
              </div>
            ))}
          </div>
        <div className="panel dashboard_devices_connection_chart">
        <div>
          <h2>Połączenie GPRS</h2>
        </div>
          <div className="dashboard_devices_connection_pie">
            <ResponsiveContainer width="100%" height={300}>
            <PieChart width={800} height={400}>
                  <Pie
                    data={this.state.data02}
                    cx={120}
                    cy={200}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    label={renderCustomizedLabel}
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {this.state.data02.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="panel dashboard_devices_status_chart">
        <div>
          <h2>Jazda / Postój</h2>
        </div>
          <div className="dashboard_devices_status_pie">
            <ResponsiveContainer width="100%" height={300}>
            <PieChart width={800} height={400}>
                  <Pie
                    data={this.state.data01}
                    cx={120}
                    cy={200}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    label={renderCustomizedLabel}
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {this.state.data01.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    );
  }
}
