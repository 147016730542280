import React from "react";
import {
  Link
} from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from '../../WithRouter';
import Moment from 'react-moment';
import { IconButton } from 'rsuite';
import { RiNotification4Line } from "react-icons/ri";
import PageNextIcon from '@rsuite/icons/PageNext';
import { latestNotifications, readNotification } from '../../actions/Notification';

const getLicenseplate = (vl, codename) => {
  const obj = vl.find(o => o.codename === codename);
  let r = codename;
  if(obj !== undefined) {
    r = obj['label'];
  }
  return r
}

const notificationTypesList = {
  'satelitesNone': 'Brak satelitów',
  'satelitesLow': 'Słaby sygnał GPS',
  'voltageLow': 'Niskie napięcie akumulatora',
  'voltageNone': 'Utracono zasilanie',
  'ignitionNone': 'Jazda z wyłączonym zapłonem'
};

class DisplayNotificationsLatest extends React.Component {
  constructor(props) {
  super(props);
      this.state = {
        notificationsList: [],
        notificationsLatestVisible: false,
        organizationId: '',
      };
      this.switchLatestNotifications = this.switchLatestNotifications.bind(this);
      this.handleShowAll = this.handleShowAll.bind(this);
  }

  componentDidMount() {
    // this.props.latestNotifications(this.state.organizationId, this.props.history);

  }

  componentDidUpdate(prevProps) {
    // console.log('this.props');
    // console.log(this.props);
    // console.log(this.props.latestNotifications.list);
      // if(this.props.latestNotifications.list.length !== 0) {
      if(prevProps.notificationsLatest.list.data !== this.props.notificationsLatest.list.data) {
        this.setState({notificationsList: this.props.notificationsLatest.list.data});
      }
      // }
      if(prevProps.orgId !== this.props.orgId ) {
        console.log('orgId change!!!!');
        this.setState({organizationId:  this.props.orgId})
        this.props.latestNotifications(this.props.orgId, this.props.history);
      }
  }


  switchLatestNotifications(event) {
    let a = document.getElementsByClassName('notifications-latest-list')[0].attributes.notvis.value;
    if (a==='closed') {
      this.setState({notificationsLatestVisible: true});
    } else if (a==='opened') {
      this.setState({notificationsLatestVisible: false});
    }
  };

  handleShowAll(event) {
    this.setState({notificationsLatestVisible: false});
  }

  handleMarkRead = (notification, event) => {
      const params = {
        _id: notification._id,
        organization_id: notification.organization_id,
        latest: true
      }
      this.props.readNotification(params, this.props.history);
  }

  render() {
    let list = this.state.notificationsList;
    let count_number = list.length;
    let countVisible = false;
    let count = count_number;
    if (count_number > 0) {
      countVisible = true
    }
    if (count_number === 11) {
      count = '10+'
    }
    return (
      <div className="notifications-latest">
        <div className="notifications-icon icon-element" onClick={this.switchLatestNotifications}>
          <RiNotification4Line />
          <div className={`counter ${countVisible === true? 'visible': ''}`}>
            {count}
          </div>
        </div>
        <div
          className={`notifications-latest-list ${this.state.notificationsLatestVisible === true? 'opened': 'closed'}`}
          notvis={this.state.notificationsLatestVisible === true? 'opened': 'closed'}>
          <h3>Nieodczytane powiadomienia</h3>



          {count_number>=1
              ? (
                <ul>
                {list.map((notification, i) => (
                    <li key={i} className='notification'>
                    <div className="left">
                      <div className="top-line">
                        <div className="notification=type">
                          {notificationTypesList[notification.notificationType]}
                        </div>
                      </div>
                      <div className="middle-line">
                        <div className="vehicle">
                          <div className="label">
                            Pojazd:
                          </div>
                          <div className="value">
                            <div className="codename">
                              {notification.codename}
                            </div>
                            <div className="slash">
                              /
                            </div>
                            <div className="licenseplate">
                              {getLicenseplate(this.props.vehicles.list, notification.codename)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="right">
                      <div className="read" onClick={() => this.handleMarkRead(notification, i)}>
                        Odczytaj
                      </div>
                    </div>
                  </li>
                ))}
                </ul>
              )
              : (
                <ul>
                  <li className="no-notifications">
                    Gratulacje! Wszystkie powiadomienia odczytane!
                  </li>
                </ul>
              )
          }









        <div className="bottom-line">
          <Link to="/notifications">
            <IconButton
              icon={<PageNextIcon />}
              appearance="primary"
              onClick={this.handleShowAll}
              className="new-button"
              size="md"
              placement="right">
            Zobacz wszystkie
            </IconButton>
          </Link>
        </div>
        </div>
      </div>
    );
  }
}

DisplayNotificationsLatest.propTypes = {
    latestNotifications: PropTypes.func.isRequired,
    readNotification: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    notificationsLatest: state.notificationsLatest,
    vehicles: state.vehicles,
    errors: state.errors
});

export default connect(mapStateToProps,{ latestNotifications, readNotification })(withRouter(DisplayNotificationsLatest))
