import React from "react";
import { addDays, subDays } from "date-fns";
import _ from 'lodash';
import Moment from 'react-moment';
import { InputPicker, CheckPicker, IconButton, Loader } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';

import { ResponsiveContainer, LineChart, Line, CartesianGrid, Tooltip, Legend, Brush, XAxis, YAxis } from 'recharts';
import {CalendarModal} from "../calendar_n";
const api_name = process.env.REACT_APP_API_URL;
const api_ssl = process.env.REACT_APP_API_SSL;
const api_ws = process.env.REACT_APP_API_WS;
const domainName =  window.location.hostname;
var api_url = domainName+api_name;
switch (domainName) {
  case '141.94.222.182':
    api_url = domainName+':5000'+api_name;
    break;
  default:
}

const toTimestamp = (strDate) => {
  const dt = Date.parse(strDate);
  return dt / 1000;
}

function durationFormated(t) {
    var sec_num = parseInt(t, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
}

const parametersContent = [
  { label: 'Całkowite zużycie paliwa', value: 'total_fuel_used' },
  { label: 'Ilość satelitów', value: 'satelites' },
  { label: 'Napięcie akumulatora', value: 'voltage' },
  { label: 'Obroty silnika', value: 'engine_rpm' },
  { label: 'Prędkość', value: 'speed' },
  { label: 'Poziom paliwa', value: 'fuel_lvl' },
  { label: 'Ruch', value: 'movement' },
  { label: 'Zapłon', value: 'ignition' },
  { label: 'Oś X', value: 'axis_x' },
  { label: 'Oś Y', value: 'axis_y' },
  { label: 'Oś Z', value: 'axis_z' }
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let timestamp = payload[0].payload.name;
    let odometer = payload[0].payload.odometer;
    // console.log('payload',payload);
    return (
      <div className="custom-tooltip">
        <div className="date-time">
          <Moment format="DD.MM.YYYY HH:mm:ss">
            {new Date(timestamp)}
          </Moment>
        </div>
        <div className="par">
          <div className="label">Przebieg:</div>
          <div className="value">{parseFloat(odometer/1000).toFixed(2)}</div>
        </div>
        {
        payload.map((parameter, i) => (
            <div key={parameter.name} className="par">
              <div className="color-dot" style={{background:parameter.color}}></div>
              <div className="label">{parameter.name}:</div>
              <div className="value">{parseFloat(parameter.value).toFixed(2)}</div>
            </div>
          ))
        }
      </div>
    );
  }

  return null;
};

// const api_url = process.env.REACT_APP_API_URL;

export class DisplayTemperatureSocket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarVisibility: false,
      calMaxDays: 3,
      chartreclist: [
      ],
      parameters: parametersContent,
      parametersDefault: ['voltage', 'satelites', 'fuel_lvl', 'total_fuel_used'],
      parametersColors: [
        '#8884d8',
        '#7ed6df',
        '#e056fd',
        '#ff7979',
        '#e056fd',
        '#30336b',
        '#95afc0',
        '#f9ca24'
      ],
      loader: false,
      data: [],
      currentVehicle: '',
      currentVehicleInitial: 0,
      current_track_start: subDays(new Date(), 1),
      current_track_end: addDays(new Date(), 1),
      devicesSelect: [],
      ws: null,
      orgId: '',
      startIndex: 0,
      endIndex: 400
    };
    this.calendarChange = this.calendarChange.bind(this);
    this.handleChartChange = this.handleChartChange.bind(this);
    this.handleVehicleChange = this.handleVehicleChange.bind(this);
    this.handleParametersChange = this.handleParametersChange.bind(this);
    this.handleGetData = this.handleGetData.bind(this);
  }

  // componentDidMount() {
  //
  // }

  handleChartChange(event) {
    console.log('handleChartChange START');
    this.setState({loader: true});
    let device_id = event[0];
    let current_track_start = toTimestamp(event[1]);
    let current_track_end = toTimestamp(event[2]);
    let parameters = event[3];
    console.log('parameters', parameters);

    fetch(api_ssl+'://'+api_url+'/tk?device_id='+device_id+'&current_track_start='+current_track_start+'&current_track_end='+current_track_end)
      .then(response => response.json())
      .then((jsonData) => {

        let datajson = jsonData;
        var tracks_to_send = [];
        var vssaaa = [];
        var distance_total = 0;
        let h = 0;
        if(datajson.data[0]) {
          let firstTrack = JSON.parse(JSON.parse(JSON.stringify(datajson.data[0])));
          let firstPoint = JSON.parse(JSON.parse(JSON.stringify(firstTrack.samples)))[0];
          let firstTrackFirst = (new Date(firstTrack.first.$date)).getTime();
          let firstTrackLast = (new Date(firstTrack.last.$date)).getTime();

          const firstMarker = [{id:0, licenseplate: firstTrack.deviceId, lat: firstPoint.coordinates[0], lng: firstPoint.coordinates[1], timestamp: firstPoint.timestamp_rec.$date, first: firstTrackFirst, last: firstTrackLast }];

          // this.setState({
          //   trackMarkers: this.state.trackMarkers.concat(firstMarker)
          // });
        }
        var last_fuel_level_value = null;
        var last_total_fuel_used_value = null;
        var k = 0;
        for (let j = 0; j < datajson.data.length; j++) {
          const par = JSON.parse(JSON.parse(JSON.stringify(datajson.data[j])));
          const nsamples = par.nsamples;
          const type = par.type;
          const first = (new Date(par.first.$date)).getTime();
          const last = (new Date(par.last.$date)).getTime();
          const duration = durationFormated((last-first)/1000);
          const track_points = par.samples;
          const track_points_parsed = JSON.parse(JSON.parse(JSON.stringify(track_points)));
          // console.log(track_points_parsed);
          if(nsamples >= 2 && type === 'break') {
            const lated = track_points_parsed[0].coordinates[0];
            const lnged = track_points_parsed[0].coordinates[1];
            const tmstp = track_points_parsed[0].timestamp_rec.$date;
            const boost = [{id:j+1, licenseplate: par.deviceId, lat: lated, lng: lnged, timestamp: tmstp, first: first, last: last }];
            // this.setState({
            //   trackMarkers: this.state.trackMarkers.concat(boost)
            // });
          }
          var track_complete = [];
          var blat = [];
          var blng = [];

          function toTimestamp(strDate){
             var datum = Date.parse(strDate);
             return datum/1000;
          }
          let odometer_list = [];
          for (let i = 0; i < track_points_parsed.length; i++) {



            const lated = track_points_parsed[i].coordinates[0];
            const lnged = track_points_parsed[i].coordinates[1];

            let tmstp = track_points_parsed[i].timestamp_rec.$date;
            var recordtochart = {name: tmstp};

            let fuel_lvl = track_points_parsed[i].fuel_lvl;
            if (fuel_lvl !== 0 && k > 0) {
              last_fuel_level_value = fuel_lvl;
            }
            if (fuel_lvl === 0 && k > 0) {
              fuel_lvl = last_fuel_level_value;
            }
            k++;

            let total_fuel_used = track_points_parsed[i].total_fuel_used;
            if (total_fuel_used !== 0 && k > 0) {
              last_total_fuel_used_value = total_fuel_used;
            }
            if (total_fuel_used === 0 && k > 0) {
              total_fuel_used = last_total_fuel_used_value;
            }
            k++;


            const sat = track_points_parsed[i].sat;
            const odometer = track_points_parsed[i].odometer;
            const speed = track_points_parsed[i].speed;
            const ignition = track_points_parsed[i].ignition;
            const movement = track_points_parsed[i].movement;
            const engine_rpm = track_points_parsed[i].engine_rpm;
            const volt = (track_points_parsed[i].volt)/1000;

            const axis_x = track_points_parsed[i].axis_x;
            const axis_y = track_points_parsed[i].axis_y;
            const axis_z = track_points_parsed[i].axis_z;

            for (let g = 0; g < parameters.length; g++) {
              let parameter = parameters[g];
              let value = 0;
              switch (parameters[g]) {
                case 'satelites':
                  value = sat;
                  recordtochart.satelites = value;
                  break;
                case 'fuel_lvl':
                  value = fuel_lvl;
                  recordtochart.fuel_lvl = value;
                  break;
                case 'odometer':
                  value = odometer;
                  recordtochart.odometer = value;
                  break;
                case 'speed':
                  value = speed;
                  recordtochart.speed = value;
                  break;
                case 'voltage':
                  value = volt;
                  recordtochart.voltage = value;
                  break;
                case 'ignition':
                  value = ignition;
                  recordtochart.ignition = value;
                  break;
                case 'total_fuel_used':
                  value = total_fuel_used;
                  recordtochart.total_fuel_used = value;
                  break;
                case 'engine_rpm':
                  value = engine_rpm;
                  recordtochart.engine_rpm = value;
                  break;
                case 'movement':
                  value = movement;
                  recordtochart.movement = value;
                  break;
                case 'axis_x':
                  value = axis_x;
                  recordtochart.axis_x = value;
                  break;
                case 'axis_y':
                  value = axis_y;
                  recordtochart.axis_y = value;
                  break;
                case 'axis_z':
                  value = axis_z;
                  recordtochart.axis_z = value;
                  break;
                
                default:
                  value = 0;
              }

            }
            recordtochart.odometer = odometer;
            // let recordtochart = {name: tmstp, uv: fuel_lvl, pv: sat, amt: volt};




            vssaaa.push(recordtochart);
            // this.setState({ chartreclist: [...this.state.chartreclist, recordtochart] })


            if(typeof tmstp !== 'number' ){
              tmstp = toTimestamp(tmstp);
            }
            const odotr = parseInt(track_points_parsed[i].odometer);
            if(odotr === 0 || odotr === null) {
            } else {
              odometer_list.push(odotr);
            }
            track_complete.push({ lat: lated, lng: lnged, odometer: odotr, timestamp: tmstp });
            blat.push(lated);
            blng.push(lnged);
          }
          const latMin = Math.min.apply(null, blat);
          const latMax = Math.max.apply(null, blat);
          const lngMin = Math.min.apply(null, blng);
          const lngMax = Math.max.apply(null, blng);
          track_complete = track_complete.sort((a, b) => a.timestamp - b.timestamp);
          odometer_list = odometer_list.sort();
          let odoFirst = odometer_list.slice(0)[0];
          let odoLast = odometer_list.slice(-1)[0];
          let distance =  (odoLast-odoFirst)/1000;
          if (distance <= -1) {
            distance = 0;
          }
          distance_total+=distance;
          if(nsamples >= 2 && type === 'track') {
            var dada = {
              index: h,
              datefirst: first,
              datelast: last,
              odoFirst: odoFirst,
              odoLast: odoLast,
              distance: Math.round(distance * 100) / 100,
              duration: duration,
              nsamples: nsamples,
              points: track_complete,
              bounds: [
                [latMin, lngMin],
                [latMax, lngMax]
              ],
              type: type,
              trackOptions: {color: '#5573df', weight: 6}
            };
            // console.log(dada);
            h++;
            tracks_to_send.push(dada);
          }
        }
        // console.log(vssaaa);
        this.setState({chartreclist: vssaaa});
        this.setState({loader: false});
        this.setState({endIndex: 400});
      })
      .catch((error) => {
        // handle your errors here
        console.error(error)
      })


  }



  static getDerivedStateFromProps(nextProps, prevState){
      // console.log('orgId',prevState.orgId);
      // console.log('orgId',nextProps.orgId);
      if (nextProps.devicesSelect !== prevState.devicesSelect) {
          const a = nextProps.devicesSelect;
          let b = [];
          let c = '';
          for (let i = 0; i < a.length; i++) {
            b.push({ label: a[i].label + ' / ' + a[i].codename, value: a[i].codename });
            if(i==0) {
              c = a[0].codename
            }
          }
          if (prevState.currentVehicleInitial === 0 && c !== undefined && c !== '') {
            return { devicesSelect: b, currentVehicle: c, currentVehicleInitial: 1 };
          } else {
            if (nextProps.orgId !== prevState.orgId) {
              // console.log('Change vehicle when org changed');
              // console.log('nextProps.orgId', nextProps.orgId);
              // console.log('prevState.orgId', prevState.orgId);
              return { devicesSelect: b, currentVehicle: c };
            } else {
              return { devicesSelect: b };
            }
          }
      }
      else return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const a = prevProps.devicesSelect;
    let b = [];
    for (let i = 0; i < a.length; i++) {
      b.push({ label: a[i].label + ' / ' + a[i].codename, value: a[i].codename });
    }
    if(!_.isEqual(b, this.state.devicesSelect) && this.state.devicesSelect.length > 0) {
      console.log('devicesSelect prev', b);
      console.log('devicesSelect this', this.state.devicesSelect);
      // this.handleChartChange(
      //   [
      //     this.state.currentVehicle,
      //     this.state.current_track_start,
      //     this.state.current_track_end,
      //     this.state.parametersDefault
      //   ]
      // );
    }
    if(!_.isEqual(prevProps.orgId, this.state.orgId)) {
    //   console.log('Change vehicle when org changed');
    //   console.log('prevProps.orgId',prevProps.orgId);
    //   console.log('this.state.orgId',this.state.orgId);
      this.setState({orgId: prevProps.orgId });
    }
  }

  handleVehicleChange(event) {
    console.log(this.state);
    this.setState({currentVehicle: event});
    // this.handleChartChange(
    //   [
    //     event,
    //     this.state.current_track_start,
    //     this.state.current_track_end,
    //     this.state.parametersDefault
    //   ]
    // );

  }
  handleParametersChange(event) {
    this.setState({parametersDefault: event});
    console.log(event);
    // this.handleChartChange(
    //   [
    //     this.state.currentVehicle,
    //     this.state.current_track_start,
    //     this.state.current_track_end,
    //     event
    //   ]
    // );
  }

  handleLegendClick(event) {
    console.log('Click');
    console.log(event);
  }
  handleGetData(event) {
    console.log('currentVehicle', this.state.currentVehicle);
    this.handleChartChange(
      [
        this.state.currentVehicle,
        this.state.current_track_start,
        this.state.current_track_end,
        this.state.parametersDefault
      ]
    );
  }

  calendarChange = (childData) => {
    this.setState({
      current_track_start: new Date(childData[0].rgns[0].startDate),
      current_track_end: new Date(childData[0].rgns[0].endDate)
    });
    // this.handleChartChange(
    //   [
    //     this.state.currentVehicle,
    //     new Date(childData[0].rgns[0].startDate),
    //     new Date(childData[0].rgns[0].endDate),
    //     this.state.parametersDefault
    //   ]
    // );
  };

  render() {
    // console.log('chartreclist', this.state.chartreclist);
    return (
      <div className="content-wrapper charts">
        <div className="content-head">
          <div className="selected-vehicle">
            <span className="label">
            Pojazd:
            </span>
            <InputPicker
              className="vehicle-picker"
              cleanable={false}
              value={this.state.currentVehicle}
              onChange={this.handleVehicleChange}
              data={this.state.devicesSelect} />
          </div>
          <div className="selected-parameters">
            <span className="label">
              Parametry:
            </span>
            <CheckPicker
              className="full-width"
              data={this.state.parameters}
              defaultValue={this.state.parametersDefault}
              onChange={this.handleParametersChange}
              locale={{
                placeholder: 'Wybierz',
                searchPlaceholder: 'Szukaj',
                noResultsText: 'Brak wyników'
              }}
            />
          </div>
          <div className="visible-date" onClick={this.showCalendar} calvis={this.state.calendarVisibility === true? 'opened': 'closed'}>
            <span className="label">
            Daty:
            </span>
            <CalendarModal  calMaxDays={this.state.calMaxDays} calRanges={this.calendarChange} calendarVisibility={this.state.calendarVisibility}/>
          </div>
          <div className="button-search">
            <IconButton
              appearance="primary"
              type="submit"
              className="new-button"
              onClick={this.handleGetData}
              icon={<PlusIcon />}>
                Pokaż dane
            </IconButton>
          </div>
        </div>
        <div className="content-left">
          <div className={`loader-container ${this.state.loader === true? 'loading': ''}`}>
            <Loader size="md" />
          </div>
          <div className="container-chart">
            <ResponsiveContainer width="100%" height={300}>
              <LineChart onClick={this.handleLegendClick} data={this.state.chartreclist} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                {
                  this.state.parametersDefault.map((parameter, i) => (
                    <Line name={parametersContent[parametersContent.findIndex(e => e.value === parameter)].label} key={'line-'+i} type="monotone" dot={false} dataKey={parameter} stroke={this.state.parametersColors[i]} />
                  ))
                }
                <Brush startIndex={this.state.startIndex} endIndex={this.state.endIndex} />
                {/* <Brush /> */}
                <Tooltip content={<CustomTooltip />} />
                <Legend verticalAlign="top" />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <YAxis />
                <XAxis />
              </LineChart>
            </ResponsiveContainer>
          </div>
          {/* <div className="container-points">
          {
            this.state.chartreclist.map((parameter, i) => (
              <div className="params-row">
                <div className="param-id">{i}</div>
                <div className="param-date">{parameter.name}</div>
                {typeof parameter.satelites !=='undefined' ? (
                  <div className="param-satelites">{parameter.satelites}</div>
                ) : ('')}
                {typeof parameter.voltage !=='undefined' ? (
                  <div className="param-voltage">{parameter.voltage}</div>
                ) : ('')}
                {typeof parameter.odometer !=='undefined' ? (
                <div className="param-odometer">{parameter.odometer}</div>
                ) : ('')}
                {typeof parameter.total_fuel_used !=='undefined' ? (
                <div className="param-total-fuel-used">{parameter.total_fuel_used}</div>
                ) : ('')}
              </div>
            ))
          }
          </div> */}
        </div>
      </div>
    );
  }
}
