export const GET_ERRORS = 'GET_ERRORS';
export const GET_ETOLL_STATUS_LIST = 'GET_ETOLL_STATUS_LIST';
export const GET_VEHILCES_LIST = 'GET_VEHILCES_LIST';
export const GET_NOTIFICATIONS_LIST = 'GET_NOTIFICATIONS_LIST';
export const GET_NOTIFICATIONS_LATEST = 'GET_NOTIFICATIONS_LATEST';
export const GET_NOTIFICATIONS_RULES_LIST = 'GET_NOTIFICATIONS_RULES_LIST';
export const GET_SHARED_VEHILCES_LIST = 'GET_SHARED_VEHILCES_LIST';
export const SET_CURRENT_ORGANIZATION = 'SET_CURRENT_ORGANIZATION';
export const GET_ETOLL_SENT_STATUS_LIST = 'GET_ETOLL_SENT_STATUS_LIST';
export const GET_ETOLL_SENT_STATUS_SUMMARY = 'GET_ETOLL_SENT_STATUS_SUMMARY';
export const GET_SENTGEO_SENT_STATUS_LIST = 'GET_SENTGEO_SENT_STATUS_LIST';
export const GET_SENTGEO_SENT_STATUS_SUMMARY = 'GET_SENTGEO_SENT_STATUS_SUMMARY';