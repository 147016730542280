import React from "react";
import axios from 'axios';
import {
  MapContainer,
  TileLayer,
  MapConsumer,
  Marker,
  Popup,
  useMap
} from 'react-leaflet';
import L, { MarkerCluster } from 'leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import CustomMarker from "../../libs/CustomMarker";
import Moment from 'react-moment';
import 'moment-timezone';
import { AiOutlineThunderbolt } from "react-icons/ai";
import { AiOutlineDashboard } from "react-icons/ai";
import { GiCartwheel } from "react-icons/gi";
import { RiBattery2ChargeLine } from "react-icons/ri";
import { RiAlbumLine } from "react-icons/ri";
import { RiMap2Line } from "react-icons/ri";

const api_name = process.env.REACT_APP_API_URL;
const api_ssl = process.env.REACT_APP_API_SSL;
const domainName =  window.location.hostname;
var api_url = domainName+api_name;
switch (domainName) {
  case '141.94.222.182':
    api_url = domainName+':5000'+api_name;
    break;
  default:

}
const createClusterCustomIcon = function (cluster: MarkerCluster) {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: 'custom-marker-cluster',
    iconSize: L.point(33, 33, true),
  })
}
function UpdateMapCentre(props) {
  const map = useMap();
  if(props.lastActionTimestamp.prev !== props.lastActionTimestamp.action) {
    // console.log('mapCentre',props)
    props.handleLastActionTimeChange('upd');
    // const lat = props.mapCentre.lat;
    // const lng = props.mapCentre.lng;
    // const zoom = 12;
    const b = props.bounds;
    map.fitBounds(b, {padding: [20, 20]})
  }
  return null;
}

export class DisplayVehilceAccess extends React.Component {
  constructor(props) {
  super(props);
      this.state = {
        auth: '',
        bounds: [
          [50.505, -29.09],
          [52.505, 29.09]
        ],
        data: [],
        devices: [],
        firstrun: 1,
        panelVisible: true,
        code: '',
        newCords: {lat: 49.7200, lng: 13.2183},
        lastActionTimestamp: {
          prev: Date.now(),
          action: Date.now()
        },
      };
      this.handleLastActionTimeChange = this.handleLastActionTimeChange.bind(this);
      this.fetchApi = this.fetchApi.bind(this);
      this.handleShowVehicle = this.handleShowVehicle.bind(this);
      this.switchMapPanel = this.switchMapPanel.bind(this);

  }

  fetchApi() {
    let that = this;
    axios.get(api_ssl+'://'+api_url+'/vehilceaccess/map', {
      params: { code: this.props.match.params.code }
    })
    .then(function (response) {
      console.log(response.data.data);
      that.setState({
        data: response.data.data,
        devices: response.data.data.devices
      });
      let devices = response.data.data.devices;
      let blat = [];
      let blng = [];
      for (let i = 0; i < devices.length; i++) {
        const lated = parseFloat(devices[i].parameters[0].lat);
        const lnged = parseFloat(devices[i].parameters[0].lng);
        blat.push(lated);
        blng.push(lnged);
      }
      if (that.state.firstrun === 1) {
        const latMin = Math.min.apply(null, blat);
        const latMax = Math.max.apply(null, blat);
        const lngMin = Math.min.apply(null, blng);
        const lngMax = Math.max.apply(null, blng);
        const newBounds = [
          [latMin, lngMin],
          [latMax, lngMax]
        ];
        const newTimestamp = {
          prev: that.state.lastActionTimestamp.action,
          action: Date.now()
        }
        that.setState({
          bounds: newBounds,
          lastActionTimestamp: newTimestamp,
          preloader: 'preloader-hide',
          firstrun: 0
        });
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  componentDidMount() {
    let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    console.log(auth_get);
    let l = 'loggedout';
    if (auth_get) {
      l = 'loggedin';
    }
    this.setState({
      auth: l,
      code: this.props.match.params.code
    });
    this.fetchApi();

    this.timeout = setInterval(() => {
      this.fetchApi();
    }, 10000)
  }

  componentWillReceiveProps (newProps) {
    console.log(newProps);
    if( newProps.match.params.code !== this.props.match.params.code ) {
      this.setState({
        code: this.props.match.params.code
      });
    }
  }

  handleLastActionTimeChange = (childData) => {
    const nt = {
      prev: Date.now(),
      action: Date.now(),
    };
    this.setState({
      lastActionTimestamp: nt
    });
  };
  switchMapPanel(e) {
    var d = document.getElementById("panel-left-map");
    if(d.classList.contains('opened')){
      this.setState({panelVisible: false});
    } else {
      this.setState({panelVisible: true});
    }
  };
  handleShowVehicle = (device, event) => {
    const latMin = parseFloat(device.parameters[0].lat)-0.2;
    const lngMin = parseFloat(device.parameters[0].lng)-0.2;
    const latMax = parseFloat(device.parameters[0].lat)+0.2;
    const lngMax = parseFloat(device.parameters[0].lng)+0.2;
    const newBounds = [
      [latMin, lngMin],
      [latMax, lngMax]
    ];
    const newTimestamp = {
      prev: this.state.lastActionTimestamp.prev,
      action: Date.now()
    }
    this.setState({
      bounds: newBounds,
      lastActionTimestamp: newTimestamp,
      currentDevice: device,
    });
  };

  render() {
    // console.log(this.state.devices);
    let bounds= this.state.bounds;
    let timestamp_now = parseInt(Date.now());
    let timestamp_valid_untill = parseInt(this.state.data.valid_untill);
    let display_content = false;
    if (timestamp_valid_untill >= timestamp_now) {
      display_content = true;
    }
    // console.log('display_content',display_content);
    // console.log('timestamp_valid_untill', timestamp_now, timestamp_valid_untill);
    return (
      display_content ?
      <div className= {`content-wrapper vehile-access-page ${this.state.auth}`}>
        <div id="panel-left-map" className={`content-left panel left ${this.state.panelVisible === true? 'opened': ''}`}>
          <div className="header">
            <div className="name">
              <div className="label">
                Dostęp:
              </div>
              <div className="value">
                {this.state.data.name}
              </div>
            </div>
            <div className="valid-untill">
              <div className="label">
                Ważny do:
              </div>
              <div className="value">
                <Moment format="DD.MM.YYYY HH:mm:ss">
                  {new Date(parseInt(this.state.data.valid_untill))}
                </Moment>
              </div>
            </div>
          </div>
        <ul className="vehicles-list">
          {
            this.state.devices.map((device, i) => (
              <li className="vehicle" key={device.id}>
                <div className="left">
                  <div className="top-line">
                    <div className="licenseplate">
                      {device.reg}
                      <div className={device.countryShort === undefined? 'country-short-disabled': 'country-short'}>{device.countryShort}</div>
                    </div>
                    <div className="codename">{device.name}</div>
                  </div>
                  <ul className="middle-line text-color">
                    <li><span className="label"><AiOutlineThunderbolt /></span>{device.parameters[0].ignition ? 'Wł' : 'Wył'}</li>
                    <li><span className="label"><GiCartwheel /></span>{device.parameters[0].movement ? 'Wł' : 'Wył'}</li>
                    <li><span className="label"><AiOutlineDashboard /></span>{(device.parameters[0].gpsSpeed*1).toFixed(0)}<span className="unit"> km/h</span></li>
                    <li><span className="label"><RiBattery2ChargeLine /></span>{(device.parameters[0].voltage/1000).toFixed(2)}<span className="unit"> V</span></li>
                  </ul>
                  <div className="bottom-line text-color">
                    <div><span className="label">Ost. Rek: </span>
                    <Moment format="DD.MM.YYYY HH:mm:ss">
                      {new Date(device.parameters[0].record_timestamp)}
                    </Moment>
                    </div>
                  </div>
                </div>
                <div className="details">
                  <div className="show-vehicle-on-map-button" onClick={() => this.handleShowVehicle(device, i)}>
                    <RiAlbumLine />
                  </div>
                </div>
              </li>
            ))
          }
        </ul>
        </div>
        <div className="content-right">
          <MapContainer scrollWheelZoom={true} bounds={bounds}>
            <UpdateMapCentre mapCentre={this.state.newCords} bounds={this.state.bounds} handleLastActionTimeChange={this.handleLastActionTimeChange} lastActionTimestamp={this.state.lastActionTimestamp}/>

            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MapConsumer>
              {(map) => {
                // console.log("map center:", map.getCenter());
                // map.on("click", function (e) {
                //   // console.log(e);
                //   const { lat, lng } = e.latlng;
                //   L.marker([lat, lng], { icon }).addTo(map);
                //   // console.log('ddddddddddd', lat, lng);
                //
                // });
                return null;
              }}
            </MapConsumer>


            <MarkerClusterGroup
              chunkedLoading
              iconCreateFunction={createClusterCustomIcon}
              maxClusterRadius={60}
              removeOutsideVisibleBounds={true}
              polygonOptions={{
                fill: '#ffffff',
                color: '#5573df',
                weight: 2,
                opacity: 0.6,
                fillOpacity: 0.1,
              }}
            >
            {
              this.state.devices.map((device, i) => (
              <CustomMarker
                key={i}
                id={device.id}
                latitude={device.parameters[0].lat}
                longitude={device.parameters[0].lng}
                heading={device.parameters[0].gpsHeading}
                licenseplate={device.reg}
                ignition={device.parameters[0].ignition}
                movement={device.parameters[0].movement}
                speed={device.parameters[0].gpsSpeed}
              >
              </CustomMarker>

              ))
            }
            </MarkerClusterGroup>

          </MapContainer>
        </div>
        <div className={`map-switch ${this.state.panelVisible === true? 'opened': ''}`} onClick={this.switchMapPanel}>
          <div>
          <div>
            <RiMap2Line />
          </div>
          </div>
        </div>
      </div>
      :
      <div className="content-wrapper access-invalid">
        <div className="access-error">
          Dostęp {this.state.data.name} wygasł
          <span className="date-valid-untill">
          <Moment format="DD.MM.YYYY HH:mm:ss">
            {new Date(parseInt(this.state.data.valid_untill))}
          </Moment>
          </span>
        </div>
      </div>
    );
  }
}
