import React from "react";
import { Tab, Tabs as TabsComponent, TabList, TabPanel } from "react-tabs";
import { SelectPicker } from 'rsuite';


function timeFormated(t) {
  // console.log(t);
  var timestamp = new Date(parseInt(t));
  var tsp_da = ("0" + timestamp.getDate()).slice(-2);
  var tsp_mo = (parseInt(("0" + timestamp.getMonth()).slice(-2))+1);
  var tsp_ye = timestamp.getFullYear();
  var tsp_ho = ("0" + timestamp.getHours()).slice(-2);
  var tsp_mi = ("0" + timestamp.getMinutes()).slice(-2);
  var tsp_se = ("0" + timestamp.getSeconds()).slice(-2);
  var currentTimezone = timestamp.getTimezoneOffset();
  currentTimezone = (currentTimezone/60) * -1;
  var gmt = 'GMT';
  if (currentTimezone !== 0) {
    gmt += currentTimezone > 0 ? ' +' : ' ';
    gmt += currentTimezone;
  }
  var dateFormat = tsp_da+'.'+tsp_mo+'.'+tsp_ye+' '+tsp_ho+':'+tsp_mi+':'+tsp_se+' ['+gmt+']';
  return dateFormat;
}

export class Device extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDevice: [],
      currentUserRoles: this.props.currentUserRoles,
      goBack: '',
      id: '',
      codename: '',
      vehicle_name: '',
      licenseplate: '',
      type: '',
      brand: '',
      model: '',
      year: '',
      device_type: '',
      device_id: '',
      phone: '',
      sn: '',
      ble_status: '',
      ble_temp_1: '',
      ble_humd_1: '',
      ble_batt_1: '',
      ble_temp_2: '',
      ble_humd_2: '',
      ble_batt_2 : '',
      battery_current: '',
      battery_voltage: '',
      battery_level: '',
      fuel_lvl: '',
      gpsSpeed: '',
      ignition: '',
      last_connection_time: '',
      movement: '',
      odometer: '',
      operatorCode: '',
      satellitesForFix: '',
      totel_fuel_used: '',
      record_timestamp: '',
      voltage: ''
    };
    this.handleCloseDetails = this.handleCloseDetails.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDeviceEditSubmit = this.handleDeviceEditSubmit.bind(this);
    this.handleCarTypeSelect = this.handleCarTypeSelect.bind(this);
  }


  componentWillReceiveProps (newProps) {
    if( newProps.currentUserRoles !== this.props.currentUserRoles ) {
      this.setState({currentUserRoles:  newProps.currentUserRoles})
    }
    if( newProps.currtntDevice !== this.props.currtntDevice ) {
      var ignition,
          movement,
          gpsSpeed,
          operatorCode,
          satellitesForFix,
          voltage,
          odometer,
          fuel_lvl,
          totel_fuel_used,
          last_connection_time,
          battery_voltage,
          battery_current,
          battery_level,
          ble_status,
          ble_temp_1,
          ble_humd_1,
          ble_batt_1,
          ble_temp_2,
          ble_humd_2,
          ble_batt_2,
          record_timestamp;
      if (newProps.currtntDevice.lastrecord) {
        ble_status = newProps.currtntDevice.lastrecord.ble_status;
        ble_temp_1 = newProps.currtntDevice.lastrecord.ble_temp_1;
        ble_humd_1 = newProps.currtntDevice.lastrecord.ble_humd_1;
        ble_batt_1 = newProps.currtntDevice.lastrecord.ble_batt_1;
        ble_temp_2 = newProps.currtntDevice.lastrecord.ble_temp_2;
        ble_humd_2 = newProps.currtntDevice.lastrecord.ble_humd_2;
        ble_batt_2 = newProps.currtntDevice.lastrecord.ble_batt_2;
        battery_current = newProps.currtntDevice.lastrecord.battery_current;
        battery_voltage = newProps.currtntDevice.lastrecord.battery_voltage;
        battery_level = newProps.currtntDevice.lastrecord.battery_level;
        fuel_lvl = newProps.currtntDevice.lastrecord.fuel_lvl;
        gpsSpeed = newProps.currtntDevice.lastrecord.gpsSpeed;
        ignition = newProps.currtntDevice.lastrecord.ignition;
        last_connection_time = newProps.currtntDevice.lastrecord.last_connection_time;
        movement = newProps.currtntDevice.lastrecord.movement;
        odometer = newProps.currtntDevice.lastrecord.odometer;
        operatorCode = newProps.currtntDevice.lastrecord.operatorCode;
        satellitesForFix = newProps.currtntDevice.lastrecord.satellitesForFix;
        totel_fuel_used = newProps.currtntDevice.lastrecord.totel_fuel_used;
        record_timestamp = newProps.currtntDevice.lastrecord.record_timestamp;
        voltage = newProps.currtntDevice.lastrecord.voltage;
      }

      this.setState({newProps:  newProps.newProps})
      this.setState({
        id: newProps.currtntDevice.id,
        codename: newProps.currtntDevice.codename,
        vehicle_name: newProps.currtntDevice.name,
        licenseplate: newProps.currtntDevice.licenseplate,
        type:  newProps.currtntDevice.type,
        brand:  newProps.currtntDevice.brand,
        model: newProps.currtntDevice.model,
        year: newProps.currtntDevice.year,
        device_type: newProps.currtntDevice.device_type,
        device_id: newProps.currtntDevice.device_id,
        phone: newProps.currtntDevice.phone,
        sn: newProps.currtntDevice.sn,
        ble_status: ble_status,
        ble_temp_1: ble_temp_1,
        ble_humd_1: ble_humd_1,
        ble_batt_1: ble_batt_1,
        ble_temp_2: ble_temp_2,
        ble_humd_2: ble_humd_2,
        ble_batt_2 : ble_batt_2,
        battery_current: battery_current,
        battery_voltage: battery_voltage,
        battery_level: battery_level,
        fuel_lvl: fuel_lvl,
        gpsSpeed: gpsSpeed,
        ignition: ignition,
        last_connection_time: last_connection_time,
        movement: movement,
        odometer: odometer,
        operatorCode: operatorCode,
        satellitesForFix: satellitesForFix,
        totel_fuel_used: totel_fuel_used,
        record_timestamp: record_timestamp,
        voltage: voltage
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    console.log(name, value);
    this.setState({
      [name]: value
    });
  }

  handleSelectChange(event) {
    const name = event.target.name;
    this.setState({[name]: event.target.value});
  }

  handleDeviceEditSubmit(event) {
    event.preventDefault();
    const type = event.target.name;
    this.props.editDevice([{
      type: type,
      id: this.state.id,
      codename: this.state.codename,
      name: this.state.vehicle_name,
      licenseplate: this.state.licenseplate,
      vehicle_type:  this.state.type,
      brand:  this.state.brand,
      model: this.state.model,
      year: this.state.year,
      device_type: this.state.device_type,
      device_id: this.state.device_id,
      phone: this.state.phone,
      sn: this.state.sn
    }]);
  };


  handleCloseDetails = () => {
    this.props.goBack('back');
  };

  handleCarTypeSelect(event) {
    let ds = event;
    this.setState({type: ds});
  }

  render(){
    const tabsHeaders = [
      {
        heading: "Pojazd",
      },
      {
        heading: "Urządzenie",
      },
      {
        heading: "Info",
      }
    ];
    const tabsNumber =  'tabs-'+tabsHeaders.length;
    const vehicleTypes = [
      { label: 'Osobowy', value: 'car' },
      { label: 'Ciężarowy', value: 'truck' }
    ]
    // const vehicleTypes = ['Eugenia', 'Bryan', 'Linda', 'Nancy', 'Lloyd', 'Alice', 'Julia', 'Albert'].map(
    //   item => ({ label: item, value: item })
    // );

    console.log('type', this.state.type);
    return (
      <div className="list-element-details">
        <div className="header">
          <div className="arrow-container">
            <span onClick={this.handleCloseDetails} className={`arrow ${this.state.navWidth === true? 'wide': ''}`}>
              <span></span>
              <span></span>
            </span>
          </div>
          <div className="name-container">
            <div className="licenseplate">{this.state.licenseplate}</div>
            <div className="codename">{this.state.vehicle_name} / {this.state.codename}</div>
          </div>
        </div>


        <div className={tabsNumber}>
          <TabsComponent>
            <TabList>
              {tabsHeaders.map(({ heading }, i) => (
                <Tab key={i}>{heading}</Tab>
              ))}
            </TabList>
            <TabPanel>
              <div className="edit-object">
                <form name="vehicleedit" onSubmit={this.handleDeviceEditSubmit}>
                  <label>
                    Nazwa:
                    <input className="formEditName" type="text" value={this.state.vehicle_name} name="vehicle_name" onChange={this.handleInputChange} />
                  </label>
                  <label>
                    Numer rejestracyjny:
                    <input className="formEditLicenseplate" type="text" value={this.state.licenseplate} name="licenseplate" onChange={this.handleInputChange} />
                  </label>
                  <div>
                    Typ pojazdu:
                    <SelectPicker
                     cleanable={false}
                     value={this.state.type}
                     data={vehicleTypes}
                     searchable={false}
                     style={{ width: 224 }} />
                  </div>
                  <label>
                    Marka:
                    <input className="formEditBrand" type="text" value={this.state.brand} name="brand" onChange={this.handleInputChange} />
                  </label>
                  <label>
                    Model:
                    <input className="formEditModel" type="text" value={this.state.model} name="model" onChange={this.handleInputChange} />
                  </label>
                  <label>
                    Rok produkcji:
                    <input className="formEditYear" type="text" value={this.state.year} name="year" onChange={this.handleInputChange} />
                  </label>
                  <input type="submit" value="Zapisz" />
                </form>
              </div>
            </TabPanel>
            <TabPanel>
            <div className="edit-object">
            {this.state.currentUserRoles === "admin"?
              <form name="deviceedit" onSubmit={this.handleDeviceEditSubmit}>
                <label>
                  Codename:
                  <input className="formEditCodename" type="text" value={this.state.codename} name="codename" onChange={this.handleInputChange} />
                </label>
                <label>
                  Typ urządzenia:
                  <select className="formEditDeviceType" value={this.state.device_type} name="device_type" onChange={this.handleSelectChange}>
                    <option value="fmb120">FMB120</option>
                    <option value="fmb140">FMB140</option>
                    <option value="fmb640">FMB640</option>
                    <option value="fmb920">FMB920</option>
                    <option value="fmc130">FMC130</option>
                    <option value="fmc650">FMC650</option>
                  </select>
                </label>
                <label>
                  IMEI:
                  <input className="formEditBrand" type="text" value={this.state.device_id} name="device_id" onChange={this.handleInputChange} />
                </label>
                <label>
                  Numer seryjny:
                  <input className="formEditSn" type="text" value={this.state.sn} name="sn" onChange={this.handleInputChange} />
                </label>
                <label>
                  Nr MSISDN:
                  <input className="formEditPhone" type="text" value={this.state.phone} name="phone" onChange={this.handleInputChange} />
                </label>
                <input type="submit" value="Zapisz" />
              </form>
            :
              <div className="info-container">
              <ul className="text-color margin-bottom elements-list">
                <li className="device_info">
                  <span className="label">Typ urządzenia:</span>
                  <div className="value">{this.state.device_type}</div>
                </li>
                <li className="device_info">
                  <span className="label">IMEI:</span>
                  <div className="value">{this.state.device_id}</div>
                </li>
                <li className="device_info">
                  <span className="label">Numer seryjny:</span>
                  <div className="value">{this.state.sn}</div>
                </li>
                <li className="device_info">
                  <span className="label">Nr MSISDN:</span>
                  <div className="value">{this.state.phone}</div>
                </li>
              </ul>
              </div>
            }
            </div>

            </TabPanel>
            <TabPanel>
              <div className="info-container">
                <ul className="text-color margin-bottom elements-list">
                  <li><span className="label">Operator GSM: </span>{this.state.operatorCode}</li>
                  <li><span className="label">Napięcie: </span>{(this.state.voltage/1000).toFixed(2)} V</li>
                  <li><span className="label">Napięcie baterii: </span>{(this.state.battery_voltage/1000).toFixed(2)} V</li>
                  <li><span className="label">Ładowanie baterii: </span>{(this.state.battery_current/1000).toFixed(2)} V</li>
                  <li><span className="label">Poziom naładowania baterii: </span>{this.state.battery_level} %</li>
                </ul>
                <ul className="text-color margin-bottom elements-list">
                  <li><span className="label">Przebieg: </span>{(this.state.odometer/1000).toFixed(2)} km</li>
                  <li><span className="label">Poziom paliwa: </span>{this.state.fuel_lvl} %</li>
                  <li><span className="label">Całkowite zużycie paliwa: </span>{this.state.totel_fuel_used} l</li>
                </ul>
                <ul className="text-color margin-bottom elements-list">
                  <li><span className="label">Status bluetooth: </span>{this.state.ble_status}</li>
                  <li><span className="label">Sensor 1 temperatura: </span>{(this.state.ble_temp_1/100).toFixed(2)} °C</li>
                  <li><span className="label">Sensor 1 wilgotność: </span>{this.state.ble_humd_1} %</li>
                  <li><span className="label">Sensor 1 bateria: </span>{this.state.ble_batt_1} %</li>
                  <li><span className="label">Sensor 2 temperatura: </span>{(this.state.ble_temp_2/100).toFixed(2)} °C</li>
                  <li><span className="label">Sensor 2 wilgotność: </span>{this.state.ble_humd_2} %</li>
                  <li><span className="label">Sensor 2 bateria: </span>{this.state.ble_batt_2} %</li>
                </ul>
                <ul className="text-color margin-bottom elements-list">
                  <li><span className="label">Ost. poł: </span>{timeFormated(this.state.last_connection_time)}</li>
                  <li><span className="label">Ost. Rek: </span>{timeFormated(this.state.record_timestamp)}</li>
                </ul>
              </div>
            </TabPanel>
          </TabsComponent>
        </div>
      </div>
    )
  }
}
