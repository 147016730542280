import * as React from 'react';
import { GET_ERRORS, GET_VEHILCES_LIST } from './types';

export const listVehcles = (vehicleslist) => dispatch => {
  // console.log(sharedvehicleslist);
      dispatch({
          type: GET_VEHILCES_LIST,
          payload: vehicleslist
      });
  // axios({
  //     url: api_url+'organizations/list',
  // })
  // .then(response => {
  //     dispatch({
  //         type: GET_SHARED_VEHILCES_LIST,
  //         payload: response.data
  //     });
  // });
}
