import * as React from 'react';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import Moment from 'react-moment';
import 'moment-timezone';
import {Device} from "./DisplayDeviceDetails";
import { Tooltip, Whisper, IconButton, Loader, DatePicker } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const api_name = process.env.REACT_APP_API_URL;
const api_ssl = process.env.REACT_APP_API_SSL;
const api_ws = process.env.REACT_APP_API_WS;
const domainName =  window.location.hostname;
var api_url = domainName+api_name;
switch (domainName) {
  case '141.94.222.182':
    api_url = domainName+':5000'+api_name;
    break;
  default:
}

export class DisplayDevicesSocket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      currtntDevice: [],
      currentUserRoles: this.props.currentUserRoles,
      data: [],
      devicetype: 'fmb920',
      etoll: '',
      imei: '',
      modal: false,
      organization_id: '',
      reg: '',
      sn: '',
      tel: '',
      value: '',
      ws: null
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDeviceTypeChange = this.handleDeviceTypeChange.bind(this);
    this.handleDeviceAddSubmit = this.handleDeviceAddSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleShowDetails = this.handleShowDetails.bind(this);
    this.handleCloseDetails = this.handleCloseDetails.bind(this);
    this.handleEditDevice = this.handleEditDevice.bind(this);
  }

  componentDidMount() {
    // var ws = new W3CWebSocket(api_ws+'://'+api_url+'/devices');
    // this.setState({
    //   ws: ws
    // });
    // this.connect(ws);

    if (this.state.ws === null || this.state.ws.readyState !== 1) {
      this.connect();
    }
    this.timeout = setInterval(() => {
      if (this.state.ws.readyState === 1) {
        let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
        let at = '';
        let oi = '';
        if(auth_get) {
          at = auth_get.access_token;
          oi = auth_get.organization_id;
        }
        let msg_to_server = JSON.stringify({"type":"devices_list", "at":at, "organization_id":oi});
        this.state.ws.send(msg_to_server);
      }
    }, 10000)

    this.props.activeTab('devices');
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
    this.state.ws.close();
    this.setState({ ws: [] });
  }

  componentWillReceiveProps (newProps) {
    if( newProps.orgId !== this.props.orgId ) {
      this.setState({organization_id:  newProps.orgId})
      let accesss_token = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
      let at = '';
      if(accesss_token) {
        at = accesss_token.access_token;
      }
      let msg_to_server = JSON.stringify({"type":"devices_list", "at":at, "organization_id":newProps.orgId});
      this.state.ws.send(msg_to_server);
    }
    if( newProps.currentUserRoles !== this.props.currentUserRoles ) {
      this.setState({currentUserRoles:  newProps.currentUserRoles})
    }

  }

  connect = () => {
    var ws = new W3CWebSocket(api_ws+'://'+api_url+'/devices');
    let that = this; // cache the this


    let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    let at = '';
    let oi = '';
    if(auth_get) {
      at = auth_get.access_token;
      oi = auth_get.organization_id;
    }
    let msg_to_server = JSON.stringify({"type":"devices_list", "at":at, "organization_id":oi});

    ws.onopen = () => {
      that.setState({ ws: ws });
      ws.send(msg_to_server);
    };

    ws.onmessage = (message) => {
      let datajson = JSON.parse(message.data);
      // console.log(datajson);
      if(datajson.type === 'devices_add') {
        this.setState({modal: false});
      } else if(datajson.type === 'devices_edit') {
        toast.success('Zmiany zostały zapisane');
      } else if(datajson.type === 'devices_list') {
        const data = datajson.data;
        this.setState({data: data});
      }
    };

    ws.onclose = (e) => {
      ws.close();
      that.setState({ ws: [] });
      setTimeout(function () {
        that.connect();
      }, 3000);
    };

    ws.onerror = (err) => {
      // console.error(err.message);
      ws.close();
    };
  };

  showModal(event) {
    this.setState({modal: true});
  };
  hideModal(event) {
    this.setState({modal: false});
  };

  handleDeviceTypeChange(event) {
    this.setState({devicetype: event.target.value});
  }

  handleInputChange(event) {
    const target = event.target;
    // const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const value = target.value;

    this.setState({
      [name]: value
    });
  }

  handleDeviceAddSubmit(event) {
    event.preventDefault();
    const ts = this.state;
    let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    let at = '';
    let oi = '';
    if(auth_get) {
      at = auth_get.access_token;
      oi = auth_get.organization_id;
    }
    var msg_to_server =  JSON.stringify({"type":"devices_add", "codename":ts.code, "licenseplate":ts.reg, "organization_id":oi, "device_type":ts.devicetype, "device_id":ts.imei});
    ts.ws.send(msg_to_server);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
    let msg_to_server = JSON.stringify({"type":"devices_list", "device_type":'fmb640'});
    this.state.ws.send(msg_to_server);
  }

  handleShowDetails = (device, event) => {
    let listKeys = Object.keys(device)
    for (var i = 0; i < Object.keys(device).length; i++) {
      let key = listKeys[i];
      let val = device[key];
      if (val === null || val === undefined) {
        device[key] = '';
      }
    }
    console.log(device);
    this.setState({
      closeDetails: true,
      currtntDevice: device,
    });
  };

  handleCloseDetails = () => {
    this.setState({
      closeDetails: false
    });
  };

  handleEditDevice = (childData) => {
    let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    let at = '';
    let oi = '';
    if(auth_get) {
      at = auth_get.access_token;
      oi = auth_get.organization_id;
    }
    // console.log(edit('sssss'));
    console.log(childData[0]);
    let msg_edit = JSON.stringify({"type":"devices_edit", "at":at, "organization_id":oi, "data":childData[0]});

    const ts = this.state;
    ts.ws.send(msg_edit);
    let msg_to_server = JSON.stringify({"type":"devices_list", "at":at, "organization_id":oi});
    ts.ws.send(msg_to_server);
  };

  render() {
    // console.log('devices socket currentUserRoles', this.state.currentUserRoles);
    return (
      <div className="content-wrapper devices">
        <div className={`content-left ${this.state.closeDetails === true? 'opened': ''}`}>
          <div className="header">
            <div className="left">
              <h2>Lista pojazdów:</h2>
            </div>
            <div className="right">
              {this.state.currentUserRoles === "admin"?
              <IconButton
                appearance="primary"
                className="new-button"
                size="md"
                onClick={this.showModal}
                icon={<PlusIcon />}>Dodaj pojazd</IconButton>
              :
              ''
              }
            </div>
          </div>
          <form className="form_custom_message" onSubmit={this.handleSubmit}>
            <label>
              Typ wiadomości:
              <input type="text" value={this.state.value} onChange={this.handleChange} />
            </label>
            <input type="submit" value="Wyślij" />
          </form>
          <ul>
            {
              this.state.data.map((device, i) => (
                <li key={device.id} className="device">
                  <div className="left">
                    <div className="top-line">
                      <div className="device_name">{device.name}</div>
                      <div className="licenseplate">{device.licenseplate}</div>
                      <div className="codename">{device.codename}</div>
                      <div className="device_type">{device.device_type}</div>
                    </div>
                    <div className="middle-line">
                      <div><span className="label">Zap: </span>{device.lastrecord.ignition}</div>
                      <div><span className="label">Op: </span>{device.lastrecord.operatorCode}</div>
                      <div><span className="label">Sat: </span>{device.lastrecord.satellitesForFix}</div>
                      <div><span className="label">V: </span>{(device.lastrecord.voltage/1000).toFixed(2)}</div>
                    </div>
                    <div className="bottom-line">
                      <div><span className="label">Ost. poł: </span>
                      <Moment format="DD.MM.YYYY HH:mm:ss">
                        {new Date(parseInt(device.lastrecord.last_connection_time))}
                      </Moment>
                      </div>
                      <div><span className="label">Ost. Rek: </span>
                      <Moment format="DD.MM.YYYY HH:mm:ss">
                        {new Date(parseInt(device.lastrecord.record_timestamp))}
                      </Moment>
                      </div>
                    </div>
                  </div>
                    <div className="details">
                    <div className="right" onClick={() => this.handleShowDetails(device, i)}>
                      Szczegóły
                    </div>
                  </div>
                </li>
              ))
            }
          </ul>
        </div>
        <div className={`content-right ${this.state.closeDetails === true? 'opened': ''}`}>
          <div className="inner">
            <Device currentUserRoles={this.state.currentUserRoles} currtntDevice={this.state.currtntDevice} editDevice={this.handleEditDevice} goBack={this.handleCloseDetails} />
          </div>
        </div>


        <div className={`modal add ${this.state.modal === true? 'opened': ''}`}>
          <div className="wrapper">
            <div className="inner">
              <div className="header">
                <div className="left">
                  <h3>Dodaj nowy pojazd</h3>
                </div>
                <div className="right">
                <button onClick={this.hideModal} id="modal-btn-close" className="on"><span></span><span></span><span></span>
                </button>
                </div>
              </div>
              <div className="content">
                <form onSubmit={this.handleDeviceAddSubmit}>
                  <label>
                    Codename:
                    <input type="text" value={this.state.code} name="code" onChange={this.handleInputChange} />
                  </label>
                  <label>
                    Numer rejestracyjny:
                    <input type="text" value={this.state.reg} name="reg" onChange={this.handleInputChange} />
                  </label>
                  <label>
                    Typ urządzenia:
                    <select value={this.state.devicetype}  onChange={this.handleDeviceTypeChange}>
                      <option value="fmb120">FMB120</option>
                      <option value="fmb140">FMB140</option>
                      <option value="fmb640">FMB640</option>
                      <option value="fmb920">FMB920</option>
                    </select>
                  </label>
                  <label>
                    IMEI:
                    <input type="text" value={this.state.imei} name="imei" onChange={this.handleInputChange} />
                  </label>
                  <label>
                    Numer seryjny:
                    <input type="text" value={this.state.sn} name="sn" onChange={this.handleInputChange} />
                  </label>
                  <label>
                    Numer telefonu:
                    <input type="text" value={this.state.tel} name="tel" onChange={this.handleInputChange} />
                  </label>
                  <label>
                    Etoll:
                    <input type="text" value={this.state.etoll} name="etoll" onChange={this.handleInputChange} />
                  </label>
                  <input type="submit" value="Dodaj" />
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        {/* Same as */}
        <ToastContainer />
      </div>
    );
  }
}
