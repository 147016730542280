import { useLocation, useHistory, useParams } from 'react-router-dom';

function withRouter(Component) {
  	function ComponentWithRouterProp(props) {
    	let location = useLocation();
    	let navigate = useHistory();
    	let params = useParams();
    	return (
      		<Component
        		{...props}
        		location={location}
        		params={params}
        		navigate={navigate.push}
      		/>
    	);
  	}


  	return ComponentWithRouterProp;
}

export default withRouter;
