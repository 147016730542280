import React from "react";
import withRouter from '../../WithRouter';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { w3cwebsocket as W3CWebSocket } from "websocket";
// import { DateRangePicker } from 'react-date-range';
import { addDays, subDays } from "date-fns";
// import { pl } from 'date-fns/locale'
// import Moment from 'react-moment';
// import Multiselect from 'multiselect-react-dropdown';
import { InputPicker, CheckPicker } from 'rsuite';
import {CalendarModal} from "../calendar_n";
import { IoIosAdd } from "react-icons/io";
// import { RiCalendarLine } from "react-icons/ri";
import { RiCheckFill } from "react-icons/ri";
import Moment from 'react-moment';
import 'moment-timezone';
import { setCurrentOrganization } from '../../actions/Organization';


const api_name = process.env.REACT_APP_API_URL;
const api_ssl = process.env.REACT_APP_API_SSL;
const api_ws = process.env.REACT_APP_API_WS;
const domainName =  window.location.hostname;
var api_url = domainName+api_name;
switch (domainName) {
  case '141.94.222.182':
    api_url = domainName+':5000'+api_name;
    break;
  default:
}

function reportType(t) {
  switch (t) {
    case 'report_tracks':
      return 'Trasy';
      break;
    case 'report_epp':
      return 'Ewidencja przebiegu pojazdu';
      break;
    case 'report_speedings':
      return 'Przekroczenia prędkości';
      break;
    case 'report_border_crossing':
      return 'Raport Przekraczania granic';
      break;
    default:
      return 'N/D'
  }
}
function reportStatus(g) {
  switch (g) {
    case true:
      return 'Gotowy'
      break;
    default:
      return 'W trakcie'
  }
}

class DisplayReportsSocket extends React.Component {
  constructor(props) {
  super(props);
      this.state = {
        calendarVisibility: false,
        calMaxDays: 31,
        data: [],
        type: 'report_tracks',
        rgns: [{
          startDate: subDays(new Date(), 1),
          endDate: addDays(new Date(), 1),
          key: 'selection'
        }],
        vehicles: [],
        selectedVehicles: [],
        ws: [],
        organization_id: '',
        org_current: []
      };
      this.handleTypeChange = this.handleTypeChange.bind(this);
      this.handleAddSubmit = this.handleAddSubmit.bind(this);
      this.handleVehiclesChange = this.handleVehiclesChange.bind(this);
      this.calendarChange = this.calendarChange.bind(this);
      this.showModal = this.showModal.bind(this);
      this.hideModal = this.hideModal.bind(this);
      // this.onSelect = this.onSelect.bind(this);
      // this.onRemove = this.onRemove.bind(this);
      // this.handleSelect = this.handleSelect.bind(this);
  }

  timeout = 250; // Initial timeout duration as a class variable
  componentDidMount() {
      var ws = new W3CWebSocket(api_ws+'://'+api_url+'/reports');
      this.setState({
        ws: ws
      });
      this.connect(ws);
      this.setState({org_current: this.props.orgcurrent.list});
  }

  componentWillUnmount() {
    this.state.ws.close();
  }


  componentDidUpdate(prevProps) {

    if(prevProps.orgcurrent !== this.props.orgcurrent) {
      this.setState({org_current: this.props.orgcurrent.list});
    }
  }


  componentWillReceiveProps (newProps) {
    if( newProps.orgId !== this.props.orgId ) {
      this.setState({organization_id:  newProps.orgId})
      this.props.setCurrentOrganization(newProps.orgId);
      let accesss_token = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
      let at = '';
      if(accesss_token) {
        at = accesss_token.access_token;
      }
      let msg_to_server = JSON.stringify({"type":"reports_list", "at":at, "organization_id":newProps.orgId});
      this.send(msg_to_server);
    }
  }


  connect = (ws) => {
      let that = this; // cache the this
      var connectInterval;
      // websocket onopen event listener

      let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
      let at = '';
      if(auth_get) {
        at = auth_get.access_token;
      }
      let msg_to_server = JSON.stringify({"type":"reports_list", "at":at, "organization_id":auth_get.organization_id});
      that.setState({
        organization_id: auth_get.organization_id
      });

      this.send = function (message, callback) {
        this.waitForConnection(function () {
            ws.send(message);
            if (typeof callback !== 'undefined') {
              callback();
              // console.log('444444444444444444444444444444');

            } else {
              // console.log('eeeeeeeeeeeeeeeeeeeeeeeeeeeeee');
            }
        }, 1000);
      };

      this.waitForConnection = function (callback, interval) {
        if (ws !== undefined) {
          if (ws.readyState === 1) {
              callback();
          } else {
              var that = this;
              // optional: implement backoff for interval here
              setTimeout(function () {
                  that.waitForConnection(callback, interval);
              }, interval);
          }
        }
      };

      this.send(msg_to_server);

      setInterval(() => {
        let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
        let at = '';
        if(auth_get) {
          at = auth_get.access_token;
        }
        let msg_to_server = JSON.stringify({"type":"reports_list", "at":at, "organization_id":auth_get.organization_id});
        this.send(msg_to_server);
      }, 10000)

    if (ws !== undefined) {

      ws.onopen = () => {
          // console.log("Websocket devices: connected!");
          this.setState({ ws: ws });

          that.timeout = 250; // reset timer to 250 on open of websocket connection
          clearTimeout(connectInterval); // clear Interval on on open of websocket connection
      };


      ws.onmessage = (message) => {
         let datajson = JSON.parse(message.data);

          if(datajson.type === 'reports_add') {
            this.setState({modal: false});
          } else if(datajson.type === 'reports_list') {
            const data = datajson.data;
            const vehicles = datajson.vehicles;
            // console.log(vehicles);
            this.setState({
              data: data,
              vehicles: vehicles
            });
          }

      };

      // websocket onclose event listener
      ws.onclose = e => {
          that.timeout = that.timeout + that.timeout; //increment retry interval
          connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
      };

      // websocket onerror event listener
      ws.onerror = err => {
          ws.close();
      };
    }
  };

  check = () => {
      const { ws } = this.state;
      if (!ws || ws.readyState === WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
  };


  showModal(event) {
    this.setState({modal: true});
  };
  hideModal(event) {
    this.setState({modal: false});
  };

  handleTypeChange(event) {
    this.setState({type: event});
  }
  handleVehiclesChange(event) {
    let ds = event;
    let vl = this.state.vehicles;
    let sn = [];
    for (var i = 0; i < ds.length; i++) {
      let obj = vl.find(o => o.value === ds[i]);
      // console.log(ds[i]);
      sn.push(
        obj
      );
    }
    // console.log(sn);
    this.setState({selectedVehicles: sn});
  }

  calendarChange = (childData) => {
    this.setState({
      rgns: [{
        startDate: new Date(childData[0].rgns[0].startDate),
        endDate: new Date(childData[0].rgns[0].endDate),
        key: 'selection'
      }]
    });
  };
  // handleVehiclesChange(event) {
  //   this.setState({vehicles: event.target.value});
  // }

  // onSelect(event) {
  //   console.log(event);
  //   // let sv = this..selectedVehicles;
  //   // sv.push(selectedItem);
  //   // this.setState({selectedVehicles: sv});
  // }
  //
  // onRemove(selectedList, removedItem) {
  //   let sv = this.state.selectedVehicles;
  //   const indexOfObject = sv.findIndex(object => {
  //     return object.id === removedItem.id;
  //   });
  //   sv.splice(indexOfObject, 1);
  //   this.setState({selectedVehicles: sv});
  // }

  // handleSelect(ranges){
  //     console.log("ranges");
  //     console.log(ranges);
  //     this.setState({
  //       rgns: [{
  //         startDate: new Date(ranges.selection.startDate),
  //         endDate: new Date(ranges.selection.endDate),
  //         key: 'selection'
  //       }]
  //     });
  //   }

  handleAddSubmit(event) {
    event.preventDefault();
    const ts = this.state;
    let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    let at = '';
    if(auth_get) {
      at = auth_get.access_token;
    }
    let sd = ts.rgns[0].startDate;
    let ed = ts.rgns[0].endDate;
    let rtype = ts.type;
    var msg_to_server =  JSON.stringify(
      {
        "type":"reports_add",
        "rtype": rtype,
        "date_start": (new Date(sd.toISOString().split('T')[0])).getTime(),
        "date_end": (new Date(ed.toISOString().split('T')[0])).getTime(),
        "user_id": auth_get.user_id,
        "organization_id": auth_get.organization_id,
        "vehicles": ts.selectedVehicles,
      }
    );
    // console.log(msg_to_server);
    ts.ws.send(msg_to_server);
    msg_to_server = JSON.stringify({"type":"reports_list", "at":at, "organization_id":auth_get.organization_id});
    ts.ws.send(msg_to_server);
  }

  render() {
    let organization_id = this.state.organization_id;
    let func_border_crossing = this.state.org_current.func_border_crossing;
    let showreport = [{ label: 'Raport tras', value: 'report_tracks' },{ label: 'Przekroczenia prędkości', value: 'report_speedings' }];

    if (organization_id === 1 || organization_id === 2  || organization_id === 3) {
      showreport = [{ label: 'Ewidencja przebiegu pojazdu', value: 'report_epp' },{ label: 'Raport tras', value: 'report_tracks' },{ label: 'Przekroczenia prędkości', value: 'report_speedings' }];
    }
    if (func_border_crossing === true) {
      showreport.push({ label: 'Raport przekraczania granic', value: 'report_border_crossing' });
    }
    console.log('org_current', this.state.org_current);
    return (
      <div className="content-wrapper reports">
        <div className="content-left">
          <div className="header">
            <div className="left">
              <h2>Lista raportów:</h2>
            </div>
            <div className="right">
              <div className="button add" onClick={this.showModal}>
                <IoIosAdd />
                <span className="text">Generuj raport</span>
              </div>
            </div>
          </div>
          <ul>
            {
              this.state.data.map((report, i) => (
                <li key={report.id} className="report">
                  <div className="left">
                    <div className="top-line">
                      <div className="type">{reportType(report.type)}</div>
                      <div className="created_at">
                        <Moment format="DD.MM.YYYY HH:mm:ss">
                          {new Date(report.created_at)}
                        </Moment>
                      </div>
                      <div className="date_start">
                        <Moment format="DD.MM.YYYY">
                          {new Date(report.date_start)}
                        </Moment>
                      </div>
                      <div className="date_end">
                        <Moment format="DD.MM.YYYY">
                          {new Date(report.date_end)}
                        </Moment>
                      </div>
                    </div>
                    <div className="bottom-line">
                      <div className="vehicles">
                        {
                          report.devices.map((device, i) => (
                            <span key={device} className="vehicle">{device}</span>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="status">
                      {report.generated==1 ? (
                        <RiCheckFill />
                      ) : (
                        <div className="spinner">
                          <div className="lds-dual-ring"></div>
                        </div>
                      )}
                      <div className="label">
                        {reportStatus(report.generated)}
                      </div>
                    </div>
                    <div className="download">
                      {report.generated==1 ? (
                         <a href={report.file_url}>Pobierz</a>
                      ) : (
                        <div>-</div>
                      )}
                    </div>
                  </div>
                </li>
              ))
            }
          </ul>
        </div>
        <div className={`modal add ${this.state.modal === true? 'opened': ''}`}>
          <div className="wrapper">
            <div className="inner">
              <div className="header">
                <div className="left">
                  <h3>Generuj raport</h3>
                </div>
                <div className="right">
                <button onClick={this.hideModal} id="modal-btn-close" className="on"><span></span><span></span><span></span>
                </button>
                </div>
              </div>
              <div className="content">
                <form onSubmit={this.handleAddSubmit}>
                  <div className="add-form-element">
                    <span className="label">
                    Typ raportu:
                    </span>
                    <InputPicker
                      className="full-width"
                      cleanable={false}
                      value={this.state.type}
                      onChange={this.handleTypeChange}
                      data={showreport} />
                  </div>
                  <div className="add-form-element">
                    <span className="label">
                      Pojazdy
                    </span>
                    <CheckPicker
                      className="full-width"
                      data={this.state.vehicles}
                      onChange={this.handleVehiclesChange}
                      locale={{
                        placeholder: 'Wybierz',
                        searchPlaceholder: 'Szukaj',
                        noResultsText: 'Brak wyników'
                      }}
                    />
                  </div>
                  <div className="add-form-element">
                    <span className="label">
                      Zakres czasu:
                    </span>
                    <CalendarModal calMaxDays={this.state.calMaxDays} calRanges={this.calendarChange} calendarVisibility={this.state.calendarVisibility}/>
                  </div>
                  <input type="submit" value="Dodaj" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DisplayReportsSocket.propTypes = {
  setCurrentOrganization: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  orgcurrent: state.orgcurrent,
  errors: state.errors
});

export default connect(mapStateToProps,{ setCurrentOrganization })(withRouter(DisplayReportsSocket))
