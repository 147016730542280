import * as React from 'react';
import axios from 'axios';
import { GET_ERRORS, GET_ETOLL_STATUS_LIST } from './types';
const api_name = process.env.REACT_APP_API_URL;
const api_ssl = process.env.REACT_APP_API_SSL;
const domainName =  window.location.hostname;
var api_url = domainName+api_name;

switch (domainName) {
  case '141.94.222.182':
    api_url = domainName+':5000'+api_name;
    break;
  default:
}

export const listEtollStatus = (params) => dispatch => {
  console.log('getting list axios api_url', );
  axios.get(
    api_ssl+'://'+api_url+'/integrations/etoll/status',
    { params: params }
  )
  .then(response => {
    console.log('getting list axios response.data', response.data);
    dispatch({
        type: GET_ETOLL_STATUS_LIST,
        payload: response.data
    });
  });
}
