import React from "react";
import withRouter from './WithRouter';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { IoIosAdd } from "react-icons/io";
import {Organization} from "./components/organizations/OrganizationDetails";
import { setCurrentOrganization } from './actions/Organization';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const api_name = process.env.REACT_APP_API_URL;
const api_ssl = process.env.REACT_APP_API_SSL;
const api_ws = process.env.REACT_APP_API_WS;
const domainName =  window.location.hostname;
var api_url = domainName+api_name;
switch (domainName) {
  case '141.94.222.182':
    api_url = domainName+':5000'+api_name;
    break;
  default:
}

class DisplayOrganizationsSocket extends React.Component {
  constructor(props) {
  super(props);
      this.state = {
        selectedOrg: [],
        data: [],
        modal: false,
        name: '',
        ws: null,
      };
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleOrganizationAddSubmit = this.handleOrganizationAddSubmit.bind(this);
      this.showModal = this.showModal.bind(this);
      this.hideModal = this.hideModal.bind(this);
      this.handleShowDetails = this.handleShowDetails.bind(this);
      this.handleCloseDetails = this.handleCloseDetails.bind(this);
      this.connect = this.connect.bind(this);
  }

  componentDidMount() {
    if (this.state.ws === null || this.state.ws.readyState !== 1) {
      this.connect();
    }
    this.timeout = setInterval(() => {
      if (this.state.ws.readyState === 1) {
        let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
        let at = '';
        if(auth_get) {
          at = auth_get.access_token;
        }
        let msg_to_server = JSON.stringify({"type":"organizations_list", "at":at, "organization_id":auth_get.organization_id});
        this.state.ws.send(msg_to_server);
      }
    }, 10000)
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
    this.state.ws.close();
    this.setState({ ws: [] });
  }

  connect = () => {
    var ws = new W3CWebSocket(api_ws+'://'+api_url+'/organizations');
    let that = this;
    let auth_get = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
    let at = '';
    let oi = '';
    if(auth_get) {
      at = auth_get.access_token;
      oi = auth_get.organization_id;
    }
    let msg_to_server = JSON.stringify({"type":"organizations_list", "at":at, "organization_id":oi});

    ws.onopen = () => {
      that.setState({ ws: ws });
      ws.send(msg_to_server);
    };

    ws.onmessage = (message) => {
       let datajson = JSON.parse(message.data);
        if(datajson.type === 'organizations_add') {
          this.setState({modal: false});
        } else if(datajson.type === 'organizations_edit') {
          toast.success('Zmiany zostały zapisane');
        } else if(datajson.type === 'organizations_list') {
          const data = datajson.data;
          this.setState({data: data});
        }
    };

    ws.onclose = (e) => {
      ws.close();
      that.setState({ ws: [] });
      setTimeout(function () {
        that.connect();
      }, 3000);
    };

    ws.onerror = (err) => {
      ws.close();
    };
  };

   showModal(event) {
     this.setState({modal: true});
   };
   hideModal(event) {
     this.setState({modal: false});
   };

   handleInputChange(event) {
     const target = event.target;
     const name = target.name;
     const value = target.value;
     this.setState({
       [name]: value
     });
   }

   handleOrganizationAddSubmit(event) {
     event.preventDefault();
     const ts = this.state;
     var msg_add =  JSON.stringify({"type":"organizations_add", "name":ts.name});
     ts.ws.send(msg_add);
     let msg_to_server = JSON.stringify({"type":"organizations_list"});
     ts.ws.send(msg_to_server);
   }

   handleShowDetails = (organization, event) => {
     let orgKeys = Object.keys(organization)
     for (var i = 0; i < Object.keys(organization).length; i++) {
       let key = orgKeys[i];
       let val = organization[key];
       if (val === null || val === undefined) {
         organization[key] = '';
       }
     }
    //  console.log('organization', organization)
     this.setState({
       closeDetails: true,
       selectedOrg: organization,
     });
   };
   handleCloseDetails = () => {
     this.setState({
       closeDetails: false
     });
   };

   handleEditOrg = (childData) => {
     const ts = this.state;
     var msg_edit =  JSON.stringify({"type":"organizations_edit", "data":childData[0]});
     ts.ws.send(msg_edit);
     let msg_to_server = JSON.stringify({"type":"organizations_list"});
     ts.ws.send(msg_to_server);
     console.log('socket selectedOrg', this.state.selectedOrg)
    //  const params = {
    //   organization_id: oi,
    // }
    //  this.props.listEtollStatus(params, this.props.history);

   };

  render() {
    return (
      <div className="content-wrapper organizations">
        <div className={`content-left ${this.state.closeDetails === true? 'opened': ''}`}>
          <div className="header">
            <div className="left">
              <h2>Organizacje</h2>
            </div>
            <div className="right">
              <div className="button add" onClick={this.showModal}>
                <IoIosAdd />
                <span className="text">Dodaj organizację</span>
              </div>
            </div>
          </div>
          <div className="content">
            <ul>
              {
                this.state.data.map((organization, i) => (
                   <li key={organization.id} className="organization">
                    <div className="left">
                      <div className="top-line">
                        <div className="name">{organization.name}</div>
                        <div className="codename">{organization.codename}</div>
                      </div>
                      <div className="middle-line">
                      </div>
                      <div className="bottom-line">
                      </div>
                    </div>
                      <div className="details">
                        <div className="right" onClick={() => this.handleShowDetails(organization, i)}>
                          Szczegóły
                        </div>
                    </div>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
        <div className={`content-right ${this.state.closeDetails === true? 'opened': ''}`}>
          <div className="inner">
            <Organization selectedOrg={this.state.selectedOrg} editOrg={this.handleEditOrg} goBack={this.handleCloseDetails} />
          </div>
        </div>
        <div className={`modal add ${this.state.modal === true? 'opened': ''}`}>
          <div className="wrapper">
            <div className="inner">
              <div className="header">
                <div className="left">
                  <h3>Dodaj organizację</h3>
                </div>
                <div className="right">
                <button onClick={this.hideModal} id="modal-btn-close" className="on"><span></span><span></span><span></span>
                </button>
                </div>
              </div>
              <div className="content">
                <form onSubmit={this.handleOrganizationAddSubmit}>
                  <label>
                    Nazwa:
                    <input type="text" value={this.state.name} name="name" onChange={this.handleInputChange} />
                  </label>
                  <input type="submit" value="Dodaj" />
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        {/* Same as */}
        <ToastContainer />
      </div>
    );
  }
}

DisplayOrganizationsSocket.propTypes = {
  setCurrentOrganization: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  orgcurrent: state.orgcurrent,
  errors: state.errors
});

export default connect(mapStateToProps,{ setCurrentOrganization })(withRouter(DisplayOrganizationsSocket))
